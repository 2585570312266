import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Grid, TextField, Button, Paper } from '@material-ui/core';
import Swal from "sweetalert2";
import Header from '../../../../components/HeaderAdmin';
import ValidateAdmin from '../../../../components/ValidateAdmin';
import { updateData, searchDataByValue } from '../../../../resources/js/functions'
import './styles.scss';

export default function ActualizarTestimonio() {
    const [campos, setCampos] = useState({});
    const params = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        getTestimonios();
    }, [])

    const getTestimonios = async () => {
        const data = await searchDataByValue('testimonios', 'id', params.id);
        setCampos(data);
    }

    const actualizar = async e => {
        e.preventDefault();
        let json = {}
        json = {
            ...campos,
        };
        updateData("testimonios", campos.id, json).then(() => {
            Swal.fire({
                title: "¡Actualización exitosa!",
                html: `El testimonio se actualizó correctamente`,
                icon: "success",
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#2D6DB4",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/admin/testimonios')
                }
            })
        })
    }

    return <>
        <ValidateAdmin>
            <Header>
                <div id='actuializarTestimonios'>
                    <form onSubmit={actualizar}>
                        <Container maxWidth="lg">
                            <Paper elevation={3} style={{ padding: '15px' }}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <h1>Actualizar testimonios</h1>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="nombre" label="Nombre" variant="outlined" onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.nombre} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="puesto" label="Puesto" variant="outlined" onChange={(e) => setCampos({ ...campos, puesto: e.target.value })} InputLabelProps={{ shrink: true }} value={campos.puesto} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            id="standard-textarea"
                                            label="Escribe el testimonio"
                                            placeholder=""
                                            multiline
                                            required
                                            fullWidth
                                            minRows={4}
                                            maxRows={4}
                                            variant="outlined"
                                            onChange={e => setCampos({ ...campos, testimonio: e.target.value })}
                                            InputLabelProps={{ shrink: true }} value={campos.testimonio} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <div className="full center">
                                            <Button type='submit' className="btn">Actualizar </Button>
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <div className="full center">
                                            <Button type='button' className="btn cancelar" onClick={() => navigate('/admin/testimonios/')}>Cancelar </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Container>
                    </form>
                </div>
            </Header>
        </ValidateAdmin>
    </>
}