import React from 'react';
import GestionPilar from '../../../Pilares/Gestion';

export default function Gestion() {
    return (
        <>
            <div id="gestion">
                <div id="recursos-digitales">
                    <GestionPilar />
                </div>
            </div >
        </>
    )
}