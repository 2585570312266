import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Box } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import orderBy from 'lodash.orderby';
import InputSearch from '../../../components/InputSearch';
import Header from '../../../components/HeaderAdmin';
import ValidateAdmin from '../../../components/ValidateAdmin';
import { getCollections, updateData } from '../../../resources/js/functions';
import './styles.scss';

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 440,
	},
});

export default function Usuarios() {
	const classes = useStyles();
	const [rowsTable, setRowsTable] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [sortColumn, setSortColumn] = useState('id');
	const [sort, setSort] = useState('asc');
	const navigate = useNavigate();

	useEffect(() => {
		getUSuarios();
	}, []);

	const getUSuarios = async () => {
		let array = [];
		const data = await getCollections('users');
		Object.values(data).map((val) => {
			if (val.activo) {
				array.push({ ...val, activo: val.activo ? 'Activo' : 'Inactivo' });
			}
		});
		setRowsTable([]);
		const sortArray = orderBy(array, [sort, sortColumn]);
		console.log(array);
		setRowsTable(sortArray);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const borrarUsuarios = async (id) => {
		Swal.fire({
			title: 'Advertencia!',
			text: '¿Desea desactivar este usuario?',
			icon: 'warning',
			confirmButtonText: 'Confirmar',
			confirmButtonColor: '#2D6DB4',
			cancelButtonColor: '#d33',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				updateData('users', id, { activo: false })
					.then(() => {
						Swal.fire({
							title: 'Bien hecho',
							text: 'Usuario desactivado correctamente',
							icon: 'success',
							confirmButtonText: 'Confirmar',
							confirmButtonColor: '#2D6DB4',
						}).then((result) => {
							if (result.isConfirmed) {
								getUSuarios();
							}
						});
					})
					.catch((e) => console.log(e));
			}
		});
	};

	const busquedaFunction = (busqueda) => {
		setRowsTable(busqueda);
	};

	const columns = [
		{ field: 'id', headerName: 'Número de empleado', flex: 1 },
		{ field: 'idGlobal', headerName: 'ID Global', flex: 1 },
		{ field: 'nombre', headerName: 'Nombre', flex: 1 },
		{ field: 'activo', headerName: 'Activo', flex: 1 },
		{
			field: 'action',
			headerName: 'Action',
			flex: 1,
			sortable: false,
			renderCell: (params) => {
				return (
					<>
						<div className='cursor' style={{ marginRight: '15px' }} onClick={() => navigate(`/admin/usuarios/${params.id}/actualizar`)}>
							<EditIcon />
						</div>
						<div className='cursor' onClick={() => borrarUsuarios(params.id)}>
							<DeleteIcon />
						</div>
					</>
				);
			},
		},
	];

	// Define estilos para el contenedor
	const containerStyle = {
		height: '600px', // Altura del contenedor
		width: '11px', // Ancho del contenedor
	};

	return (
		<>
			<ValidateAdmin>
				<Header>
					<div id='usuarios'>
						<Container maxWidth='lg'>
							<div class='scroll-container'>
								<div class='fixed-size-content'>
									<Paper elevation={3} style={{ padding: '15px', width: 'auto' }}>
										<Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
											<Grid item lg={12} md={12} sm={12} xs={12}>
												<h1>Usuarios</h1>
											</Grid>
											<Grid xs={12} sm={12} md={12} lg={12}>
												<Box style={{ height: '600px', width: '100%' }}>
													<DataGrid
														rows={rowsTable}
														columns={columns}
														pageSize={10}
														initialState={{
															pagination: { paginationModel: { pageSize: 10 } },
														}}
														slots={{ toolbar: GridToolbar }}
														slotProps={{
															toolbar: {
																printOptions: { disableToolbarButton: true },
																csvOptions: { disableToolbarButton: true },
																showQuickFilter: true,
																button: { variant: 'outlined' },
															},
														}}
														pageSizeOptions={[10, 20, 50, 100]}
														rowsPerPageOptions={[10]}
														localeText={esES.components.MuiDataGrid.defaultProps.localeText}
														// disableExtendRowFullWidth={true} // Permitir scroll horizontal
													/>
												</Box>
											</Grid>
										</Grid>
										{/* <InputSearch busquedaProps={busquedaFunction} collection='users' setRowsTable={setRowsTable} /> */}
									</Paper>
								</div>
							</div>
						</Container>
					</div>
				</Header>
			</ValidateAdmin>
		</>
	);
}
