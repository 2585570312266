import React from 'react';
import { InView } from 'react-intersection-observer';
import { Grid, Container } from "@material-ui/core";
import texto from '../../../../../resources/images/broshure/seccion3/texto.svg';
import cuadro from '../../../../../resources/images/broshure/seccion3/cuadro.svg';
import 'animate.css';

export default function Seccion3() {
    return (
        <InView as="div" onChange={(inView, entry) => console.log('Inview:', inView)}>
            <section id="seccion3" className='bg-static '>

                <Container maxWidth="lg" >
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={8} className='wow zoomInDown' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                            <img src={texto} className='full' alt="texto" />
                        </Grid>
                    </Grid>
                </Container>

                <Container maxWidth="lg" >
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={12} md={8} lg={8} className='wow zoomInDown' data-wow-delay='.5s' data-wow-offset='100' data-wow-duration='.5s'>
                            <img src={cuadro} className='full' alt="cuadro" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </InView>
    )
}