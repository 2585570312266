import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Button, Grid, Container } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import Modal from '../../components/Modal';
import Datos from './opciones';
import { getDate, updateData } from '../../resources/js/functions';
import video from '../../resources/videos/videoTutorial.mp4';
import Logo from '../../resources/images/header/logo.svg';
import '../Header/styles.scss';
import './styles.scss';

const styles = {
	fondoMenu: {
		background: `#F8F7F7 0% 0% no-repeat padding-box`,
		maxWidth: 540,
		height: `100vh`,
		width: `210px`,
		overflow: 'auto',
		padding: '15px 15px 0 15px',
		color: '#263238',
		zIndex: 2,
	},
	lista: {
		display: 'grid',
	},
	button: {
		color: '#51647D',
		width: 200,
		lineHeight: 1.1,
		fontSize: 15,
		fontWeight: 'bold',
		textTransform: 'none',
		textDecoration: 'none !important',
	},
	p: {
		margin: '5px 0',
		color: '#54565B',
		fontSize: '22px',
		fontWeight: 'bold',
	},
	h3: {
		color: '#51647D',
		fontSize: '28px',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		marginLeft: '20px',
	},
};

function Menu(props) {
	const [openMenu, setOpenMenu] = useState(false);
	const [modal, setModal] = useState(false);
	const navigate = useNavigate();

	const logout = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				window.localStorage.removeItem('0hNZeY/gjEeJihP9bIPGHw==');
				window.localStorage.removeItem('0hNZeY/gjEeJihP9bIPGHw');
				window.localStorage.removeItem('pilar');
				navigate('/');
			})
			.catch((error) => {
				console.log('error: ' + error);
			});
	};

	const toggleDrawer = () => {
		setOpenMenu(!openMenu);
	};

	const botonMenu = (to, titulo, callback = null, index) => {
		const { classes } = props;
		return (
			<>
				<Button id='btn-mobile' onClick={() => navigate(to)} key={index} className={classes.button}>
					<div className='btn-inline'>
						<div className='float-left titulo-menu full'>
							<span>{titulo}</span>
						</div>
					</div>
				</Button>
			</>
		);
	};

	const sideList = (className, back) => (
		<div className={className} id='menu-mobile'>
			<div style={{ fontSize: '30px', fontWeight: 400, marginBottom: '30px', color: '#51647D' }}>Menú</div>
			{!localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') && (
				<>
					<Button id='btn-mobile' onClick={() => navigate('/seleccionar-contenido')} className={classes.button}>
						<div className='btn-inline'>
							<div className='float-left titulo-menu full'>
								<span>Home</span>
							</div>
						</div>
					</Button>
					<Button id='btn-mobile' onClick={() => navigate('/')} className={classes.button}>
						<div className='btn-inline'>
							<div className='float-left titulo-menu full'>
								<span>Iniciar sesión</span>
							</div>
						</div>
					</Button>
				</>
			)}
			{/* {Object.values(Datos).map((val, index) => {
        if (val.texto !== "Cerrar sesión") {

          return botonMenu(val.link, val.texto, index);
        }
      })} */}
			{localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') && (
				<>
					<Button id='btn-mobile' onClick={() => navigate('/seleccionar-contenido')} className={classes.button}>
						<div className='btn-inline'>
							<div className='float-left titulo-menu full'>
								<span>Home</span>
							</div>
						</div>
					</Button>

					<Button id='btn-mobile' onClick={logout} className={classes.button}>
						<div className='btn-inline'>
							<div className='float-left titulo-menu full'>
								<span>Cerrar sesión</span>
							</div>
						</div>
					</Button>
				</>
			)}
		</div>
	);

	const open = (id) => {
		switch (id) {
			case 1:
				updateData('users', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { playBookHome: true, fechaPlaybookHome: getDate() });
				window.open('https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/DG_WB_Kickoff_People%20Leader_VF_270722.pdf?alt=media&token=4d5d6a80-342a-44c0-9d90-af89df64cf5b', '_blank');
				break;
			case 2:
				updateData('users', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { kickoff: true, fechakickoff: getDate() });
				navigate('/kickoff');
				break;
			case 3:
				updateData('users', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { broshure: true, fechaBroshure: getDate() });
				navigate('/conoce-mas');
				break;

			default:
				break;
		}
	};

	const { classes, back } = props;

	return (
		<>
			<Grid container justifyContent='center' alignItems='center' direction='row' spacing={3}>
				<Grid item lg={3} md={6} sm={10} xs={10}>
					<img id='imgBanner' src={Logo} alt='logo' onClick={() => navigate('/seleccionar-contenido')} className='cursor logo' />
				</Grid>
				<Grid item lg={9} md={6} sm={2} xs={2} className='ass'>
					<Hidden only={['lg', 'xl']}>
						<div className='hijos' style={{ float: 'right', color: '#51647D' }} onClick={toggleDrawer}>
							<i className='fas fa-bars'></i>
						</div>
					</Hidden>
					<Hidden only={['xs', 'sm', 'md']}>
						{!localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') && (
							<ul className='nav-links' id='menuHeader'>
								<li className={`hijos`}>
									<div className={`padding-li`} onClick={() => navigate('/')}>
										Iniciar sesión
									</div>
								</li>
							</ul>
						)}
						{localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') && (
							<ul className='nav-links' id='menuHeader'>
								<li className={`hijos`}>
									<div onClick={() => navigate('/seleccionar-contenido')} className={`padding-li ${window.location.pathname === '/seleccionar-contenido' ? 'border' : ''}`}>
										Home
									</div>
								</li>
								<li className={`hijos`}>
									<div onClick={() => navigate('/daring/home')} className={`padding-li ${window.location.pathname === '/daring/home' ? 'border' : ''}`}>
										Pilares
									</div>
								</li>
								<li className={`hijos`}>
									<div onClick={() => navigate('/daring/kickoff')} className={`padding-li ${window.location.pathname === '/daring/kickoff' ? 'border' : ''}`}>
										Kickoff
									</div>
								</li>

								<li className={`hijos`}>
									<Button onClick={logout} className={`padding-li`}>
										Cerrar sesión
									</Button>
								</li>
							</ul>
						)}
					</Hidden>
				</Grid>
			</Grid>
			<Drawer anchor='right' open={openMenu} onClose={toggleDrawer}>
				<div className={classes.fondoMenu}>{sideList(classes.lista, back)}</div>
			</Drawer>
			<Modal open={modal}>
				<Container maxWidth='lg'>
					<Grid container direction='row' alignItems='stretch' justifyContent='center' spacing={0}>
						<Grid container justifyContent='center' alignItems='center' direction='row' spacing={3}>
							<Grid item lg={10} md={10} sm={12} xs={12}>
								<div className='full'>
									<div className='cursor btn-cerrar-popup' onClick={() => setModal(false)}>
										Cerrar
									</div>
								</div>
							</Grid>
							<Grid item lg={10} md={10} sm={12} xs={12}>
								<video id='video1' className='element' _origeny='127' _origenx='196' src={video} style={{ width: '100%' }} controls autoPlay controlsList='nodownload noplaybackrate noremoteplayback '></video>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Modal>
		</>
	);
}

Menu.propTypes = {
	classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(Menu);
