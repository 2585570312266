import React, { useEffect, useState } from "react";
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../../../config/firebase";
import AliceCarousel from "react-alice-carousel";
import { getCollections, stringToArray, cualMes } from '../../../../resources/js/functions';
import comillaArriba from '../../../../resources/images/testimonio/comilla-arriba.svg';
import comillaAbajo from '../../../../resources/images/testimonio/comilla-abajo.svg';
import "react-alice-carousel/lib/alice-carousel.css";

export default function Carousel() {
  const [ultimosEventos, setUltimosEventos] = useState([]);

  useEffect(() => {
    getTestimonios();
    const dbRef = ref(db, "testimonios/");
    onChildChanged(dbRef, (data) => {
      getTestimonios();
    });
  }, []);


  const getTestimonios = async () => {
    const array = [];
    const data = await getCollections('testimonios');
    Object.values(data).map(val => {
      array.push(val);
    })
    setUltimosEventos(array);
  }

  return (
    <AliceCarousel
      mouseTrackingEnabled
      items={ultimosEventos}
      autoPlayInterval="4000"
      autoPlay={true}
      infinite={true}
      mouseTracking
      startIndex={0}
      slideToIndex={0}
      disableButtonsControls
      // disableDotsControls
      responsive={{
        425: {
          items: 2,
        },
        640: {
          items: 2,
        },
        1024: {
          items: 3,
        },
      }}
    >
      {ultimosEventos.map((i, index) => {
        return (
          <>
            <div className="contenedor-testimonio" key={i.testimonio}>
              <div className="border-top" />
              <div className="texto-testimonio padre">
                <div className="hijos">
                  <div style={{ textAlign: 'left' }}><img src={comillaArriba} alt="comilla" /></div>
                  {i.testimonio}
                  <div style={{ textAlign: 'right' }}><img src={comillaAbajo} alt="comilla" /></div>
                </div>
              </div>
              <div className="info-testimonio">
                <div className="nombre">{i.nombre}</div>
                <small>{i.puesto}</small>
              </div>
            </div>
          </>
        );
      })}
    </AliceCarousel>
  );
}
