import tecIcono from '../../../../../../resources/images/broshure/seccion15/tec-icono.svg';
import avaiIcono from '../../../../../../resources/images/broshure/seccion15/avai.svg';
import icebergIcono from '../../../../../../resources/images/broshure/seccion15/iceberg.svg';
import mercerIcono from '../../../../../../resources/images/broshure/seccion15/mercer.svg';
// alejandra barba
import portadaAlejandraBarba from '../../../../../../resources/images/broshure/seccion15/alejandra-barba.svg';
import descripcionAlejandraBarba from '../../../../../../resources/images/broshure/seccion15/descripcion-alejandra-barba.svg';
import nombreAlejandraBarba from '../../../../../../resources/images/broshure/seccion15/nombre-alejandra-barba.svg';
// marcella plata
import portadaMarcellaPlata from '../../../../../../resources/images/broshure/seccion15/marcella-plata.svg';
import descripcionMarcellaPlata from '../../../../../../resources/images/broshure/seccion15/descripcion-marcella-plata.svg';
import nombreMarcellaPlata from '../../../../../../resources/images/broshure/seccion15/nombre-marcella-plata.svg';
// sandra bravo
import portadaSandraBravo from '../../../../../../resources/images/broshure/seccion15/sandra-bravo.svg';
import descripcionSandraBravo from '../../../../../../resources/images/broshure/seccion15/descripcion-sandra-bravo.svg';
import nombreSandraBravo from '../../../../../../resources/images/broshure/seccion15/nombre-sandra-bravo.svg';
// shirley saenz
import portadaShirleySaenz from '../../../../../../resources/images/broshure/seccion15/shirley-saenz.svg';
import descripcionShirleySaenz from '../../../../../../resources/images/broshure/seccion15/descripcion-shirley-saenz.svg';
import nombreShirleySaenz from '../../../../../../resources/images/broshure/seccion15/nombre-shirley-saenz.svg';
// Santiago Klappenbach
import portadaSantiagoKlappenbach from '../../../../../../resources/images/broshure/seccion15/santiago-klappenbach.svg';
import descripcionSantiagoKlappenbach from '../../../../../../resources/images/broshure/seccion15/descripcion-santiago-klappenbach.svg';
import nombreSantiagoKlappenbach from '../../../../../../resources/images/broshure/seccion15/nombre-santiago-klappenbach.svg';
// Santiago Klappenbach
import portadaGabrielFernandez from '../../../../../../resources/images/broshure/seccion15/gabriel-fernandez.svg';
import descripcionGabrielFernandez from '../../../../../../resources/images/broshure/seccion15/descripcion-gabriel-fernandez.svg';
import nombreGabrielFernandez from '../../../../../../resources/images/broshure/seccion15/nombre-gabriel-fernandez.svg';
// Diana moreno
import portadaDianaMoreno from '../../../../../../resources/images/broshure/seccion15/diana-moreno.svg';
import descripcionDianaMoreno from '../../../../../../resources/images/broshure/seccion15/descripcion-diana-moreno.svg';
import nombreDianaMoreno from '../../../../../../resources/images/broshure/seccion15/nombre-diana-moreno.svg';

// Talina Gonzalez
import portadaTalinaGonzalez from '../../../../../../resources/images/broshure/seccion15/Talina-Gonzalez.svg';
import descripcionTalinaGonzalez from '../../../../../../resources/images/broshure/seccion15/descripcion-Talina-Gonzalez.svg';
import nombreTalinaGonzalez from '../../../../../../resources/images/broshure/seccion15/nombre-Talina-Gonzalez.svg';
// Eduardo Muñoz
import portadaEduardoMunoz from '../../../../../../resources/images/broshure/seccion15/Eduardo-Munoz.svg';
import descripcionEduardoMunoz from '../../../../../../resources/images/broshure/seccion15/descripcion-Eduardo-Munoz.svg';
import nombreEduardoMunoz from '../../../../../../resources/images/broshure/seccion15/nombre-Eduardo-Munoz.svg';
// Karina Cantu
import portadaKarinaCantu from '../../../../../../resources/images/broshure/seccion15/Karina-Cantu.svg';
import descripcionKarinaCantu from '../../../../../../resources/images/broshure/seccion15/descripcion-Karina-Cantu.svg';
import nombreKarinaCantu from '../../../../../../resources/images/broshure/seccion15/nombre-Karina-Cantu.svg';
// Carlos Adrian Garcia
import portadaCarlosAdrian from '../../../../../../resources/images/broshure/seccion15/Carlos-Adrian-Garcia.svg';
import descripcionCarlosAdrian from '../../../../../../resources/images/broshure/seccion15/descripcion-Carlos-Adrian-Garcia.svg';
import nombreCarlosAdrian from '../../../../../../resources/images/broshure/seccion15/nombre-Carlos-Adrian-Garcia.svg';
// mas
import portadaProximamente from '../../../../../../resources/images/broshure/seccion15/proximamente.svg';

const data = [
	{
		portada: portadaAlejandraBarba,
		descripcion: descripcionAlejandraBarba,
		nombre: nombreAlejandraBarba,
		linkeding: 'https://www.linkedin.com/in/alejandra-barba-7055bb48/',
		segundoIcon: tecIcono,
	},
	{
		portada: portadaMarcellaPlata,
		descripcion: descripcionMarcellaPlata,
		nombre: nombreMarcellaPlata,
		linkeding: 'https://www.linkedin.com/in/marcellacoach/',
		segundoIcon: tecIcono,
	},
	{
		portada: portadaSandraBravo,
		descripcion: descripcionSandraBravo,
		nombre: nombreSandraBravo,
		linkeding: 'https://www.linkedin.com/in/sandra-bravo-jim%C3%A9nez-480593/',
		segundoIcon: tecIcono,
	},
	{
		portada: portadaShirleySaenz,
		descripcion: descripcionShirleySaenz,
		nombre: nombreShirleySaenz,
		linkeding: 'http://linkedin.com/in/shirleyjsaenz',
		segundoIcon: icebergIcono,
	},
	{
		portada: portadaSantiagoKlappenbach,
		descripcion: descripcionSantiagoKlappenbach,
		nombre: nombreSantiagoKlappenbach,
		linkeding: 'http://linkedin.com/in/santiago-klappenbach-0774b81a',
		segundoIcon: avaiIcono,
	},
	{
		portada: portadaGabrielFernandez,
		descripcion: descripcionGabrielFernandez,
		nombre: nombreGabrielFernandez,
		linkeding: 'https://www.linkedin.com/in/gabrielfern%C3%A1ndez',
		segundoIcon: mercerIcono,
	},
	{
		portada: portadaDianaMoreno,
		descripcion: descripcionDianaMoreno,
		nombre: nombreDianaMoreno,
		linkeding:
			'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQF2inC8ZdFLowAAAYO0V8b4uD4qeC8Y54veGojLv7VH0GxZDAJ7qxME6RYLn7VsDniT4VbdUHsDDOthsxin8jnQM4LekDkEVMx7lYcIAKyB3bSh57y4HGt257-F2mVCFCI436k=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fdianamorenorodriguez',
		segundoIcon: mercerIcono,
	},
	{
		portada: portadaTalinaGonzalez,
		descripcion: descripcionTalinaGonzalez,
		nombre: nombreTalinaGonzalez,
		linkeding:'',
		segundoIcon: '',
	},
	{
		portada: portadaEduardoMunoz,
		descripcion: descripcionEduardoMunoz,
		nombre: nombreEduardoMunoz,
		linkeding:'',
		segundoIcon: '',
	},
	{
		portada: portadaKarinaCantu,
		descripcion: descripcionKarinaCantu,
		nombre: nombreKarinaCantu,
		linkeding:'',
		segundoIcon: '',
	},
	{
		portada: portadaCarlosAdrian,
		descripcion: descripcionCarlosAdrian,
		nombre: nombreCarlosAdrian,
		linkeding:'',
		segundoIcon: '',
	},
	{
		portada: portadaProximamente,
		descripcion: '',
		nombre: '',
		linkeding: '',
		segundoIcon: '',
	},
];

export default data;
