import React from 'react';
import { Grid, Container } from "@material-ui/core";
import Titulo from '../../../../../resources/images/broshure/seccion5/titulo.svg';

export default function Seccion5() {
    return <section id="seccion5" className='wow flipOutY' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10}>
                    <div className='center'>
                        <img src={Titulo} alt="titulo" className='size' />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}