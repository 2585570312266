import React from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import titulo from '../../../../../resources/images/broshure/seccion9/titulo.svg';
import tituloMobile from '../../../../../resources/images/broshure/seccion9/img1-mobile.svg';
import cuadro from '../../../../../resources/images/broshure/seccion9/cuadro.svg';

export default function Seccion9() {
    return <section id="seccion9" >
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="stretch" justifyContent='center' spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} className='padre wow rotateInDownLeft' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                    <div className="hijos center">
                        <img src={`${isMobileOnly ? tituloMobile : titulo}`} alt="img" className='size' />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className='wow flipInX' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                    <div className="hijos center">
                        <img src={cuadro} alt="img" className='size' />
                    </div>
                </Grid>
            </Grid>
        </Container>
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="stretch" justifyContent='center' spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={10} className='wow fadeInUp' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                    <div className='texto'>
                        Pueden acceder todas las personas que tengan un equipo a cargo o que por sus funciones requieran desarrollarse como THE PEOPLE LEADER.
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}