import React from 'react';
import bg from '../../../../../resources/images/broshure/seccion4/bg1.svg';

export default function Seccion4() {
    return (
        <section id="seccion4">
           <img src={bg} alt="bg" className='full wow rotateInDownLeft' data-wow-delay='.2s' data-wow-offset='500' data-wow-duration='.5s' />
        </section>

    )
}