import React from "react";
import { useParams } from "react-router-dom";
import Ejecutivos from './Ejecutivos'
import JefaturayCordinaciones from './JefaturayCordinaciones'

export default function SectionQuestions(props) {
  const { activeView } = props;
  const params = useParams();

  return (
    <div id="section-questions">
          {params.id === 'ejecutivos' && <Ejecutivos activeView={activeView} />}
          {params.id === 'jefaturas-y-coordinaciones' && <JefaturayCordinaciones activeView={activeView} />}
    </div>
  );
}
