import React, { useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Button, Grid, Container, Hidden } from '@material-ui/core';
import Modal from '../../components/Modal';
import { getDate, updateData } from '../../resources/js/functions';
import video from '../../resources/videos/videoTutorial.mp4';
import Logo from '../../resources/images/header/logo.svg';
import ZIP from '../../resources/descargables/Playbook Caring_Daring.zip';
import PDFBroshure from '../../resources/descargables/Brochure_HNK_V3.pdf';
import '../Header/styles.scss';
import './styles.scss';

// Estilos para el componente
const styles = {
	fondoMenu: {
		background: '#F8F7F7',
		maxWidth: 540,
		height: '100vh',
		width: '210px',
		overflow: 'auto',
		padding: '15px',
		color: '#263238',
		zIndex: 2,
	},
	button: {
		color: '#51647D',
		width: 200,
		lineHeight: 1.1,
		fontSize: 15,
		fontWeight: 'bold',
		textTransform: 'none',
	},
};

function Menu(props) {
	const [openMenu, setOpenMenu] = useState(false);
	const [modal, setModal] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const navigate = useNavigate();

	// Función para cerrar sesión
	const logout = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				// Eliminar datos de sesión y redirigir
				window.localStorage.removeItem('0hNZeY/gjEeJihP9bIPGHw==');
				window.localStorage.removeItem('pilar');
				navigate('/');
			})
			.catch((error) => {
				console.error('Error al cerrar sesión: ', error);
			});
	};

	// Función para alternar el menú lateral
	const toggleDrawer = () => {
		setOpenMenu(!openMenu);
	};

	// Componente de botón de menú
	const botonMenu = (to, titulo, index) => {
		const { classes } = props;
		return (
			<Button id='btn-mobile' onClick={() => navigate(to)} key={index} className={classes.button}>
				<span>{titulo}</span>
			</Button>
		);
	};

	// Función para construir la lista del menú lateral
	const sideList = (className) => (
		<div className={className} id='menu-mobile'>
			<div style={{ fontSize: '30px', marginBottom: '30px', color: '#51647D' }}>Menú</div>
			{!localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') ? (
				<>
					{botonMenu('/home', 'Home')}
					{botonMenu('/', 'Iniciar sesión')}
				</>
			) : (
				<>
					{botonMenu('/home', 'Caring')}
					{botonMenu('/daring/home', 'Daring')}
					<Button onClick={logout} className={classes.button}>
						Cerrar sesión
					</Button>
				</>
			)}
		</div>
	);

	// Función para manejar acciones específicas según el ID
	const open = (id) => {
		const userId = localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==');
		switch (id) {
			case 1:
				updateData('users', userId, { playBookHome: true, fechaPlaybookHome: getDate() });
				window.open('https://example.com/document.pdf', '_blank'); // Enlace a un documento
				break;
			case 2:
				updateData('users', userId, { kickoff: true, fechakickoff: getDate() });
				navigate('/kickoff');
				break;
			case 3:
				updateData('users', userId, { broshure: true, fechaBroshure: getDate() });
				// navigate('/conoce-mas');
				window.open(PDFBroshure, '_blank'); // Enlace a un documento
				break;
			case 4:
				// updateData('users', userId, { playbook: true, fechaPlaybook: getDate() });
				window.open(ZIP, '_blank');
				break;
			default:
				break;
		}
	};

	const { classes } = props;

	return (
		<>
			<Grid container justifyContent='center' alignItems='center' spacing={3}>
				<Grid item lg={3} md={6} sm={10} xs={10}>
					<img id='imgBanner' src={Logo} alt='logo' onClick={() => navigate('/home')} className='cursor logo' />
				</Grid>
				<Grid item lg={9} md={6} sm={2} xs={2}>
					<Hidden only={['lg', 'xl']}>
						<div className='hijos' style={{ float: 'right', color: '#51647D' }} onClick={toggleDrawer}>
							<i className='fas fa-bars'></i>
						</div>
					</Hidden>
					<Hidden only={['xs', 'sm', 'md']}>
						{localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') ? (
							<ul className='nav-links' id='menuHeader'>
								<li className='hijos'>
									<div onClick={() => setModal(true)} className='padding-li'>
										Cómo Navegar
									</div>
								</li>
								<li className='hijos'>
									<div onClick={() => open(4)} className='padding-li'>
										Playbook Integrador
									</div>
								</li>
								<li className='hijos'>
									<div onClick={() => navigate('/pilares')} className='padding-li'>
										Caring
									</div>
								</li>
								<li className='hijos'>
									<div onClick={() => navigate('/daring/home')} className='padding-li'>
										Daring
									</div>
								</li>
								<li className='hijos'>
									<div onClick={() => open(3)} className='padding-li'>
										Conoce más
									</div>
								</li>
								<li className='hijos'>
									<div onClick={() => navigate('/testimonios')} className='padding-li'>
										Testimonios
									</div>
								</li>
								<li className='hijos'>
									<Button onClick={logout} className='padding-li'>
										Cerrar sesión
									</Button>
								</li>
							</ul>
						) : (
							<ul className='nav-links' id='menuHeader'>
								<li className='hijos'>
									<div className='padding-li' onClick={() => navigate('/')}>
										Iniciar sesión
									</div>
								</li>
							</ul>
						)}
					</Hidden>
				</Grid>
			</Grid>
			<Drawer anchor='right' open={openMenu} onClose={toggleDrawer}>
				<div className={classes.fondoMenu}>{sideList(classes.lista)}</div>
			</Drawer>
			<Modal open={modal}>
				<Container maxWidth='lg'>
					<Grid container direction='row' alignItems='stretch' justifyContent='center'>
						<Grid item lg={10}>
							<div className='cursor btn-cerrar-popup' onClick={() => setModal(false)}>
								Cerrar
							</div>
						</Grid>
						<Grid item lg={10}>
							<video id='video1' className='element' src={video} style={{ width: '100%' }} controls autoPlay controlsList='nodownload noplaybackrate noremoteplayback'></video>
						</Grid>
					</Grid>
				</Container>
			</Modal>
		</>
	);
}

// Definición de tipos de las propiedades
Menu.propTypes = {
	classes: PropTypes.shape({}).isRequired,
};

// Exportación del componente con estilos
export default withStyles(styles)(Menu);
