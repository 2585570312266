import tutorial from '../../../../resources/images/daring/pilares/tutorial1.png';
import podcast from '../../../../resources/images/daring/pilares/podcast1.svg';
import evaluacion from '../../../../resources/images/daring/pilares/evaluacion-oyente.png';
import portinsignia from '../../../../resources/images/daring/pilares/portada-insignia-oyente.png';
const ejectutivos = {
	tutorial1: {
		portada: tutorial,
		titulo: 'Clase tutorial de las competencias Oyente y Cultivador',
		descripcion: 'Conoce cómo incluir y fortalecer las competencias Oyente y Cultivador en tu liderazgo',
		urlInsumo: '/daring/ejecutivos/oyente/tutorial',
		url: 'https://player.vimeo.com/video/879966906?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479',
	},
	podcast1: {
		titulo: 'Podcast de las competencias Oyente y Cultivador',
		descripcion: 'Conoce cómo un experto aplica las competencias oyente y cultivador en su cotidianidad como líder y su punto de vista al ponerlo en práctica',
		urlInsumo: '/daring/ejecutivos/oyente/podcast',
		portada: podcast,
		url: 'https://player.vimeo.com/video/882674700?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479',
	},
	evaluacion: {
		portada: evaluacion,
		titulo: 'Evaluación Oyente Cultivador',
		descripcion: '',
		urlInsumo: '/daring/ejecutivos/oyente/evaluacion',
		url: '',
	},
	insignia: {
		portada: portinsignia,
		titulo: 'Insignia',
		descripcion: '',
		urlInsumo: '/daring/ejecutivos/oyente/insignia',
		url: '',
	},
};
const jefaturasyCoordinadores = {
	tutorial1: {
		titulo: 'Clase tutorial de las competencias Oyente y Cultivador',
		descripcion: 'Conoce cómo incluir y fortalecer las competencias Oyente y Cultivador en tu liderazgo',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/oyente/tutorial',
		portada: tutorial,
		url: 'https://player.vimeo.com/video/879951062?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479',
	},
	podcast1: {
		titulo: 'Podcast de las competencias Oyente y Cultivador',
		descripcion: 'Conoce cómo un experto aplica las competencias oyente y cultivador en su cotidianidad como líder y su punto de vista al ponerlo en práctica',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/oyente/podcast',
		portada: podcast,
		url: 'https://player.vimeo.com/video/882674700?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479',
	},
	evaluacion: {
		portada: evaluacion,
		titulo: 'Evaluación Oyente Cultivador',
		descripcion: '',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/oyente/evaluacion',
		url: '',
	},
	insignia: {
		portada: portinsignia,
		titulo: 'Insignia',
		descripcion: '',
		urlInsumo: '/daring/ejecutivos/oyente/insignia',
		url: '',
	},
};

export { ejectutivos, jefaturasyCoordinadores };
