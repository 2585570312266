import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import sebastianVazques from '../../../../../../resources/images/daring/pilares/sebastian-vazques.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30 mt30">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      {params.id === 'ejecutivos' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={sebastianVazques} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Sebastián Vázquez Sangiorgio</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Consultor Senior y Líder del negocio de Career Services para América Latina.</li>
                          <li>Ha trabajado desde 2009 en las oficinas de Mercer Argentina, Colombia, México y Perú y ahora basado en Chile.</li>
                          <li>En sus años de experiencia en Mercer, Sebastián ha trabajado con +150 clientes en +300 proyectos locales y regionales.</li>
                          <li>Anteriormente, se desempeñó como Project Leader en Arvato Services, una empresa multinacional de la industria de Contact Center.</li>
                          <li>Sebastián cursó sus estudios de Lic. en Administración de las Organizaciones en la Facultad de Ciencias Económicas de la Universidad de Buenos Aires (Argentina). Es coach certificado en la metodología Hogan de evaluación del liderazgo y cuenta con un MBA en la Universidad del Pacífico (Perú). Cuenta con amplia experiencia dictando cursos en Mercer para clientes de toda la región, en temas relacionados a la gestión de personas.</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}
      {params.id === 'jefaturas-y-coordinaciones' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={sebastianVazques} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Sebastián Vázquez Sangiorgio</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Consultor Senior y Líder del negocio de Career Services para América Latina.</li>
                          <li>Ha trabajado desde 2009 en las oficinas de Mercer Argentina, Colombia, México y Perú y ahora basado en Chile.</li>
                          <li>En sus años de experiencia en Mercer, Sebastián ha trabajado con +150 clientes en +300 proyectos locales y regionales.</li>
                          <li>Anteriormente, se desempeñó como Project Leader en Arvato Services, una empresa multinacional de la industria de Contact Center.</li>
                          <li>Sebastián cursó sus estudios de Lic. en Administración de las Organizaciones en la Facultad de Ciencias Económicas de la Universidad de Buenos Aires (Argentina). Es coach certificado en la metodología Hogan de evaluación del liderazgo y cuenta con un MBA en la Universidad del Pacífico (Perú). Cuenta con amplia experiencia dictando cursos en Mercer para clientes de toda la región, en temas relacionados a la gestión de personas.</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}

    </>
  );
}
