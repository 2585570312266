import { ref, onValue, set, update, remove, onChildChanged, orderByChild, equalTo, get, query } from 'firebase/database';
import { db } from '../../config/firebase.js';
let xlsx = require('json-as-xlsx');

//--------------------------------------------------------------------------------------//
//-----------------------------FUNCIONES DE BASE DE DATOS-------------------------------//
//--------------------------------------------------------------------------------------//

// leer datos
export const getCollections = async (collection) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`)));
	if (dataSnapshot.exists()) {
		data = Object.values(dataSnapshot.val());
	}
	return data;
};

// leer datos
export const getCollectionsComplete = async (collection) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`)));
	if (dataSnapshot.exists()) {
		data = dataSnapshot.val();
	}
	return data;
};

// set datos
export const setData = async (collection, id, json) => {
	set(ref(db, `${collection}/${id}`), json);
};

// buscar dato por id
export const searchData = async (collection, id) => {
	let json = {};
	const dbRef = ref(db, `${collection}/${id}`);
	onValue(dbRef, (snapshot) => {
		if (snapshot.exists()) {
			json = snapshot.val();
			// console.log(snapshot.val());
		}
	});
	// console.log(json);
	return json;
};

// buscar dato por valor
export const searchDataByValue = async (collection, key, value) => {
	let data = {};
	const dataSnapshot = await get(query(ref(db, `${collection}`), orderByChild(`${key}`), equalTo(`${value}`)));
	if (dataSnapshot.exists()) {
		data = Object.values(dataSnapshot.val())[0];
	}
	return data;
};

// actualizar datos por usuario
export const updateData = async (collection, id, json) => {
	update(ref(db, `${collection}/${id}`), json);
};

// actualizar todos los datos
export const updateAll = async (collection, json) => {
	update(ref(db, `${collection}`), json);
};

// borrar datos
export const deleteData = async (collection, id) => {
	remove(ref(db, `/${collection}/${id}`))
		.then(() => {
			console.log('eliminado correctamente');
		})
		.catch((e) => {
			console.log(e);
		});
};

// child changed
export async function childChange(collection) {
	let json = {};
	const collectionRef = ref(db, `${collection}/`);
	onChildChanged(collectionRef, (data) => {
		json = data.val();
	});
	return json;
}

//--------------------------------------------------------------------------------------//
//-----------------------------FUNCIONES GENERALES--------------------------------------//
//--------------------------------------------------------------------------------------//

export function quitarAcentos(cadena) {
	const acentos = {
		á: 'a',
		é: 'e',
		í: 'i',
		ó: 'o',
		ú: 'u',
		Á: 'A',
		É: 'E',
		Í: 'I',
		Ó: 'O',
		Ú: 'U',
		Ñ: 'n',
		ñ: 'n',
	};
	return cadena
		.split('')
		.map((letra) => acentos[letra] || letra)
		.join('')
		.toString();
}

export function cambiarTextos(string) {
	const minusculas = string.toLowerCase();
	const sinEspacios = minusculas.replace(' ', '-');
	const sinTildes = quitarAcentos(sinEspacios);
	return sinTildes;
}

export function stringToArray(string, separador = ',') {
	const array = string.split(separador);
	return array;
}

// export function capitalize(string) {
//   const resultado = string.charAt(0).toUpperCase() + string.slice(1);
//   return resultado;
// }

export function cualMes(id) {
	let mes = '';
	switch (id) {
		case '01':
			mes = 'Enero';
			break;
		case '02':
			mes = 'Febrero';
			break;
		case '03':
			mes = 'Marzo';
			break;
		case '04':
			mes = 'Abril';
			break;
		case '05':
			mes = 'Mayo';
			break;
		case '06':
			mes = 'Junio';
			break;
		case '07':
			mes = 'Julio';
			break;
		case '08':
			mes = 'Agosto';
			break;
		case '09':
			mes = 'Septiembre';
			break;
		case '10':
			mes = 'Octubre';
			break;
		case '11':
			mes = 'Noviembre';
			break;
		case '12':
			mes = 'diciembre';
			break;

		default:
			break;
	}
	return mes;
}

export function getDate() {
	const today = new Date();
	const date = `${today.getFullYear()}-${today.getMonth() < 10 ? `${today.getMonth() + 1}` : today.getMonth() + 1}-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()} ${today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()}:${
		today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()
	}:${today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()} ${today.getHours() < 12 ? 'am' : 'pm'}`;
	return date;
}

export function validateEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function setFavicons(favImg) {
	const headTitle = document.querySelector('head');
	const setFavicon = document.createElement('link');
	setFavicon.setAttribute('rel', 'shortcut icon');
	setFavicon.setAttribute('href', favImg);
	headTitle.appendChild(setFavicon);
}

export function downloadFile(file, name) {
	const link = document.createElement('a');
	link.href = `${file}`;
	link.download = `${name}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

// export function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

export function top() {
	window.scrollTo(0, 0);
}

export async function reporte() {
	let array = [];
	const results = await getCollections('users');
	Object.values(results).map((val) => {
		if (val.id) {
			// if (val.id !== '000000' && val.id !== '111111') {
			array.push(val);
			// }
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Usuarios',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Nombre Completo del Line Manager', value: 'nombreLineManager' },
				{ label: 'Audiencia SPIRA CONNECT', value: 'audiencia' },
				{ label: 'Estatus Line Manager', value: 'statusLineManager' },
				{ label: 'Correo', value: 'correo' },
				{ label: 'J1', value: 'j1' },
				{ label: 'J2', value: 'j2' },
				{ label: 'J3', value: 'j3' },
				{ label: 'J4', value: 'j4' },
				{ label: 'J5', value: 'j5' },
				{ label: 'Descarga playbook', value: 'playbook' },
				{ label: 'Fecha de descarga playbook', value: 'descargaPlaybook' },
				{ label: 'Descarga playbook Integrador Home', value: 'playBookHome' },
				{ label: 'Fecha de descarga playbook Integrador Home', value: 'fechaPlaybookHome' },
				{ label: 'Kickoff', value: 'kickoff' },
				{ label: 'Fecha de Kickoff', value: 'fechakickoff' },
				{ label: 'Brochure', value: 'broshure' },
				{ label: 'Fecha de Brochure', value: 'fechaBroshure' },
				{ label: 'Ultima conexón', value: 'ultimaConexion' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Reporte-de-Descargas-Playbook-Integrador-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

// Usuarios Activos TPL
export async function reporteUsuariosActivosTPL() {
	let array = [];
	let json = {};
	const resultsUsers = await getCollectionsComplete('users');
	const resultsBienestar = await getCollectionsComplete('recursos');
	const resultsInclusion = await getCollectionsComplete('pilarInclusion');
	const resultsGestion = await getCollectionsComplete('pilarGestion');
	const likesBienestar = await getCollectionsComplete('likes/bienestar');
	const likesInclusion = await getCollectionsComplete('likes/inclusion');
	const likesGestion = await getCollectionsComplete('likes/gestion');

	const opcionesBienestar = await getCollections('encuestaCertificado');
	const opcionesGestion = await getCollections('encuestaCertificadoGestion');
	const opcionesInclusion = await getCollections('encuestaCertificadoInclusion');

	// Daring Pilares
	const pilarOyente = await getCollectionsComplete('daring/pilarOyente');
	const pilarNarrador = await getCollectionsComplete('daring/pilarNarrador');
	const pilarTransformador = await getCollectionsComplete('daring/pilarTransformador');
	const playbook = await getCollectionsComplete('daring/playbook');
	const likesHomeDaring = await getCollectionsComplete('daring/likes/home');
	const likesOyente = await getCollectionsComplete('daring/likes/oyente');
	const likesNarrador = await getCollectionsComplete('daring/likes/narrador');
	const likesTransformador = await getCollectionsComplete('daring/likes/transformador');
	const encuestaOyente = await getCollectionsComplete('daring/encuesta/oyente');
	const encuestaNarrador = await getCollectionsComplete('daring/encuesta/narrador');
	const encuestatransformador = await getCollectionsComplete('daring/encuesta/transformador');
	const masterclassDaring = await getCollectionsComplete('daring/masterclass');

	Object.keys(resultsUsers).map((valUSuarios) => {
		const dataBienestar = resultsBienestar[valUSuarios];
		const dataInclusion = resultsInclusion[valUSuarios];
		const dataGestion = resultsGestion[valUSuarios];
		const dataOyente = pilarOyente[valUSuarios];
		const dataNarrador = pilarNarrador[valUSuarios];
		const dataTransformador = pilarTransformador[valUSuarios];
		const dataPlaybook = playbook[valUSuarios];
		const dataMasterclass = masterclassDaring[valUSuarios];
		const user = resultsUsers[valUSuarios];

		// VALIDAMOS SI EL USUARIO ESTA REGISTRADO EN USERS

		// DATA Bienestar
		// if (dataBienestar || !dataBienestar) {
		// if (dataBienestar && user.activo == true) {
		if (user.activo == true) {
			// FECHAS
			let fechaMasterFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoMasterClass) {
						const fecha = dataBienestar.fechaVistoMasterClass;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPodcastFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoPodcast) {
						const fecha = dataBienestar.fechaVistoPodcast;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaTutorialFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoTutorial) {
						const fecha = dataBienestar.fechaVistoTutorial;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPlaybookFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoPlaybook) {
						const fecha = dataBienestar.fechaVistoPlaybook;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaEvaluacionFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoEvaluacion) {
						const fecha = dataBienestar.fechaVistoEvaluacion;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};

			// LIKES
			let likeMasterBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].masterClass ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePodcastBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].podcast ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeTutorialBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].tutorial ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePlaybookBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].playbook ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeEvaluacionBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '';
				}
			};

			// Status
			let statusMasterClass = () => {
				let status = ' ';
				if (dataBienestar && user.activo) {
					status = 'Verdadero';
				}
				return status;
				// if (dataBienestar || user.activo == true) {
				//   if (dataBienestar.masterClass) {
				//     return "Verdadero";
				//   } else {
				//     return " ";
				//   }
				// }
			};
			let statusPodcast = () => {
				let status = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.podcast) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusTutorial = () => {
				let status = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.tutorial) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPlaybook = () => {
				let status = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.playbook) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacion = () => {
				let status = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.evaluacion) {
						status = 'Verdadero';
						// Object.values(opcionesBienestar).map((val) => {
						// 	if (val.user === valUSuarios) {
						// 		status = val.calif;
						// 	}
						// });
					}
				}
				return status;
			};

			let calif = () => {
				let calificacion = '';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.evaluacion) {
						Object.values(opcionesBienestar).map((val) => {
							if (val.user === valUSuarios) {
								calificacion = val.calif;
							}
						});
					}
				}
				return calificacion;
			};
			// Llenar array de objetos
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Masterclass',
				status: statusMasterClass(),
				fecha: fechaMasterFinal(),
				satisfaccion: likeMasterBienestar(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Podcast',
				status: statusPodcast(),
				fecha: fechaPodcastFinal(),
				satisfaccion: likePodcastBienestar(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Tutorial',
				status: statusTutorial(),
				fecha: fechaTutorialFinal(),
				satisfaccion: likeTutorialBienestar(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Playbook',
				status: statusPlaybook(),
				fecha: fechaPlaybookFinal(),
				satisfaccion: likePlaybookBienestar(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacion(),
				fecha: fechaEvaluacionFinal(),
				satisfaccion: likeEvaluacionBienestar(),
				audiencia: user.audiencia,
			});
		}

		// DATA Incusion
		// if (dataInclusion || !dataInclusion) {
		// if (dataInclusion && user.activo == true) {
		if (user.activo == true) {
			// FECHAS
			let fechaMasterFinal = () => {
				let fechaFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoMasterClass) {
						const fecha = dataInclusion.fechaVistoMasterClass;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPodcastFinal = () => {
				let fechaFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoPodcast) {
						const fecha = dataInclusion.fechaVistoPodcast;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaTutorialFinal = () => {
				let fechaFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoTutorial) {
						const fecha = dataInclusion.fechaVistoTutorial;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPlaybookFinal = () => {
				let fechaFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoPlaybook) {
						const fecha = dataInclusion.fechaVistoPlaybook;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaPlaybookFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaPlaybookFinal;
			};
			let fechaEvaluacionFinal = () => {
				let fechaFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoEvaluacion) {
						const fecha = dataInclusion.fechaVistoEvaluacion;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaEvaluacionFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaEvaluacionFinal;
			};

			// LIKES
			let likeMasterInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].masterClass ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePodcastInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].podcast ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeTutorialInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].tutorial ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePlaybookInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].playbook ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeEvaluacionInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '';
				}
			};

			// Status
			let statusMasterClass = () => {
				let status = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.masterClass) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcast = () => {
				let status = ' ';

				if (dataInclusion && user.activo == true) {
					if (dataInclusion.podcast) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusTutorial = () => {
				let status = ' ';

				if (dataInclusion && user.activo == true) {
					if (dataInclusion.tutorial) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPlaybook = () => {
				let status = ' ';

				if (dataInclusion && user.activo == true) {
					if (dataInclusion.playbook) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacion = () => {
				let status = ' ';

				if (dataInclusion && user.activo == true) {
					if (dataInclusion.evaluacion) {
						status = 'Verdadero';
						// Object.values(opcionesInclusion).map((val) => {
						// 	if (val.user === valUSuarios) {
						// 		status = val.calif;
						// 	}
						// });
					}
				}
				return status;
			};
			let calif = () => {
				let calificacion = '';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.evaluacion) {
						Object.values(opcionesBienestar).map((val) => {
							if (val.user === valUSuarios) {
								calificacion = val.calif;
							}
						});
					}
				}
				return calificacion;
			};

			// Llenar JSON
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Masterclass',
				status: statusMasterClass(),
				fecha: fechaMasterFinal(),
				satisfaccion: likeMasterInclusion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Podcast',
				status: statusPodcast(),
				fecha: fechaPodcastFinal(),
				satisfaccion: likePodcastInclusion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Tutorial',
				status: statusTutorial(),
				fecha: fechaTutorialFinal(),
				satisfaccion: likeTutorialInclusion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Playbook',
				status: statusPlaybook(),
				fecha: fechaPlaybookFinal(),
				satisfaccion: likePlaybookInclusion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacion(),
				fecha: fechaEvaluacionFinal(),
				satisfaccion: likeEvaluacionInclusion(),
				audiencia: user.audiencia,
			});
		}

		// DATA Gestion
		// if (dataInclusion || !dataInclusion) {
		// if (dataInclusion && user.activo == true) {
		if (user.activo == true) {
			// FECHAS
			let fechaMasterFinalGestion = () => {
				let fechaFinal = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoMasterClass) {
						const fecha = dataGestion.fechaVistoMasterClass;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPodcastFinalGestion = () => {
				let fechaFinal = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoPodcast) {
						const fecha = dataGestion.fechaVistoPodcast;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaTutorialFinalGestion = () => {
				let fechaFinal = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoTutorial) {
						const fecha = dataGestion.fechaVistoTutorial;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPlaybookFinalGestion = () => {
				let fechaPlaybookFinalGestion = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoPlaybook) {
						const fecha = dataGestion.fechaVistoPlaybook;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaPlaybookFinalGestion = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaPlaybookFinalGestion;
			};
			let fechaEvaluacionFinalGestion = () => {
				let fechaEvaluacionFinalGestion = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoEvaluacion) {
						const fecha = dataGestion.fechaVistoEvaluacion;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaEvaluacionFinalGestion = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaEvaluacionFinalGestion;
			};

			// LIKES
			let likeMasterInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].masterClass ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePodcastInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].podcast ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeTutorialInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].tutorial ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePlaybookInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].playbook ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeEvaluacionInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '';
				}
			};

			// Status
			let statusMasterClassGestion = () => {
				let status = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.masterClass) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcastGestion = () => {
				let status = ' ';

				if (dataGestion && user.activo == true) {
					if (dataGestion.podcast) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusTutorialGestion = () => {
				let status = ' ';

				if (dataGestion && user.activo == true) {
					if (dataGestion.tutorial) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPlaybookGestion = () => {
				let status = ' ';

				if (dataGestion && user.activo == true) {
					if (dataGestion.playbook) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacionGestion = () => {
				let status = ' ';

				if (dataGestion && user.activo == true) {
					if (dataGestion.evaluacion) {
						status = 'Verdadero';
						// Object.values(opcionesGestion).map((val) => {
						// 	if (val.user === valUSuarios) {
						// 		status = val.calif;
						// 	}
						// });
					}
				}
				return status;
			};
			let calif = () => {
				let calificacion = '';
				if (dataGestion && user.activo == true) {
					if (dataGestion.evaluacion) {
						Object.values(opcionesBienestar).map((val) => {
							if (val.user === valUSuarios) {
								calificacion = val.calif;
							}
						});
					}
				}
				return calificacion;
			};

			// Llenar JSON
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Masterclass',
				status: statusMasterClassGestion(),
				fecha: fechaMasterFinalGestion(),
				satisfaccion: likeMasterInclusionGestion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Podcast',
				status: statusPodcastGestion(),
				fecha: fechaPodcastFinalGestion(),
				satisfaccion: likePodcastInclusionGestion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Tutorial',
				status: statusTutorialGestion(),
				fecha: fechaTutorialFinalGestion(),
				satisfaccion: likeTutorialInclusionGestion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Playbook',
				status: statusPlaybookGestion(),
				fecha: fechaPlaybookFinalGestion(),
				satisfaccion: likePlaybookInclusionGestion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacionGestion(),
				fecha: fechaEvaluacionFinalGestion(),
				satisfaccion: likeEvaluacionInclusionGestion(),
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Oyente
		if (user.activo == true) {
			let fechaTutorialFinalOyente = () => {
				let fechaFinalPodcast = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoTutorial1) {
						const fecha = dataOyente.fechaVistoTutorial1;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinalPodcast = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaPodcastFinalOyente = () => {
				let fechaFinalPodcast = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoPodcast1) {
						const fechaPodcast = dataOyente.fechaVistoPodcast1;
						const fechaArreglo1Podcast = stringToArray(fechaPodcast, ' ');
						const fechaArreglo2Podcast = stringToArray(fechaArreglo1Podcast[0], '-');
						fechaFinalPodcast = `${fechaArreglo2Podcast[2]}/${fechaArreglo2Podcast[1]}/${fechaArreglo2Podcast[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaEvaluacionFinalOyente = () => {
				let fechaFinalEvaluacion = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoEvaluacion) {
						const fechaEvaluacion = dataOyente.fechaVistoEvaluacion;
						const fechaArreglo1Evaluacion = stringToArray(fechaEvaluacion, ' ');
						const fechaArreglo2Evaluacion = stringToArray(fechaArreglo1Evaluacion[0], '-');
						fechaFinalEvaluacion = `${fechaArreglo2Evaluacion[2]}/${fechaArreglo2Evaluacion[1]}/${fechaArreglo2Evaluacion[0]}`;
					}
				}
				return fechaFinalEvaluacion;
			};
			let fechaInsigniaFinalOyente = () => {
				let fechaFinalInsignia = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoInsignia) {
						const fechaInsignia = dataOyente.fechaVistoInsignia;
						const fechaArreglo1Insignia = stringToArray(fechaInsignia, ' ');
						const fechaArreglo2Insignia = stringToArray(fechaArreglo1Insignia[0], '-');
						fechaFinalInsignia = `${fechaArreglo2Insignia[2]}/${fechaArreglo2Insignia[1]}/${fechaArreglo2Insignia[0]}`;
					}
				}
				return fechaFinalInsignia;
			};
			let fechaDescargaInsigniaFinalOyente = () => {
				let fechaFinalDescargaInsignia = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoDescargaInsignia) {
						const fechaDescargaInsignia = dataOyente.descargaInsignia;
						const fechaArreglo1DescargaInsignia = stringToArray(fechaDescargaInsignia, ' ');
						const fechaArreglo2DescargaInsignia = stringToArray(fechaArreglo1DescargaInsignia[0], '-');
						fechaFinalDescargaInsignia = `${fechaArreglo2DescargaInsignia[2]}/${fechaArreglo2DescargaInsignia[1]}/${fechaArreglo2DescargaInsignia[0]}`;
					}
				}
				return fechaFinalDescargaInsignia;
			};

			// LIKES
			let likesTutorialOyentes = () => {
				if (likesOyente[valUSuarios]) {
					return likesOyente[valUSuarios].tutorial1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesPodcastOyentes = () => {
				if (likesOyente[valUSuarios]) {
					return likesOyente[valUSuarios].podcast1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesEvaluacionOyentes = () => {
				if (likesOyente[valUSuarios]) {
					return likesOyente[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '-';
				}
			};

			// STATUS
			let statusTutorialOyente = () => {
				let status = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.tutorial1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcastOyente = () => {
				let status = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.podcast1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacionOyente = () => {
				let status = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.evaluacion) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusInsigniaOyente = () => {
				let status = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.insignia) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			const calif = () => {
				let calif = '';
				Object.values(encuestaOyente).map((val) => {
					if (val.user === valUSuarios) {
						calif = val.puntos;
					}
				});
				return calif;
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Tutorial',
				status: statusTutorialOyente(),
				fecha: fechaTutorialFinalOyente(),
				satisfaccion: likesTutorialOyentes(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Podcast',
				status: statusPodcastOyente(),
				fecha: fechaPodcastFinalOyente(),
				satisfaccion: likesPodcastOyentes(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacionOyente(),
				fecha: fechaEvaluacionFinalOyente(),
				satisfaccion: likesEvaluacionOyentes(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Insignia',
				status: statusInsigniaOyente(),
				fecha: fechaInsigniaFinalOyente(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Narrador
		if (user.activo == true) {
			let fechaTutorialFinalNarrador = () => {
				let fechaFinalPodcast = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoTutorial1) {
						const fecha = dataNarrador.fechaVistoTutorial1;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinalPodcast = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaPodcastFinalNarrador = () => {
				let fechaFinalPodcast = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoPodcast1) {
						const fechaPodcast = dataNarrador.fechaVistoPodcast1;
						const fechaArreglo1Podcast = stringToArray(fechaPodcast, ' ');
						const fechaArreglo2Podcast = stringToArray(fechaArreglo1Podcast[0], '-');
						fechaFinalPodcast = `${fechaArreglo2Podcast[2]}/${fechaArreglo2Podcast[1]}/${fechaArreglo2Podcast[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaEvaluacionFinalNarrador = () => {
				let fechaFinalEvaluacion = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoEvaluacion) {
						const fechaEvaluacion = dataNarrador.fechaVistoEvaluacion;
						const fechaArreglo1Evaluacion = stringToArray(fechaEvaluacion, ' ');
						const fechaArreglo2Evaluacion = stringToArray(fechaArreglo1Evaluacion[0], '-');
						fechaFinalEvaluacion = `${fechaArreglo2Evaluacion[2]}/${fechaArreglo2Evaluacion[1]}/${fechaArreglo2Evaluacion[0]}`;
					}
				}
				return fechaFinalEvaluacion;
			};
			let fechaInsigniaFinalNarrador = () => {
				let fechaFinalInsignia = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoInsignia) {
						const fechaInsignia = dataNarrador.fechaVistoInsignia;
						const fechaArreglo1Insignia = stringToArray(fechaInsignia, ' ');
						const fechaArreglo2Insignia = stringToArray(fechaArreglo1Insignia[0], '-');
						fechaFinalInsignia = `${fechaArreglo2Insignia[2]}/${fechaArreglo2Insignia[1]}/${fechaArreglo2Insignia[0]}`;
					}
				}
				return fechaFinalInsignia;
			};
			let fechaDescargaInsigniaFinalNarrador = () => {
				let fechaFinalDescargaInsignia = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoDescargaInsignia) {
						const fechaDescargaInsignia = dataNarrador.descargaInsignia;
						const fechaArreglo1DescargaInsignia = stringToArray(fechaDescargaInsignia, ' ');
						const fechaArreglo2DescargaInsignia = stringToArray(fechaArreglo1DescargaInsignia[0], '-');
						fechaFinalDescargaInsignia = `${fechaArreglo2DescargaInsignia[2]}/${fechaArreglo2DescargaInsignia[1]}/${fechaArreglo2DescargaInsignia[0]}`;
					}
				}
				return fechaFinalDescargaInsignia;
			};

			// LIKES
			let likesTutorialNarrador = () => {
				if (likesNarrador[valUSuarios]) {
					return likesNarrador[valUSuarios].tutorial1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesPodcastNarrador = () => {
				if (likesNarrador[valUSuarios]) {
					return likesNarrador[valUSuarios].podcast1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesEvaluacionNarrador = () => {
				if (likesNarrador[valUSuarios]) {
					return likesNarrador[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '-';
				}
			};

			// STATUS
			let statusTutorialNarrador = () => {
				let status = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.tutorial1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcastNarrador = () => {
				let status = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.podcast1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacionNarrador = () => {
				let status = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.evaluacion) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusInsigniaNarrador = () => {
				let status = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.insignia) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			const calif = () => {
				let calif = '';
				Object.values(encuestaNarrador).map((val) => {
					if (val.user === valUSuarios) {
						calif = val.puntos;
					}
				});
				return calif;
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Narrador/Activador',
				material: 'Tutorial',
				status: statusTutorialNarrador(),
				fecha: fechaTutorialFinalNarrador(),
				satisfaccion: likesTutorialNarrador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Narrador/Activador',
				material: 'Podcast',
				status: statusPodcastNarrador(),
				fecha: fechaPodcastFinalNarrador(),
				satisfaccion: likesPodcastNarrador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Narrador/Activador',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacionNarrador(),
				fecha: fechaEvaluacionFinalNarrador(),
				satisfaccion: likesEvaluacionNarrador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Narrador/Activador',
				material: 'Insignia',
				status: statusInsigniaNarrador(),
				fecha: fechaInsigniaFinalNarrador(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Transformador
		if (user.activo == true) {
			let fechaTutorialFinalTransformador = () => {
				let fechaFinalPodcast = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoTutorial1) {
						const fecha = dataTransformador.fechaVistoTutorial1;
						const fechaArreglo1 = stringToArray(fecha, ' ');
						const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						fechaFinalPodcast = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaPodcastFinalTransformador = () => {
				let fechaFinalPodcast = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoPodcast1) {
						const fechaPodcast = dataTransformador.fechaVistoPodcast1;
						const fechaArreglo1Podcast = stringToArray(fechaPodcast, ' ');
						const fechaArreglo2Podcast = stringToArray(fechaArreglo1Podcast[0], '-');
						fechaFinalPodcast = `${fechaArreglo2Podcast[2]}/${fechaArreglo2Podcast[1]}/${fechaArreglo2Podcast[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaEvaluacionFinalTransformador = () => {
				let fechaFinalEvaluacion = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoEvaluacion) {
						const fechaEvaluacion = dataTransformador.fechaVistoEvaluacion;
						const fechaArreglo1Evaluacion = stringToArray(fechaEvaluacion, ' ');
						const fechaArreglo2Evaluacion = stringToArray(fechaArreglo1Evaluacion[0], '-');
						fechaFinalEvaluacion = `${fechaArreglo2Evaluacion[2]}/${fechaArreglo2Evaluacion[1]}/${fechaArreglo2Evaluacion[0]}`;
					}
				}
				return fechaFinalEvaluacion;
			};
			let fechaInsigniaFinalTransformador = () => {
				let fechaFinalInsignia = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoInsignia) {
						const fechaInsignia = dataTransformador.fechaVistoInsignia;
						const fechaArreglo1Insignia = stringToArray(fechaInsignia, ' ');
						const fechaArreglo2Insignia = stringToArray(fechaArreglo1Insignia[0], '-');
						fechaFinalInsignia = `${fechaArreglo2Insignia[2]}/${fechaArreglo2Insignia[1]}/${fechaArreglo2Insignia[0]}`;
					}
				}
				return fechaFinalInsignia;
			};
			let fechaDescargaInsigniaFinalTransformador = () => {
				let fechaFinalDescargaInsignia = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoDescargaInsignia) {
						const fechaDescargaInsignia = dataTransformador.descargaInsignia;
						const fechaArreglo1DescargaInsignia = stringToArray(fechaDescargaInsignia, ' ');
						const fechaArreglo2DescargaInsignia = stringToArray(fechaArreglo1DescargaInsignia[0], '-');
						fechaFinalDescargaInsignia = `${fechaArreglo2DescargaInsignia[2]}/${fechaArreglo2DescargaInsignia[1]}/${fechaArreglo2DescargaInsignia[0]}`;
					}
				}
				return fechaFinalDescargaInsignia;
			};

			// LIKES
			let likesTutorialTransformador = () => {
				if (likesTransformador[valUSuarios]) {
					return likesTransformador[valUSuarios].tutorial1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesPodcastTransformador = () => {
				if (likesTransformador[valUSuarios]) {
					return likesTransformador[valUSuarios].podcast1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesEvaluacionTransformador = () => {
				if (likesTransformador[valUSuarios]) {
					return likesTransformador[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '-';
				}
			};

			// STATUS
			let statusTutorialTransformador = () => {
				let status = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.tutorial1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcastTransformador = () => {
				let status = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.podcast1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacionTransformador = () => {
				let status = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.evaluacion) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusInsigniaTransformador = () => {
				let status = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.insignia) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			const calif = () => {
				let calif = '';
				Object.values(encuestatransformador).map((val) => {
					if (val.user === valUSuarios) {
						calif = val.puntos;
					}
				});
				return calif;
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Transformador',
				material: 'Tutorial',
				status: statusTutorialTransformador(),
				fecha: fechaTutorialFinalTransformador(),
				satisfaccion: likesTutorialTransformador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Transformador',
				material: 'Podcast',
				status: statusPodcastTransformador(),
				fecha: fechaPodcastFinalTransformador(),
				satisfaccion: likesPodcastTransformador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Transformador',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacionTransformador(),
				fecha: fechaEvaluacionFinalTransformador(),
				satisfaccion: likesEvaluacionTransformador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Transformador',
				material: 'Insignia',
				status: statusInsigniaTransformador(),
				fecha: fechaDescargaInsigniaFinalTransformador(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Home
		// DATA Daring masterclass
		if (user.activo == true) {
			let likeHomeDaring = () => {
				if (likesHomeDaring[valUSuarios]) {
					return likesHomeDaring[valUSuarios].masterClass ? 'Like' : '';
				} else {
					return '-';
				}
			};

			let fechaMasterClassDaring = () => {
				let fechaFinalMasterClass = ' ';
				if (dataMasterclass && user.activo == true) {
					if (dataMasterclass.fechaDeMasterClass) {
						const fechaMC = dataMasterclass.fechaDeMasterClass;
						const fechaArregloMC = stringToArray(fechaMC, ' ');
						const fechaArreglo2MC = stringToArray(fechaArregloMC[0], '-');
						fechaFinalMasterClass = `${fechaArreglo2MC[2]}/${fechaArreglo2MC[1]}/${fechaArreglo2MC[0]}`;
					}
				}
				return fechaFinalMasterClass;
			};

			// LIKES
			let getMasterClass = () => {
				let texto = '';
				if (dataMasterclass) {
					// console.log(dataMasterclass[valUSuarios]);
					texto = dataMasterclass.masterclass ? 'Verdadero' : '';
				}
				return texto;
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Daring/Home',
				material: 'MasterClass',
				calificacion: '',
				status: getMasterClass(),
				fecha: fechaMasterClassDaring(),
				satisfaccion: likeHomeDaring(),
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Playbook
		if (user.activo == true) {
			let fechaDescargaPlaybookDaring = () => {
				let fechaFinalDescargaPlaybook = ' ';
				if (dataPlaybook && user.activo == true) {
					if (dataPlaybook.fechaPlaybook) {
						const fechaDescargaPlaybook = dataPlaybook.fechaPlaybook;
						const fechaArreglo1DescargaPlaybook = stringToArray(fechaDescargaPlaybook, ' ');
						const fechaArreglo2DescargaPlaybook = stringToArray(fechaArreglo1DescargaPlaybook[0], '-');
						fechaFinalDescargaPlaybook = `${fechaArreglo2DescargaPlaybook[2]}/${fechaArreglo2DescargaPlaybook[1]}/${fechaArreglo2DescargaPlaybook[0]}`;
					}
				}
				return fechaFinalDescargaPlaybook;
			};

			// LIKES
			let getPlaybook = () => {
				if (playbook[valUSuarios]) {
					return playbook[valUSuarios].playbook ? 'Verdadero' : '';
				} else {
					return '';
				}
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Daring/Home',
				material: 'Playbook',
				calificacion: '',
				status: getPlaybook(),
				fecha: fechaDescargaPlaybookDaring(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
		}
		// console.log(array);
	});

	let data = [
		{
			sheet: 'Recursos Digitales',
			columns: [
				{ label: 'UserID', value: 'userID' },
				{ label: 'IDGlobal', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Pilar', value: 'pilar' },
				{ label: 'Material', value: 'material' },
				// { label: 'Calificación', value: 'calificacion' },
				{ label: 'Status', value: 'status' },
				{ label: 'Fecha', value: 'fecha' },
				{ label: 'Satisfaccion', value: 'satisfaccion' },
				{ label: 'Audiencia', value: 'audiencia' },
			],
			content: array,
		},
	];
	const fecha = cambiarTextos(getDate());
	let settings = {
		fileName: `Reporte-HNK-TPL-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
	// let currentSheetIndex = 0;
	// const totalSheets = data.length;

	// const progressInterval = setInterval(() => {
	// 	const percentComplete = (currentSheetIndex / totalSheets) * 100;
	// 	console.log(`Porcentaje completado: ${percentComplete.toFixed(2)}%`);
	// }, 1000);

	// settings.callback = function (err, stats) {
	// 	clearInterval(progressInterval); // Detener el temporizador cuando se completa la escritura
	// 	if (err) {
	// 		console.error(err);
	// 	} else {
	// 		currentSheetIndex = stats.currentSheetIndex;
	// 		const percentComplete = (currentSheetIndex / totalSheets) * 100;
	// 		console.log(`Porcentaje completado: ${percentComplete.toFixed(2)}%`);
	// 	}
	// };
}

// Usuarios Activos TPL con CALIF
export async function reporteUsuariosActivosTPLConCalif() {
	let array = [];
	let json = {};
	const resultsUsers = await getCollectionsComplete('users');
	const resultsBienestar = await getCollectionsComplete('recursos');
	const resultsInclusion = await getCollectionsComplete('pilarInclusion');
	const resultsGestion = await getCollectionsComplete('pilarGestion');
	const likesBienestar = await getCollectionsComplete('likes/bienestar');
	const likesInclusion = await getCollectionsComplete('likes/inclusion');
	const likesGestion = await getCollectionsComplete('likes/gestion');

	const opcionesBienestar = await getCollections('encuestaCertificado');
	const opcionesGestion = await getCollections('encuestaCertificadoGestion');
	const opcionesInclusion = await getCollections('encuestaCertificadoInclusion');

	// Daring Pilares
	const pilarOyente = await getCollectionsComplete('daring/pilarOyente');
	const pilarNarrador = await getCollectionsComplete('daring/pilarNarrador');
	const pilarTransformador = await getCollectionsComplete('daring/pilarTransformador');
	const playbook = await getCollectionsComplete('daring/playbook');
	const likesHomeDaring = await getCollectionsComplete('daring/likes/home');
	const likesOyente = await getCollectionsComplete('daring/likes/oyente');
	const likesNarrador = await getCollectionsComplete('daring/likes/narrador');
	const likesTransformador = await getCollectionsComplete('daring/likes/transformador');
	const encuestaOyente = await getCollectionsComplete('daring/encuesta/oyente');
	const encuestaNarrador = await getCollectionsComplete('daring/encuesta/narrador');
	const encuestatransformador = await getCollectionsComplete('daring/encuesta/transformador');
	const masterclassDaring = await getCollectionsComplete('daring/masterclass');
	const kickOffDaring = await getCollectionsComplete('daring/kickoff');
	const reconocimientoDaring = await getCollectionsComplete('daring/reconocimiento');

	Object.keys(resultsUsers).map((valUSuarios) => {
		const dataBienestar = resultsBienestar[valUSuarios];
		const dataInclusion = resultsInclusion[valUSuarios];
		const dataGestion = resultsGestion[valUSuarios];
		const dataOyente = pilarOyente[valUSuarios];
		const dataNarrador = pilarNarrador[valUSuarios];
		const dataTransformador = pilarTransformador[valUSuarios];
		const dataPlaybook = playbook[valUSuarios];
		const dataMasterclass = masterclassDaring[valUSuarios];
		const dataKickOff = kickOffDaring[valUSuarios];
		const dataReconocimiento = reconocimientoDaring[valUSuarios];
		const user = resultsUsers[valUSuarios];

		// VALIDAMOS SI EL USUARIO ESTA REGISTRADO EN USERS

		// DATA Bienestar
		// if (dataBienestar || !dataBienestar) {
		// if (dataBienestar && user.activo == true) {
		if (user.activo == true) {
			// FECHAS
			let fechaMasterFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoMasterClass) {
						fechaFinal = dataBienestar.fechaVistoMasterClass;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPodcastFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoPodcast) {
						fechaFinal = dataBienestar.fechaVistoPodcast;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaTutorialFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoTutorial) {
						fechaFinal = dataBienestar.fechaVistoTutorial;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPlaybookFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoPlaybook) {
						fechaFinal = dataBienestar.fechaVistoPlaybook;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaEvaluacionFinal = () => {
				let fechaFinal = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.fechaVistoEvaluacion) {
						fechaFinal = dataBienestar.fechaVistoEvaluacion;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};

			// LIKES
			let likeMasterBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].masterClass ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePodcastBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].podcast ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeTutorialBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].tutorial ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePlaybookBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].playbook ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeEvaluacionBienestar = () => {
				if (likesBienestar[valUSuarios]) {
					return likesBienestar[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '';
				}
			};

			// Status
			let statusMasterClass = () => {
				let status = ' ';
				if (dataBienestar && user.activo) {
					status = 'Verdadero';
				}
				return status;
				// if (dataBienestar || user.activo == true) {
				//   if (dataBienestar.masterClass) {
				//     return "Verdadero";
				//   } else {
				//     return " ";
				//   }
				// }
			};
			let statusPodcast = () => {
				let status = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.podcast) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusTutorial = () => {
				let status = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.tutorial) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPlaybook = () => {
				let status = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.playbook) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacion = () => {
				let status = ' ';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.evaluacion) {
						status = 'Verdadero';
						// Object.values(opcionesBienestar).map((val) => {
						// 	if (val.user === valUSuarios) {
						// 		status = val.calif;
						// 	}
						// });
					}
				}
				return status;
			};

			let calif = () => {
				let calificacion = '';
				if (dataBienestar && user.activo == true) {
					if (dataBienestar.evaluacion) {
						Object.values(opcionesBienestar).map((val) => {
							if (val.user === valUSuarios) {
								calificacion = val.calif;
							}
						});
					}
				}
				return calificacion;
			};
			// Llenar array de objetos
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Masterclass',
				status: statusMasterClass(),
				fecha: fechaMasterFinal(),
				satisfaccion: likeMasterBienestar(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Podcast',
				status: statusPodcast(),
				fecha: fechaPodcastFinal(),
				satisfaccion: likePodcastBienestar(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Tutorial',
				status: statusTutorial(),
				fecha: fechaTutorialFinal(),
				satisfaccion: likeTutorialBienestar(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Playbook',
				status: statusPlaybook(),
				fecha: fechaPlaybookFinal(),
				satisfaccion: likePlaybookBienestar(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Bienestar',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacion(),
				fecha: fechaEvaluacionFinal(),
				satisfaccion: likeEvaluacionBienestar(),
				audiencia: user.audiencia,
			});
		}

		// DATA Incusion
		// if (dataInclusion || !dataInclusion) {
		// if (dataInclusion && user.activo == true) {
		if (user.activo == true) {
			// FECHAS
			let fechaMasterFinal = () => {
				let fechaFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoMasterClass) {
						fechaFinal = dataInclusion.fechaVistoMasterClass;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPodcastFinal = () => {
				let fechaFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoPodcast) {
						fechaFinal = dataInclusion.fechaVistoPodcast;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaTutorialFinal = () => {
				let fechaFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoTutorial) {
						fechaFinal = dataInclusion.fechaVistoTutorial;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPlaybookFinal = () => {
				let fechaPlaybookFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoPlaybook) {
						fechaPlaybookFinal = dataInclusion.fechaVistoPlaybook;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaPlaybookFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaPlaybookFinal;
			};
			let fechaEvaluacionFinal = () => {
				let fechaEvaluacionFinal = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.fechaVistoEvaluacion) {
						const fechaEvaluacionFinal = dataInclusion.fechaVistoEvaluacion;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaEvaluacionFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaEvaluacionFinal;
			};

			// LIKES
			let likeMasterInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].masterClass ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePodcastInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].podcast ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeTutorialInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].tutorial ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePlaybookInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].playbook ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeEvaluacionInclusion = () => {
				if (likesInclusion[valUSuarios]) {
					return likesInclusion[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '';
				}
			};

			// Status
			let statusMasterClass = () => {
				let status = ' ';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.masterClass) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcast = () => {
				let status = ' ';

				if (dataInclusion && user.activo == true) {
					if (dataInclusion.podcast) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusTutorial = () => {
				let status = ' ';

				if (dataInclusion && user.activo == true) {
					if (dataInclusion.tutorial) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPlaybook = () => {
				let status = ' ';

				if (dataInclusion && user.activo == true) {
					if (dataInclusion.playbook) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacion = () => {
				let status = ' ';

				if (dataInclusion && user.activo == true) {
					if (dataInclusion.evaluacion) {
						status = 'Verdadero';
						// Object.values(opcionesInclusion).map((val) => {
						// 	if (val.user === valUSuarios) {
						// 		status = val.calif;
						// 	}
						// });
					}
				}
				return status;
			};
			let calif = () => {
				let calificacion = '';
				if (dataInclusion && user.activo == true) {
					if (dataInclusion.evaluacion) {
						Object.values(opcionesBienestar).map((val) => {
							if (val.user === valUSuarios) {
								calificacion = val.calif;
							}
						});
					}
				}
				return calificacion;
			};

			// Llenar JSON
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Masterclass',
				status: statusMasterClass(),
				fecha: fechaMasterFinal(),
				satisfaccion: likeMasterInclusion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Podcast',
				status: statusPodcast(),
				fecha: fechaPodcastFinal(),
				satisfaccion: likePodcastInclusion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Tutorial',
				status: statusTutorial(),
				fecha: fechaTutorialFinal(),
				satisfaccion: likeTutorialInclusion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Playbook',
				status: statusPlaybook(),
				fecha: fechaPlaybookFinal(),
				satisfaccion: likePlaybookInclusion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Inclusion',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacion(),
				fecha: fechaEvaluacionFinal(),
				satisfaccion: likeEvaluacionInclusion(),
				audiencia: user.audiencia,
			});
		}

		// DATA Gestion
		// if (dataInclusion || !dataInclusion) {
		// if (dataInclusion && user.activo == true) {
		if (user.activo == true) {
			// FECHAS
			let fechaMasterFinalGestion = () => {
				let fechaFinal = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoMasterClass) {
						fechaFinal = dataGestion.fechaVistoMasterClass;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPodcastFinalGestion = () => {
				let fechaFinal = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoPodcast) {
						fechaFinal = dataGestion.fechaVistoPodcast;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaTutorialFinalGestion = () => {
				let fechaFinal = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoTutorial) {
						fechaFinal = dataGestion.fechaVistoTutorial;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinal = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinal;
			};
			let fechaPlaybookFinalGestion = () => {
				let fechaPlaybookFinalGestion = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoPlaybook) {
						fechaPlaybookFinalGestion = dataGestion.fechaVistoPlaybook;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaPlaybookFinalGestion = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaPlaybookFinalGestion;
			};
			let fechaEvaluacionFinalGestion = () => {
				let fechaEvaluacionFinalGestion = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.fechaVistoEvaluacion) {
						fechaEvaluacionFinalGestion = dataGestion.fechaVistoEvaluacion;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaEvaluacionFinalGestion = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaEvaluacionFinalGestion;
			};

			// LIKES
			let likeMasterInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].masterClass ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePodcastInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].podcast ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeTutorialInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].tutorial ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likePlaybookInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].playbook ? 'Like' : '';
				} else {
					return '';
				}
			};
			let likeEvaluacionInclusionGestion = () => {
				if (likesGestion[valUSuarios]) {
					return likesGestion[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '';
				}
			};

			// Status
			let statusMasterClassGestion = () => {
				let status = ' ';
				if (dataGestion && user.activo == true) {
					if (dataGestion.masterClass) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcastGestion = () => {
				let status = ' ';

				if (dataGestion && user.activo == true) {
					if (dataGestion.podcast) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusTutorialGestion = () => {
				let status = ' ';

				if (dataGestion && user.activo == true) {
					if (dataGestion.tutorial) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPlaybookGestion = () => {
				let status = ' ';

				if (dataGestion && user.activo == true) {
					if (dataGestion.playbook) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacionGestion = () => {
				let status = ' ';

				if (dataGestion && user.activo == true) {
					if (dataGestion.evaluacion) {
						status = 'Verdadero';
						// Object.values(opcionesGestion).map((val) => {
						// 	if (val.user === valUSuarios) {
						// 		status = val.calif;
						// 	}
						// });
					}
				}
				return status;
			};
			let calif = () => {
				let calificacion = '';
				if (dataGestion && user.activo == true) {
					if (dataGestion.evaluacion) {
						Object.values(opcionesBienestar).map((val) => {
							if (val.user === valUSuarios) {
								calificacion = val.calif;
							}
						});
					}
				}
				return calificacion;
			};

			// Llenar JSON
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Masterclass',
				status: statusMasterClassGestion(),
				fecha: fechaMasterFinalGestion(),
				satisfaccion: likeMasterInclusionGestion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Podcast',
				status: statusPodcastGestion(),
				fecha: fechaPodcastFinalGestion(),
				satisfaccion: likePodcastInclusionGestion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Tutorial',
				status: statusTutorialGestion(),
				fecha: fechaTutorialFinalGestion(),
				satisfaccion: likeTutorialInclusionGestion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Playbook',
				status: statusPlaybookGestion(),
				fecha: fechaPlaybookFinalGestion(),
				satisfaccion: likePlaybookInclusionGestion(),
				audiencia: user.audiencia,
			});

			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Gestión',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacionGestion(),
				fecha: fechaEvaluacionFinalGestion(),
				satisfaccion: likeEvaluacionInclusionGestion(),
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Oyente
		if (user.activo == true) {
			let fechaTutorialFinalOyente = () => {
				let fechaFinalPodcast = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoTutorial1) {
						fechaFinalPodcast = dataOyente.fechaVistoTutorial1;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinalPodcast = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaPodcastFinalOyente = () => {
				let fechaFinalPodcast = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoPodcast1) {
						fechaFinalPodcast = dataOyente.fechaVistoPodcast1;
						// const fechaArreglo1Podcast = stringToArray(fechaPodcast, ' ');
						// const fechaArreglo2Podcast = stringToArray(fechaArreglo1Podcast[0], '-');
						// fechaFinalPodcast = `${fechaArreglo2Podcast[2]}/${fechaArreglo2Podcast[1]}/${fechaArreglo2Podcast[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaEvaluacionFinalOyente = () => {
				let fechaFinalEvaluacion = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoEvaluacion) {
						fechaFinalEvaluacion = dataOyente.fechaVistoEvaluacion;
						// const fechaArreglo1Evaluacion = stringToArray(fechaEvaluacion, ' ');
						// const fechaArreglo2Evaluacion = stringToArray(fechaArreglo1Evaluacion[0], '-');
						// fechaFinalEvaluacion = `${fechaArreglo2Evaluacion[2]}/${fechaArreglo2Evaluacion[1]}/${fechaArreglo2Evaluacion[0]}`;
					}
				}
				return fechaFinalEvaluacion;
			};
			let fechaInsigniaFinalOyente = () => {
				let fechaFinalInsignia = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoInsignia) {
						fechaFinalInsignia = dataOyente.fechaVistoInsignia;
						// const fechaArreglo1Insignia = stringToArray(fechaInsignia, ' ');
						// const fechaArreglo2Insignia = stringToArray(fechaArreglo1Insignia[0], '-');
						// fechaFinalInsignia = `${fechaArreglo2Insignia[2]}/${fechaArreglo2Insignia[1]}/${fechaArreglo2Insignia[0]}`;
					}
				}
				return fechaFinalInsignia;
			};
			let fechaDescargaInsigniaFinalOyente = () => {
				let fechaFinalDescargaInsignia = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.fechaVistoDescargaInsignia) {
						fechaFinalDescargaInsignia = dataOyente.descargaInsignia;
						// const fechaArreglo1DescargaInsignia = stringToArray(fechaDescargaInsignia, ' ');
						// const fechaArreglo2DescargaInsignia = stringToArray(fechaArreglo1DescargaInsignia[0], '-');
						// fechaFinalDescargaInsignia = `${fechaArreglo2DescargaInsignia[2]}/${fechaArreglo2DescargaInsignia[1]}/${fechaArreglo2DescargaInsignia[0]}`;
					}
				}
				return fechaFinalDescargaInsignia;
			};

			// LIKES
			let likesTutorialOyentes = () => {
				if (likesOyente[valUSuarios]) {
					return likesOyente[valUSuarios].tutorial1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesPodcastOyentes = () => {
				if (likesOyente[valUSuarios]) {
					return likesOyente[valUSuarios].podcast1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesEvaluacionOyentes = () => {
				if (likesOyente[valUSuarios]) {
					return likesOyente[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '-';
				}
			};

			// STATUS
			let statusTutorialOyente = () => {
				let status = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.tutorial1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcastOyente = () => {
				let status = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.podcast1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacionOyente = () => {
				let status = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.evaluacion) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusInsigniaOyente = () => {
				let status = ' ';
				if (dataOyente && user.activo == true) {
					if (dataOyente.insignia) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			const calif = () => {
				let calif = '';
				Object.values(encuestaOyente).map((val) => {
					if (val.user === valUSuarios) {
						calif = val.puntos;
					}
				});
				return calif;
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Tutorial',
				status: statusTutorialOyente(),
				fecha: fechaTutorialFinalOyente(),
				satisfaccion: likesTutorialOyentes(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Podcast',
				status: statusPodcastOyente(),
				fecha: fechaPodcastFinalOyente(),
				satisfaccion: likesPodcastOyentes(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacionOyente(),
				fecha: fechaEvaluacionFinalOyente(),
				satisfaccion: likesEvaluacionOyentes(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Insignia',
				status: statusInsigniaOyente(),
				fecha: fechaInsigniaFinalOyente(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Narrador
		if (user.activo == true) {
			let fechaTutorialFinalNarrador = () => {
				let fechaFinalPodcast = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoTutorial1) {
						fechaFinalPodcast = dataNarrador.fechaVistoTutorial1;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinalPodcast = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaPodcastFinalNarrador = () => {
				let fechaFinalPodcast = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoPodcast1) {
						fechaFinalPodcast = dataNarrador.fechaVistoPodcast1;
						// const fechaArreglo1Podcast = stringToArray(fechaPodcast, ' ');
						// const fechaArreglo2Podcast = stringToArray(fechaArreglo1Podcast[0], '-');
						// fechaFinalPodcast = `${fechaArreglo2Podcast[2]}/${fechaArreglo2Podcast[1]}/${fechaArreglo2Podcast[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaEvaluacionFinalNarrador = () => {
				let fechaFinalEvaluacion = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoEvaluacion) {
						fechaFinalEvaluacion = dataNarrador.fechaVistoEvaluacion;
						// const fechaArreglo1Evaluacion = stringToArray(fechaEvaluacion, ' ');
						// const fechaArreglo2Evaluacion = stringToArray(fechaArreglo1Evaluacion[0], '-');
						// fechaFinalEvaluacion = `${fechaArreglo2Evaluacion[2]}/${fechaArreglo2Evaluacion[1]}/${fechaArreglo2Evaluacion[0]}`;
					}
				}
				return fechaFinalEvaluacion;
			};
			let fechaInsigniaFinalNarrador = () => {
				let fechaFinalInsignia = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoInsignia) {
						fechaFinalInsignia = dataNarrador.fechaVistoInsignia;
						// const fechaArreglo1Insignia = stringToArray(fechaInsignia, ' ');
						// const fechaArreglo2Insignia = stringToArray(fechaArreglo1Insignia[0], '-');
						// fechaFinalInsignia = `${fechaArreglo2Insignia[2]}/${fechaArreglo2Insignia[1]}/${fechaArreglo2Insignia[0]}`;
					}
				}
				return fechaFinalInsignia;
			};
			let fechaDescargaInsigniaFinalNarrador = () => {
				let fechaFinalDescargaInsignia = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.fechaVistoDescargaInsignia) {
						fechaFinalDescargaInsignia = dataNarrador.descargaInsignia;
						// const fechaArreglo1DescargaInsignia = stringToArray(fechaDescargaInsignia, ' ');
						// const fechaArreglo2DescargaInsignia = stringToArray(fechaArreglo1DescargaInsignia[0], '-');
						// fechaFinalDescargaInsignia = `${fechaArreglo2DescargaInsignia[2]}/${fechaArreglo2DescargaInsignia[1]}/${fechaArreglo2DescargaInsignia[0]}`;
					}
				}
				return fechaFinalDescargaInsignia;
			};

			// LIKES
			let likesTutorialNarrador = () => {
				if (likesNarrador[valUSuarios]) {
					return likesNarrador[valUSuarios].tutorial1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesPodcastNarrador = () => {
				if (likesNarrador[valUSuarios]) {
					return likesNarrador[valUSuarios].podcast1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesEvaluacionNarrador = () => {
				if (likesNarrador[valUSuarios]) {
					return likesNarrador[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '-';
				}
			};

			// STATUS
			let statusTutorialNarrador = () => {
				let status = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.tutorial1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcastNarrador = () => {
				let status = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.podcast1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacionNarrador = () => {
				let status = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.evaluacion) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusInsigniaNarrador = () => {
				let status = ' ';
				if (dataNarrador && user.activo == true) {
					if (dataNarrador.insignia) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			const calif = () => {
				let calif = '';
				Object.values(encuestaNarrador).map((val) => {
					if (val.user === valUSuarios) {
						calif = val.puntos;
					}
				});
				return calif;
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Narrador/Activador',
				material: 'Tutorial',
				status: statusTutorialNarrador(),
				fecha: fechaTutorialFinalNarrador(),
				satisfaccion: likesTutorialNarrador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Narrador/Activador',
				material: 'Podcast',
				status: statusPodcastNarrador(),
				fecha: fechaPodcastFinalNarrador(),
				satisfaccion: likesPodcastNarrador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Narrador/Activador',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacionNarrador(),
				fecha: fechaEvaluacionFinalNarrador(),
				satisfaccion: likesEvaluacionNarrador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Narrador/Activador',
				material: 'Insignia',
				status: statusInsigniaNarrador(),
				fecha: fechaInsigniaFinalNarrador(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Transformador
		if (user.activo == true) {
			let fechaTutorialFinalTransformador = () => {
				let fechaFinalPodcast = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoTutorial1) {
						fechaFinalPodcast = dataTransformador.fechaVistoTutorial1;
						// const fechaArreglo1 = stringToArray(fecha, ' ');
						// const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
						// fechaFinalPodcast = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaPodcastFinalTransformador = () => {
				let fechaFinalPodcast = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoPodcast1) {
						fechaFinalPodcast = dataTransformador.fechaVistoPodcast1;
						// const fechaArreglo1Podcast = stringToArray(fechaPodcast, ' ');
						// const fechaArreglo2Podcast = stringToArray(fechaArreglo1Podcast[0], '-');
						// fechaFinalPodcast = `${fechaArreglo2Podcast[2]}/${fechaArreglo2Podcast[1]}/${fechaArreglo2Podcast[0]}`;
					}
				}
				return fechaFinalPodcast;
			};
			let fechaEvaluacionFinalTransformador = () => {
				let fechaFinalEvaluacion = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoEvaluacion) {
						fechaFinalEvaluacion = dataTransformador.fechaVistoEvaluacion;
						// const fechaArreglo1Evaluacion = stringToArray(fechaEvaluacion, ' ');
						// const fechaArreglo2Evaluacion = stringToArray(fechaArreglo1Evaluacion[0], '-');
						// fechaFinalEvaluacion = `${fechaArreglo2Evaluacion[2]}/${fechaArreglo2Evaluacion[1]}/${fechaArreglo2Evaluacion[0]}`;
					}
				}
				return fechaFinalEvaluacion;
			};
			let fechaInsigniaFinalTransformador = () => {
				let fechaFinalInsignia = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoInsignia) {
						fechaFinalInsignia = dataTransformador.fechaVistoInsignia;
						// const fechaArreglo1Insignia = stringToArray(fechaInsignia, ' ');
						// const fechaArreglo2Insignia = stringToArray(fechaArreglo1Insignia[0], '-');
						// fechaFinalInsignia = `${fechaArreglo2Insignia[2]}/${fechaArreglo2Insignia[1]}/${fechaArreglo2Insignia[0]}`;
					}
				}
				return fechaFinalInsignia;
			};
			let fechaDescargaInsigniaFinalTransformador = () => {
				let fechaFinalDescargaInsignia = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.fechaVistoDescargaInsignia) {
						fechaFinalDescargaInsignia = dataTransformador.descargaInsignia;
						// const fechaArreglo1DescargaInsignia = stringToArray(fechaDescargaInsignia, ' ');
						// const fechaArreglo2DescargaInsignia = stringToArray(fechaArreglo1DescargaInsignia[0], '-');
						// fechaFinalDescargaInsignia = `${fechaArreglo2DescargaInsignia[2]}/${fechaArreglo2DescargaInsignia[1]}/${fechaArreglo2DescargaInsignia[0]}`;
					}
				}
				return fechaFinalDescargaInsignia;
			};

			// LIKES
			let likesTutorialTransformador = () => {
				if (likesTransformador[valUSuarios]) {
					return likesTransformador[valUSuarios].tutorial1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesPodcastTransformador = () => {
				if (likesTransformador[valUSuarios]) {
					return likesTransformador[valUSuarios].podcast1 ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let likesEvaluacionTransformador = () => {
				if (likesTransformador[valUSuarios]) {
					return likesTransformador[valUSuarios].evaluacion ? 'Like' : '';
				} else {
					return '-';
				}
			};

			// STATUS
			let statusTutorialTransformador = () => {
				let status = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.tutorial1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusPodcastTransformador = () => {
				let status = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.podcast1) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusEvaluacionTransformador = () => {
				let status = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.evaluacion) {
						status = 'Verdadero';
					}
				}
				return status;
			};
			let statusInsigniaTransformador = () => {
				let status = ' ';
				if (dataTransformador && user.activo == true) {
					if (dataTransformador.insignia) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			const calif = () => {
				let calif = '';
				Object.values(encuestatransformador).map((val) => {
					if (val.user === valUSuarios) {
						calif = val.puntos;
					}
				});
				return calif;
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Transformador',
				material: 'Tutorial',
				status: statusTutorialTransformador(),
				fecha: fechaTutorialFinalTransformador(),
				satisfaccion: likesTutorialTransformador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Transformador',
				material: 'Podcast',
				status: statusPodcastTransformador(),
				fecha: fechaPodcastFinalTransformador(),
				satisfaccion: likesPodcastTransformador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Transformador',
				material: 'Evaluacion',
				calificacion: calif(),
				status: statusEvaluacionTransformador(),
				fecha: fechaEvaluacionFinalTransformador(),
				satisfaccion: likesEvaluacionTransformador(),
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Transformador',
				material: 'Insignia',
				status: statusInsigniaTransformador(),
				fecha: fechaDescargaInsigniaFinalTransformador(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
		}

		// DATA Daring Home
		if (user.activo == true) {
			let fechaMasterClassDaring = () => {
				let fechaFinalMasterClass = ' ';
				if (dataMasterclass && user.activo == true) {
					if (dataMasterclass.fechaDeMasterClass) {
						fechaFinalMasterClass = dataMasterclass.fechaDeMasterClass;
						// const fechaArregloMC = stringToArray(fechaMC, ' ');
						// const fechaArreglo2MC = stringToArray(fechaArregloMC[0], '-');
						// fechaFinalMasterClass = `${fechaArreglo2MC[2]}/${fechaArreglo2MC[1]}/${fechaArreglo2MC[0]}`;
					}
				}
				return fechaFinalMasterClass;
			};
			let fechaKickOffDaring = () => {
				let fechaFinalKickOff = ' ';
				if (dataKickOff && user.activo == true) {
					if (dataKickOff.fechaDeKickOff) {
						fechaFinalKickOff = dataKickOff.fechaDeKickOff;
						// const fechaArregloMC = stringToArray(fechaMC, ' ');
						// const fechaArreglo2MC = stringToArray(fechaArregloMC[0], '-');
						// fechaFinalKickOff = `${fechaArreglo2MC[2]}/${fechaArreglo2MC[1]}/${fechaArreglo2MC[0]}`;
					}
				}
				return fechaFinalKickOff;
			};
			let fechaDescargaPlaybookDaring = () => {
				let fechaFinalDescargaPlaybook = ' ';
				if (dataPlaybook && user.activo == true) {
					if (dataPlaybook.fechaPlaybook) {
						fechaFinalDescargaPlaybook = dataPlaybook.fechaPlaybook;
						// const fechaArreglo1DescargaPlaybook = stringToArray(fechaDescargaPlaybook, ' ');
						// const fechaArreglo2DescargaPlaybook = stringToArray(fechaArreglo1DescargaPlaybook[0], '-');
						// fechaFinalDescargaPlaybook = `${fechaArreglo2DescargaPlaybook[2]}/${fechaArreglo2DescargaPlaybook[1]}/${fechaArreglo2DescargaPlaybook[0]}`;
					}
				}
				return fechaFinalDescargaPlaybook;
			};
			let fechaReconocimientoDaring = () => {
				let fechaFinalReconocimiento = ' ';
				if (dataReconocimiento && user.activo == true) {
					if (dataReconocimiento.fechaDescargaReconocimiento) {
						fechaFinalReconocimiento = dataReconocimiento.fechaDescargaReconocimiento;
						// const fechaArregloMC = stringToArray(fechaMC, ' ');
						// const fechaArreglo2MC = stringToArray(fechaArregloMC[0], '-');
						// fechaFinalReconocimiento = `${fechaArreglo2MC[2]}/${fechaArreglo2MC[1]}/${fechaArreglo2MC[0]}`;
					}
				}
				return fechaFinalReconocimiento;
			};

			// STATUS
			let statusMasterClassDaring = () => {
				let status = ' ';
				if (dataMasterclass && user.activo == true) {
					if (dataMasterclass.masterclass) {
						status = 'Verdadero';
					}
				} else if (dataOyente) {
					if (dataOyente.tutorial1) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			let statusKickOffDaring = () => {
				let status = ' ';
				if (dataKickOff && user.activo == true) {
					if (dataKickOff.kickoff) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			let statusReconocimientoDaring = () => {
				let status = ' ';
				if (dataReconocimiento && user.activo == true) {
					if (dataReconocimiento.descargaReconocimiento) {
						status = 'Verdadero';
					}
				}
				return status;
			};

			// LIKES
			let likeHomeDaring = () => {
				if (likesHomeDaring[valUSuarios]) {
					return likesHomeDaring[valUSuarios].masterClass ? 'Like' : '';
				} else {
					return '-';
				}
			};
			let getPlaybook = () => {
				if (playbook[valUSuarios]) {
					return playbook[valUSuarios].playbook ? 'Verdadero' : '';
				} else {
					return '';
				}
			};

			// Actualiza Array por Materiales
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Daring/Home',
				material: 'MasterClass',
				calificacion: '',
				status: statusMasterClassDaring(),
				fecha: fechaMasterClassDaring(),
				satisfaccion: likeHomeDaring(),
				audiencia: user.audiencia,
			});
			// array.push({
			// 	userID: valUSuarios,
			// 	idGlobal: user.idGlobal,
			// 	nombre: user.nombre,
			// 	pilar: 'Daring/Home',
			// 	material: 'KickOff',
			// 	calificacion: '',
			// 	status: statusKickOffDaring(),
			// 	fecha: fechaKickOffDaring(),
			// 	satisfaccion: '-',
			// 	audiencia: user.audiencia,
			// });
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Daring/Home',
				material: 'Playbook',
				calificacion: '',
				status: getPlaybook(),
				fecha: fechaDescargaPlaybookDaring(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
			array.push({
				userID: valUSuarios,
				idGlobal: user.idGlobal,
				nombre: user.nombre,
				pilar: 'Daring/Home',
				material: 'Reconocimiento',
				calificacion: '',
				status: statusReconocimientoDaring(),
				fecha: fechaReconocimientoDaring(),
				satisfaccion: '-',
				audiencia: user.audiencia,
			});
			if (valUSuarios == '000000') {
				// if (valUSuarios == '8033437') {
				console.log(valUSuarios);
				console.log('dataOyente', dataOyente.tutorial1);
				// console.log('fechaMasterClassDaring', fechaMasterClassDaring());
				// console.log('likeHomeDaring', likeHomeDaring());
				// console.log('statusKickOffDaring', statusKickOffDaring());
				// console.log('fechaKickOffDaring', fechaKickOffDaring());
				// console.log('statusReconocimientoDaring', statusReconocimientoDaring());
				// console.log('fechaReconocimientoDaring', fechaReconocimientoDaring());
			}
		}
		// console.log(array);
	});

	let data = [
		{
			sheet: 'Data',
			columns: [
				{ label: 'UserID', value: 'userID' },
				{ label: 'IDGlobal', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Pilar', value: 'pilar' },
				{ label: 'Material', value: 'material' },
				{ label: 'Status', value: 'status' },
				{ label: 'Fecha', value: 'fecha' },
				{ label: 'Satisfaccion', value: 'satisfaccion' },
				{ label: 'Audiencia', value: 'audiencia' },
			],
			content: array,
		},
	];
	const fecha = cambiarTextos(getDate());
	let settings = {
		fileName: `Reporte-HNK-TPL-CON-CALIF${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}
// Usuarios Activos TPL con CALIF

// Bienestar
export async function reporteRecursosBienestar() {
	let array = [];
	let json = {};
	let arrayFinal = [];
	const resultsUsers = await getCollections('users');
	const results = await getCollectionsComplete('recursos');

	Object.values(results).map((val) => {
		array.push({
			id: val.id,

			masterClass: val.masterClass === true ? 'VERDADERO' : 'FALSO',
			fechaVistoMasterClass: val.fechaVistoMasterClass ? val.fechaVistoMasterClass : '-',

			tutorial: val.tutorial === true ? 'VERDADERO' : 'FALSO',
			fechaVistoTutorial: val.fechaVistoTutorial ? val.fechaVistoTutorial : '-',

			podcast: val.podcast === true ? 'VERDADERO' : 'FALSO',
			fechaVistoPodcast: val.fechaVistoPodcast ? val.fechaVistoPodcast : '-',

			playbook: val.playbook === true ? 'VERDADERO' : 'FALSO',
			fechaVistoPlaybook: val.fechaVistoPlaybook ? val.fechaVistoPlaybook : '-',

			evaluacion: val.masterClass === true ? 'VERDADERO' : 'FALSO',
			fechaVistoEvaluacion: val.fechaVistoEvaluacion ? val.fechaVistoEvaluacion : '-',
		});
	});

	Object.values(resultsUsers).map((valUSuarios) => {
		const data = results[valUSuarios.id];
		if (data) {
			json = {
				...valUSuarios,
				...data,
			};
		} else {
			json = {
				...valUSuarios,
			};
		}

		arrayFinal.push(json);
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Recursos Digitales Bienestar',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Nombre Completo del Line Manager', value: 'nombreLineManager' },
				{ label: 'Audiencia SPIRA CONNECT', value: 'audiencia' },
				{ label: 'Estatus Line Manager', value: 'statusLineManager' },
				{ label: 'Correo', value: 'correo' },
				{ label: 'J1', value: 'j1' },
				{ label: 'J2', value: 'j2' },
				{ label: 'J3', value: 'j3' },
				{ label: 'J4', value: 'j4' },
				{ label: 'J5', value: 'j5' },
				{ label: 'Masterclass', value: 'masterClass' },
				{ label: 'Fecha Masterclass', value: 'fechaVistoMasterClass' },
				{ label: 'Tutorial', value: 'tutorial' },
				{ label: 'Fecha Tutorial', value: 'fechaVistoTutorial' },
				{ label: 'Podcast', value: 'podcast' },
				{ label: 'Fecha Podcast', value: 'fechaVistoPodcast' },
				{ label: 'Playbook', value: 'playbook' },
				{ label: 'Fecha Playbook', value: 'fechaVistoPlaybook' },
				{ label: 'Evaluación', value: 'evaluacion' },
				{ label: 'Fecha Evaluación', value: 'fechaVistoEvaluacion' },
			],
			content: arrayFinal,
		},
	];

	let settings = {
		fileName: `Reporte-Bienestar-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

// Bienestar feedback
export async function reporteRecursosBienestarFeedback() {
	let json = {};
	let arrayFinal = [];
	const resultsUsers = await getCollections('users');
	const results = await getCollectionsComplete('likes/bienestar');
	const nameLike = (valor) => {
		let name = 'Sin reaccion';
		switch (valor) {
			case true:
				name = 'Like';
				break;
			case false:
				name = 'Disike';
				break;

			default:
				break;
		}
		return name;
	};
	Object.values(resultsUsers).map((valUSuarios) => {
		const data = results[valUSuarios.id];
		if (data) {
			// console.log(data);
			json = {
				id: data.id,
				nombre: valUSuarios.nombre,
				audiencia: valUSuarios.audiencia,

				masterClass: nameLike(data.masterClass),
				masterClassLikefechaRegistro: data.masterClassLikefechaRegistro ? data.masterClassLikefechaRegistro : '-',

				tutorial: nameLike(data.tutorial),
				tutorialLikefechaRegistro: data.tutorialLikefechaRegistro ? data.tutorialLikefechaRegistro : '-',

				podcast: nameLike(data.podcast),
				podcastLikefechaRegistro: data.podcastLikefechaRegistro ? data.podcastLikefechaRegistro : '-',

				playbook: nameLike(data.playbook),
				playbookLikefechaRegistro: data.playbookLikefechaRegistro ? data.playbookLikefechaRegistro : '-',
			};
			arrayFinal.push(json);
		}
	});
	// console.log(arrayFinal);
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Recursos Digitales Bienestar',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Audiencia', value: 'audiencia' },
				{ label: 'Masterclass', value: 'masterClass' },
				{ label: 'Fecha Masterclass', value: 'masterClassLikefechaRegistro' },
				{ label: 'Tutorial', value: 'tutorial' },
				{ label: 'Fecha Tutorial', value: 'tutorialLikefechaRegistro' },
				{ label: 'Podcast', value: 'podcast' },
				{ label: 'Fecha Podcast', value: 'podcastLikefechaRegistro' },
				{ label: 'Playbook', value: 'playbook' },
				{ label: 'Fecha Playbook', value: 'playbookLikefechaRegistro' },
			],
			content: arrayFinal,
		},
	];

	let settings = {
		fileName: `Reporte-Bienestar-feedback-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

// Inclusion
export async function reporteRecursosInclusion() {
	let array = [];
	let json = {};
	let arrayFinal = [];
	const resultsUsers = await getCollections('users');
	const results = await getCollectionsComplete('pilarInclusion');

	Object.values(resultsUsers).map((valUSuarios) => {
		const dataInclusuin = results[valUSuarios.id];
		if (dataInclusuin) {
			json = {
				id: valUSuarios.id,
				nombre: valUSuarios.nombre,
				puesto: valUSuarios.puesto,
				nombreLineManager: valUSuarios.nombreLineManager,
				audiencia: valUSuarios.audiencia,
				statusLineManager: valUSuarios.statusLineManager,
				correo: valUSuarios.correo,
				j1: valUSuarios.j1,
				j2: valUSuarios.j2,
				j3: valUSuarios.j3,
				j4: valUSuarios.j4,
				j5: valUSuarios.j5,
				masterClass: dataInclusuin.masterClass,
				fechaVistoMasterClass: dataInclusuin.fechaVistoMasterClass,
				tutorial: dataInclusuin.tutorial,
				fechaVistoTutorial: dataInclusuin.fechaVistoTutorial,
				podcast: dataInclusuin.podcast,
				fechaVistoPodcast: dataInclusuin.fechaVistoPodcast,
				playbook: dataInclusuin.playbook,
				fechaVistoPlaybook: dataInclusuin.fechaVistoPlaybook,
				evaluacion: dataInclusuin.evaluacion,
				fechaVistoEvaluacion: dataInclusuin.fechaVistoEvaluacion,
			};
		} else {
			json = {
				id: valUSuarios.id,
				nombre: valUSuarios.nombre,
				puesto: valUSuarios.puesto,
				nombreLineManager: valUSuarios.nombreLineManager,
				audiencia: valUSuarios.audiencia,
				statusLineManager: valUSuarios.statusLineManager,
				correo: valUSuarios.correo,
				j1: valUSuarios.j1,
				j2: valUSuarios.j2,
				j3: valUSuarios.j3,
				j4: valUSuarios.j4,
				j5: valUSuarios.j5,
			};
		}
		arrayFinal.push(json);
	});
	console.log(arrayFinal);
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Recursos Digitales Bienestar',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Puesto', value: 'puesto' },
				{ label: 'Nombre Completo del Line Manager', value: 'nombreLineManager' },
				{ label: 'Audiencia SPIRA CONNECT', value: 'audiencia' },
				{ label: 'Estatus Line Manager', value: 'statusLineManager' },
				{ label: 'Correo', value: 'correo' },
				{ label: 'J1', value: 'j1' },
				{ label: 'J2', value: 'j2' },
				{ label: 'J3', value: 'j3' },
				{ label: 'J4', value: 'j4' },
				{ label: 'J5', value: 'j5' },
				{ label: 'Masterclass', value: 'masterClass' },
				{ label: 'Fecha Masterclass', value: 'fechaVistoMasterClass' },
				{ label: 'Tutorial', value: 'tutorial' },
				{ label: 'Fecha Tutorial', value: 'fechaVistoTutorial' },
				{ label: 'Podcast', value: 'podcast' },
				{ label: 'Fecha Podcast', value: 'fechaVistoPodcast' },
				{ label: 'Playbook', value: 'playbook' },
				{ label: 'Fecha Playbook', value: 'fechaVistoPlaybook' },
				{ label: 'Evaluación', value: 'evaluacion' },
				{ label: 'Fecha Evaluación', value: 'fechaVistoEvaluacion' },
			],
			content: arrayFinal,
		},
	];

	let settings = {
		fileName: `Reporte-Inclusion-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

// Inclsuion feedback
export async function reporteRecursosInclusionFeedback() {
	let json = {};
	let arrayFinal = [];
	const resultsUsers = await getCollections('users');
	const results = await getCollectionsComplete('likes/inclusion');
	const nameLike = (valor) => {
		let name = 'Sin reaccion';
		switch (valor) {
			case true:
				name = 'Like';
				break;
			case false:
				name = 'Disike';
				break;

			default:
				break;
		}
		return name;
	};
	Object.values(resultsUsers).map((valUSuarios) => {
		const data = results[valUSuarios.id];
		if (data) {
			// console.log(data);
			json = {
				id: data.id,
				nombre: valUSuarios.nombre,
				audiencia: valUSuarios.audiencia,

				masterClass: nameLike(data.masterClass),
				masterClassLikefechaRegistro: data.masterClassfechaRegistro ? data.masterClassfechaRegistro : '-',

				tutorial: nameLike(data.tutorial),
				tutorialLikefechaRegistro: data.tutorialfechaRegistro ? data.tutorialfechaRegistro : '-',

				podcast: nameLike(data.podcast),
				podcastLikefechaRegistro: data.podcastfechaRegistro ? data.podcastfechaRegistro : '-',

				playbook: nameLike(data.playbook),
				playbookLikefechaRegistro: data.playbookfechaRegistro ? data.playbookfechaRegistro : '-',
			};
			arrayFinal.push(json);
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Recursos Digitales Bienestar',
			columns: [
				{ label: 'Número de empleado', value: 'id' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Audiencia', value: 'audiencia' },
				{ label: 'Masterclass', value: 'masterClass' },
				{ label: 'Fecha Masterclass', value: 'masterClassLikefechaRegistro' },
				{ label: 'Tutorial', value: 'tutorial' },
				{ label: 'Fecha Tutorial', value: 'tutorialLikefechaRegistro' },
				{ label: 'Podcast', value: 'podcast' },
				{ label: 'Fecha Podcast', value: 'podcastLikefechaRegistro' },
				{ label: 'Playbook', value: 'playbook' },
				{ label: 'Fecha Playbook', value: 'playbookLikefechaRegistro' },
			],
			content: arrayFinal,
		},
	];

	let settings = {
		fileName: `Reporte-inclusion-feedback-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

/* Helper function */
export function download_file(fileURL, fileName) {
	// for non-IE
	if (!window.ActiveXObject) {
		var save = document.createElement('a');
		save.href = fileURL;
		save.target = '_blank';
		var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
		save.download = fileName || filename;
		if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
			document.location = save.href;
			// window event not working here
		} else {
			var evt = new MouseEvent('click', {
				view: window,
				bubbles: true,
				cancelable: false,
			});
			save.dispatchEvent(evt);
			(window.URL || window.webkitURL).revokeObjectURL(save.href);
		}
	}

	// for IE < 11
	else if (!!window.ActiveXObject && document.execCommand) {
		var _window = window.open(fileURL, '_blank');
		_window.document.close();
		_window.document.execCommand('SaveAs', true, fileName || fileURL);
		_window.close();
	}
}
