import { getCollections, getCollectionsComplete, cambiarTextos, getDate, stringToArray } from './functions';
let xlsx = require('json-as-xlsx');

export async function reporteMasterclass() {
	let array = [];
	const resultsUsers = await getCollectionsComplete('users');
	const results = await getCollectionsComplete('daring/likes/home');

	Object.keys(resultsUsers).map((key) => {
		const dataUser = resultsUsers[key];
		const dataLike = results[key];
		if (dataLike) {
			array.push({
				id: dataUser.id,
				idGlobal: dataUser.idGlobal,
				nombre: dataUser.nombre,
				material: 'Master Class',
				satisfaccion: dataLike.masterClass === true || dataLike.masterClass === false ? (dataLike.masterClass === true ? 'Like' : 'Dislike') : '-',
				fechaDeRegistro: dataLike.masterClassfechaRegistro,
			});
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción Master Class',
			columns: [
				{ label: 'UserID', value: 'id' },
				{ label: 'IDGlobal', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Material', value: 'material' },
				{ label: 'Satisfacción', value: 'satisfaccion' },
				{ label: 'Material', value: 'fechaDeRegistro' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Reporte-satisfaccion-masterclass-daring-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings);
}

// Pilar Oyente/Cultivador Likes
export async function reporteOyenteCultivadorLikes() {
	let array = [];
	const resultsUsers = await getCollectionsComplete('users');
	const results = await getCollectionsComplete('daring/likes/oyente');
	const pilarOyente = await getCollectionsComplete('daring/pilarOyente');

	Object.keys(resultsUsers).map((key) => {
		const dataUser = resultsUsers[key];
		const dataLike = results[key];
		const dataPilar = pilarOyente[key];
		let fechaFinalTutorial = ' ';
		let fechaFinalPodcast = ' ';
		if (dataPilar && dataLike) {
			const fechaTutorial = dataPilar.fechaVistoTutorial1;
			const fechaArreglo1 = stringToArray(fechaTutorial, ' ');
			const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
			fechaFinalTutorial = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;

			array.push({
				id: dataUser.id,
				idGlobal: dataUser.idGlobal,
				nombre: dataUser.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Tutorial',
				fecha: fechaFinalTutorial,
				satisfaccion: dataLike.tutorial1 === true || dataLike.tutorial1 === false ? (dataLike.tutorial1 === true ? 'Like' : 'Dislike') : '-',
				audiencia: dataUser.audiencia,
			});

			const fechaPodcast = dataPilar.fechaVistoPodcast1;
			const fechaArreglo1Podcast = stringToArray(fechaPodcast, ' ');
			const fechaArreglo2Podcast = stringToArray(fechaArreglo1Podcast[0], '-');
			fechaFinalPodcast = `${fechaArreglo2Podcast[2]}/${fechaArreglo2Podcast[1]}/${fechaArreglo2Podcast[0]}`;

			array.push({
				id: dataUser.id,
				idGlobal: dataUser.idGlobal,
				nombre: dataUser.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Podcast',
				fecha: fechaFinalPodcast,
				satisfaccion: dataLike.podcast1 === true || dataLike.podcast1 === false ? (dataLike.podcast1 === true ? 'Like' : 'Dislike') : '-',
				audiencia: dataUser.audiencia,
			});
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción Oyente Cultivador',
			columns: [
				{ label: 'UserID', value: 'id' },
				{ label: 'IDGlobal', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Pilar', value: 'pilar' },
				{ label: 'Material', value: 'material' },
				{ label: 'Fecha', value: 'fecha' },
				{ label: 'Satisfacción', value: 'satisfaccion' },
				{ label: 'Audiencia', value: 'audiencia' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Reporte-satisfaccion-oyente-cultivador-daring-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings); 
}

// Pilar Oyente/Cultivador 
export async function reporteOyenteCultivador() {
	let array = [];
	const resultsUsers = await getCollectionsComplete('users');
	const results = await getCollectionsComplete('daring/pilarOyente');

	Object.keys(resultsUsers).map((key) => {
		const dataUser = resultsUsers[key];
		const dataLike = results[key];
		let fechaFinalTutorial = ' ';
		let fechaFinalPodcast = ' ';
		if (dataLike) {
			const fechaTutorial = dataLike.tutorial1fechaRegistro;
			const fechaArreglo1 = stringToArray(fechaTutorial, ' ');
			const fechaArreglo2 = stringToArray(fechaArreglo1[0], '-');
			fechaFinalTutorial = `${fechaArreglo2[2]}/${fechaArreglo2[1]}/${fechaArreglo2[0]}`;

			array.push({
				id: dataUser.id,
				idGlobal: dataUser.idGlobal,
				nombre: dataUser.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Tutorial',
				fecha: fechaFinalTutorial,
				audiencia: dataUser.audiencia,
			});

			const fechaPodcast = dataLike.podcast1fechaRegistro;
			const fechaArreglo1Podcast = stringToArray(fechaPodcast, ' ');
			const fechaArreglo2Podcast = stringToArray(fechaArreglo1Podcast[0], '-');
			fechaFinalPodcast = `${fechaArreglo2Podcast[2]}/${fechaArreglo2Podcast[1]}/${fechaArreglo2Podcast[0]}`;

			array.push({
				id: dataUser.id,
				idGlobal: dataUser.idGlobal,
				nombre: dataUser.nombre,
				pilar: 'Oyente/Cultivador',
				material: 'Podcast',
				fecha: fechaFinalPodcast,
				satisfaccion: dataLike.podcast1 === true || dataLike.podcast1 === false ? (dataLike.podcast1 === true ? 'Like' : 'Dislike') : '-',
				audiencia: dataUser.audiencia,
			});
		}
	});
	const fecha = cambiarTextos(getDate());
	let data = [
		{
			sheet: 'Satisfacción Oyente Cultivador',
			columns: [
				{ label: 'UserID', value: 'id' },
				{ label: 'IDGlobal', value: 'idGlobal' },
				{ label: 'Nombre', value: 'nombre' },
				{ label: 'Pilar', value: 'pilar' },
				{ label: 'Material', value: 'material' },
				{ label: 'Fecha', value: 'fecha' },
				{ label: 'Satisfacción', value: 'satisfaccion' },
				{ label: 'Audiencia', value: 'audiencia' },
			],
			content: array,
		},
	];

	let settings = {
		fileName: `Reporte-satisfaccion-oyente-cultivador-daring-${fecha}`,
		extraLength: 3,
		writeOptions: {},
	};

	xlsx(data, settings); 
}
