import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Container, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@material-ui/core";
import uniqid from "uniqid";
import { setData, getDate, top } from "../../../../../resources/js/functions";
import Aprobado from '../../Aprobado'
import Reprobado from '../../Reprobrado'

export default function Ejecutivos(props) {
    const { activeView } = props;
    const params = useParams();
    const [reprobado, setReprobado] = useState(false);
    const [aprobado, setAprobado] = useState(false);
    const [preguntas, setPreguntas] = useState(['', '', '', '', '', '', '', '', '', '']);
    const [respuestas, setRespuestas] = useState(['b', 'c', 'c', 'b', 'b', 'a', 'a', 'c', 'a', 'b']);
    const [seccion1, setSeccion1] = useState([0, 0, 0, 0]);
    const [seccion2, setSeccion2] = useState([0, 0, 0]);
    const [seccion3, setSeccion3] = useState([0, 0, 0]);
    const [calif, setCalif] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        top();
    }, [])

    const registrar = async () => {
        if (Object.values(preguntas).length < 9) {
            Swal.fire({
                title: `¡Error!`,
                text: `Existen preguntas sin responder`,
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#f38f04",
                cancelButtonColor: "#e10613",
                icon: "error",
            });
        } else {
            let id = uniqid();
            let puntos = 0;
            const json = {
                ...preguntas,
                id,
                fechaDeRegistro: getDate(),
                user: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')
            };

            Object.values(preguntas).map((val, index) => {
                if (preguntas[index] === respuestas[index]) {
                    puntos = puntos + 1;
                }
            });
            setCalif(puntos);
            if (puntos > 7) {
                setData("encuestaCertificado", id, json).then(() => {
                    setAprobado(true);
                });
            } else {
                setReprobado(true);
            }
        }
    };

    const set = (val, id) => {
        switch (id) {
            case 0:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[0] = 1;
                }
                break;
            case 1:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[1] = 1;
                }
                break;
            case 2:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[2] = 1;
                }
                break;
            case 3:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[0] = 1;
                }
                break;
            case 4:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[1] = 1;
                }
                break;
            case 5:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[0] = 1;
                }
                break;
            case 6:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[1] = 1;
                }
                break;
            case 7:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[3] = 1;
                }
                break;
            case 8:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[2] = 1;
                }
                break;
            case 9:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[2] = 1;
                }
                break;

            default:
                break;
        }
        setSeccion1([...seccion1]);
        setSeccion2([...seccion2]);
        setSeccion3([...seccion3]);
    }

    return <>
        {(!reprobado && !aprobado) && <>
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <div className="cursor" onClick={() => navigate('/pilares')} style={{ color: '#2D6DB4' }}>{'< Regresar'}</div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="titulos">Estás a un paso de conseguir tu constancia del pilar Bienestar. <br />¡Te deseamos éxito!<br /><br /></div>
                        <div className="titulos">Instrucciones: Lee con atención cada enunciado y selecciona la respuesta correcta.</div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Qué es el eustrés? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 0)} value="La acumulación prolongada de altos niveles de estrés." control={<Radio />} label="La acumulación prolongada de altos niveles de estrés." />
                                <FormControlLabel onChange={() => set('b', 0)} value="Una respuesta positiva ante una situación estresante." control={<Radio />} label="Una respuesta positiva ante una situación estresante." />
                                <FormControlLabel onChange={() => set('c', 0)} value="Una respuesta negativa ante una situación estresante." control={<Radio />} label="Una respuesta negativa ante una situación estresante." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Qué es el distrés? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 1)} value="Una respuesta positiva ante una situación estresante." control={<Radio />} label="Una respuesta positiva ante una situación estresante." />
                                <FormControlLabel onChange={() => set('b', 1)} value="La acumulación prolongada de altos niveles de estrés." control={<Radio />} label="La acumulación prolongada de altos niveles de estrés." />
                                <FormControlLabel onChange={() => set('c', 1)} value="Una respuesta negativa ante una situación estresante." control={<Radio />} label="Una respuesta negativa ante una situación estresante." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuáles son las cuatro emociones básicas? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 2)} value="Pánico, Molestia, Desanimo, Placer." control={<Radio />} label="Pánico, Molestia, Desanimo, Placer." />
                                <FormControlLabel onChange={() => set('b', 2)} value="Cobardía, Ira, Depresión, Satisfacción." control={<Radio />} label="Cobardía, Ira, Depresión, Satisfacción." />
                                <FormControlLabel onChange={() => set('c', 2)} value="Miedo, Enojo, Tristeza y Alegría." control={<Radio />} label="Miedo, Enojo, Tristeza y Alegría." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Por qué es importante hacer pausas conscientes?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 3)} value="Para expresar gratitud y reconocimiento cuando el equipo hace un buen trabajo." control={<Radio />} label="Para expresar gratitud y reconocimiento cuando el equipo hace un buen trabajo." />
                                <FormControlLabel onChange={() => set('b', 3)} value="Para generar mayores niveles de energía y bienestar cuando tu capacidad de enfoque está disminuida." control={<Radio />} label="Para generar mayores niveles de energía y bienestar cuando tu capacidad de enfoque está disminuida." />
                                <FormControlLabel onChange={() => set('c', 3)} value="Para hacerme cargo de lo que siento cuando tengo un mal día." control={<Radio />} label="Para hacerme cargo de lo que siento cuando tengo un mal día." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuál es un beneficio de que los miembros de tu equipo tengan bien definidas sus responsabilidades? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 4)} value="Les permite expresar gratitud y reconocimiento oportunamente." control={<Radio />} label="Les permite expresar gratitud y reconocimiento oportunamente." />
                                <FormControlLabel onChange={() => set('b', 4)} value="Permanecen motivados y confiados en que pueden realizar sus tareas." control={<Radio />} label="Permanecen motivados y confiados en que pueden realizar sus tareas." />
                                <FormControlLabel onChange={() => set('c', 4)} value="Les permite mantener mayores niveles de distrés." control={<Radio />} label="Les permite mantener mayores niveles de distrés." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">Según la OMS ¿Qué tipo de estado de ánimo es el “bienestar emocional”?​</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 5)} value="Uno en el que la persona se da cuenta de sus propias aptitudes, puede afrontar las presiones normales de la vida y trabajar productivamente." control={<Radio />} label="Uno en el que la persona se da cuenta de sus propias aptitudes, puede afrontar las presiones normales de la vida y trabajar productivamente." />
                                <FormControlLabel onChange={() => set('b', 5)} value="Uno en el que la persona reconoce sus emociones y las fortalece para trabajar más y mejor." control={<Radio />} label="Uno en el que la persona reconoce sus emociones y las fortalece para trabajar más y mejor." />
                                <FormControlLabel onChange={() => set('c', 5)} value="Uno en el que la persona puede trabajar durante largas jornadas sin tener problemas emocionales." control={<Radio />} label="Uno en el que la persona puede trabajar durante largas jornadas sin tener problemas emocionales." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">La fórmula para generar Engagement es: Vigor + Dedicación + Absorción </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 6)} value="Verdadero " control={<Radio />} label="Verdadero " />
                                <FormControlLabel onChange={() => set('b', 6)} value="Falso " control={<Radio />} label="Falso " />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuál es la forma en la que nuestro cuerpo nos manda señales de alguna necesidad que, atendida en el momento correcto, nos llevará a resultados funcionales? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 7)} value="Las acciones" control={<Radio />} label="Las acciones" />
                                <FormControlLabel onChange={() => set('b', 7)} value="Los sentimientos " control={<Radio />} label="Los sentimientos " />
                                <FormControlLabel onChange={() => set('c', 7)} value="Las emociones" control={<Radio />} label="Las emociones" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Qué se recomienda hacer cuando tienes la sensación de que no fluyes con una tarea? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 8)} value="Procura no forzarla y busca un espacio más productivo para realizarla después. " control={<Radio />} label="Procura no forzarla y busca un espacio más productivo para realizarla después. " />
                                <FormControlLabel onChange={() => set('b', 8)} value="Respira y haz lo posible por continuar hasta terminarla. " control={<Radio />} label="Respira y haz lo posible por continuar hasta terminarla. " />
                                <FormControlLabel onChange={() => set('c', 8)} value="Hay que decir que NO." control={<Radio />} label="Hay que decir que NO." />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuál es uno de los síntomas del burnout? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 9)} value="Aumentar la productividad. " control={<Radio />} label="Aumentar la productividad. " />
                                <FormControlLabel onChange={() => set('b', 9)} value="Irritabilidad y/o negatividad. " control={<Radio />} label="Irritabilidad y/o negatividad. " />
                                <FormControlLabel onChange={() => set('c', 9)} value="Generar compromiso." control={<Radio />} label="Generar compromiso." />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Button className="btn blanco" onClick={registrar}>
                            Enviar respuestas
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>}
        {reprobado && <Reprobado seccion1={seccion1} seccion2={seccion2} seccion3={seccion3} perfil={params.perfil} calif={calif} />}
        {aprobado && <Aprobado seccion1={seccion1} seccion2={seccion2} seccion3={seccion3} perfil={params.perfil} activeView={activeView} calif={calif} />}
    </>
}