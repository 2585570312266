import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import jaimeFayad from '../../../../../../resources/images/daring/pilares/jaime-fayad.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30 mt30">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      {params.id === 'ejecutivos' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={jaimeFayad} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Ale Jaime Fayad</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Ale es Líder de la práctica de Transformación en México, con más de 15 años de experiencia profesional de los cuales, 10 de estos han sido en el área de consultoría en temas relacionados a M&A y Transformaciones organizacionales en las industrias de salud, telecom, agroindustria, banca, educación, consumo, retail, gobierno, entre otras.</li>
                          <li>Antes de entrar a Mercer trabajó en PwC como líder de la práctica de M&A de RRHH para Latam. Trabajó en Grupo Vitro como profesionista en desarrollo de RRHH, en la implementación de SAP (Gestión del cambio) desarrollo organizacional, capacitación y desarrollo, productividad, nómina y apoyó a la organización en su re-estructura.</li>
                          <li>Ale es Ingeniero Industrial por la Universidad Iberoamericana y cuenta con un MBA internacional con la Escuela de Alta Dirección y Administración (EADA) en Barcelona, España y tiene certificaciones en psicoterapia holística y meditación, así como coaching, eneagrama y liderazgo creativo con la universidad del estado de Nueva York.</li>
                          <li>Es autor del libro “Mindfulness en las organizaciones”, escribe periódicamente para la revista Forbes en diversos temas incluyendo ESG, ha sido ponente del tema en foros como ARIOAC y forma parte en Mercer del equipo encargado del despliegue del Good Work Framework (modelo para atender la “S” de ESG apuntalado en el foro económico mundial).</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}
      {params.id === 'jefaturas-y-coordinaciones' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={jaimeFayad} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Ale Jaime Fayad</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Ale es Líder de la práctica de Transformación en México, con más de 15 años de experiencia profesional de los cuales, 10 de estos han sido en el área de consultoría en temas relacionados a M&A y Transformaciones organizacionales en las industrias de salud, telecom, agroindustria, banca, educación, consumo, retail, gobierno, entre otras.</li>
                          <li>Antes de entrar a Mercer trabajó en PwC como líder de la práctica de M&A de RRHH para Latam. Trabajó en Grupo Vitro como profesionista en desarrollo de RRHH, en la implementación de SAP (Gestión del cambio) desarrollo organizacional, capacitación y desarrollo, productividad, nómina y apoyó a la organización en su re-estructura.</li>
                          <li>Ale es Ingeniero Industrial por la Universidad Iberoamericana y cuenta con un MBA internacional con la Escuela de Alta Dirección y Administración (EADA) en Barcelona, España y tiene certificaciones en psicoterapia holística y meditación, así como coaching, eneagrama y liderazgo creativo con la universidad del estado de Nueva York.</li>
                          <li>Es autor del libro “Mindfulness en las organizaciones”, escribe periódicamente para la revista Forbes en diversos temas incluyendo ESG, ha sido ponente del tema en foros como ARIOAC y forma parte en Mercer del equipo encargado del despliegue del Good Work Framework (modelo para atender la “S” de ESG apuntalado en el foro económico mundial).</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}

    </>
  );
}
