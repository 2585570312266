const texto = [
	{
		retroUno: {
			bien: 'Reconoces las prácticas inclusivas que te permiten proporcionar igualdad de oportunidades, ser transparente y lograr los entregables en equipo.',
			mal: 'Refuerza las prácticas inclusivas que te ayudarán a proporcionar igualdad de oportunidades, ser transparente y lograr los entregables en equipo.',
		},
		retroDos: {
			bien: 'Eres consciente de las prácticas que te ayudan a fomentar un ambiente donde la creatividad y la participación de todo tu equipo será clave para lograr resultados diferentes.',
			mal: 'Necesitas ser consciente de las prácticas inclusivas que te ayudan a fomentar un ambiente donde la creatividad y la participación de todo tu equipo será clave para lograr resultados diferentes.',
		},
		retroTres: {
			bien: 'Eres sensible a las prácticas que te permiten construir una cultura de pertenencia segura e inclusiva para tu equipo.',
			mal: 'Dedica tiempo a sensibilizarte sobre las prácticas que te permitirán construir una cultura de pertenencia segura e inclusiva para tu equipo.',
		},
		retroCuatro: {
			bien: 'Conoces las prácticas que promueven el diálogo abierto y directo, así como el compromiso con el aprendizaje y el desarrollo de cada persona de tu equipo',
			mal: 'Refuerza las prácticas que te ayudarán tanto a promover el diálogo abierto y directo, así como el compromiso con el aprendizaje y el desarrollo de cada persona de tu equipo.',
		},
	},
];
export default texto;
