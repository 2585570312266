import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Container, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@material-ui/core";
import uniqid from "uniqid";
import { setData, getDate, top } from "../../../../../../../resources/js/functions";
import Aprobado from '../../Aprobado'
import Reprobado from '../../Reprobrado'
import questionario from '../questionarioEjecutivos'; 

export default function Ejecutivos(props) {
    const { activeView } = props;
    const params = useParams();
    const [reprobado, setReprobado] = useState(false);
    const [aprobado, setAprobado] = useState(false);
    const [preguntas, setPreguntas] = useState(['', '', '', '', '', '', '', '', '', '']);
    const [respuestas, setRespuestas] = useState(['a', 'b', 'b', 'b', 'a', 'a', 'c', 'b', 'a', 'a']);
    const [calif, setCalif] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        top();
    }, [])

    const registrar = async () => {
        if (Object.values(preguntas).length < 9) {
            Swal.fire({
                title: `¡Error!`,
                text: `Existen preguntas sin responder`,
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#f38f04",
                cancelButtonColor: "#e10613",
                icon: "error",
            });
        } else {
            let id = uniqid();
            let puntos = 0;
            
            Object.values(preguntas).map((val, index) => {
                if (preguntas[index] === respuestas[index]) {
                    puntos = puntos + 1;
                }
            });
            const json = {
                ...preguntas,
                id,
                fechaDeRegistro: getDate(),
                puntos,
                user: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')
            };
            setCalif(puntos);
            if (puntos > 7) {
                setData("daring/encuesta/narrador", id, json).then(() => {
                    setAprobado(true);
                });
            } else {
                setReprobado(true);
            }
        }
    };

    const set = (val, id) => {
        switch (id) {
            case 0:
                preguntas[id] = val
                break;
            case 1:
                preguntas[id] = val
                break;
            case 2:
                preguntas[id] = val
                break;
            case 3:
                preguntas[id] = val
                break;
            case 4:
                preguntas[id] = val
                break;
            case 5:
                preguntas[id] = val
                break;
            case 6:
                preguntas[id] = val
                break;
            case 7:
                preguntas[id] = val
                break;
            case 8:
                preguntas[id] = val
                break;
            case 9:
                preguntas[id] = val
                break;

            default:
                break;
        }
    }

    return <>
        {(!reprobado && !aprobado) && <>
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <div className="cursor" onClick={() => navigate('/daring/home')} style={{ color: '#2D6DB4' }}>{'< Regresar'}</div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="titulos">Instrucciones: Lee con atención cada pregunta o afirmación y selecciona la respuesta correcta.</div>
                        <div className="titulos">Desafía tus competencias y conviértete en un líder Narrador-Activador</div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta1.pregunta} </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 0)} value={questionario.pregunta1.opcionA} control={<Radio />} label={questionario.pregunta1.opcionA} />
                                <FormControlLabel onChange={() => set('b', 0)} value={questionario.pregunta1.opcionB} control={<Radio />} label={questionario.pregunta1.opcionB} />
                                <FormControlLabel onChange={() => set('c', 0)} value={questionario.pregunta1.opcionC} control={<Radio />} label={questionario.pregunta1.opcionC} />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta2.pregunta} </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 1)} value={questionario.pregunta2.opcionA}  control={<Radio />} label={questionario.pregunta2.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 1)} value={questionario.pregunta2.opcionB}  control={<Radio />} label={questionario.pregunta2.opcionB}  />
                                <FormControlLabel onChange={() => set('c', 1)} value={questionario.pregunta2.opcionC} control={<Radio />} label={questionario.pregunta2.opcionC} />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta3.pregunta} </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 2)} value={questionario.pregunta3.opcionA}  control={<Radio />} label={questionario.pregunta3.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 2)} value={questionario.pregunta3.opcionB}  control={<Radio />} label={questionario.pregunta3.opcionB}  />
                                <FormControlLabel onChange={() => set('c', 2)} value={questionario.pregunta3.opcionC}  control={<Radio />} label={questionario.pregunta3.opcionC}  />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta4.pregunta} </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 3)} value={questionario.pregunta4.opcionA}  control={<Radio />} label={questionario.pregunta4.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 3)} value={questionario.pregunta4.opcionB}  control={<Radio />} label={questionario.pregunta4.opcionB}  />
                                <FormControlLabel onChange={() => set('c', 3)} value={questionario.pregunta4.opcionC}  control={<Radio />} label={questionario.pregunta4.opcionC}  />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta5.pregunta} </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 4)} value={questionario.pregunta5.opcionA}  control={<Radio />} label={questionario.pregunta5.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 4)} value={questionario.pregunta5.opcionB}  control={<Radio />} label={questionario.pregunta5.opcionB}  />
                                <FormControlLabel onChange={() => set('c', 4)} value={questionario.pregunta5.opcionC}  control={<Radio />} label={questionario.pregunta5.opcionC}  />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta6.pregunta} ​</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 5)} value={questionario.pregunta6.opcionA}  control={<Radio />} label={questionario.pregunta6.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 5)} value={questionario.pregunta6.opcionB}  control={<Radio />} label={questionario.pregunta6.opcionB}  />
                                <FormControlLabel onChange={() => set('c', 5)} value={questionario.pregunta6.opcionC}  control={<Radio />} label={questionario.pregunta6.opcionC}  />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta7.pregunta} </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 6)} value={questionario.pregunta7.opcionA}  control={<Radio />} label={questionario.pregunta7.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 6)} value={questionario.pregunta7.opcionB}  control={<Radio />} label={questionario.pregunta7.opcionB}  />
                                <FormControlLabel onChange={() => set('c', 6)} value={questionario.pregunta7.opcionC}  control={<Radio />} label={questionario.pregunta7.opcionC}  />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta8.pregunta} </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 7)} value={questionario.pregunta8.opcionA}  control={<Radio />} label={questionario.pregunta8.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 7)} value={questionario.pregunta8.opcionB}  control={<Radio />} label={questionario.pregunta8.opcionB}  />
                                <FormControlLabel onChange={() => set('c', 7)} value={questionario.pregunta8.opcionC}  control={<Radio />} label={questionario.pregunta8.opcionC}  />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta9.pregunta} </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 8)} value={questionario.pregunta9.opcionA}  control={<Radio />} label={questionario.pregunta9.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 8)} value={questionario.pregunta9.opcionB} control={<Radio />} label={questionario.pregunta9.opcionB} />
                                <FormControlLabel onChange={() => set('c', 8)} value={questionario.pregunta9.opcionC} control={<Radio />} label={questionario.pregunta9.opcionC} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">{questionario.pregunta10.pregunta}  </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 9)} value={questionario.pregunta10.opcionA}  control={<Radio />} label={questionario.pregunta10.opcionA}  />
                                <FormControlLabel onChange={() => set('b', 9)} value={questionario.pregunta10.opcionB} control={<Radio />} label={questionario.pregunta10.opcionB} />
                                <FormControlLabel onChange={() => set('c', 9)} value={questionario.pregunta10.opcionC}  control={<Radio />} label={questionario.pregunta10.opcionC}  />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Button className="btn blanco" onClick={registrar}>
                            Enviar respuestas
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>}
        {reprobado && <Reprobado perfil={params.perfil} calif={calif} />}
        {aprobado && <Aprobado perfil={params.perfil} activeView={activeView} calif={calif} />}
    </>
}