import { getDate, updateData } from "../../resources/js/functions";

export default function ValidateUser(props) {
  const { children } = props;
  const data = localStorage.getItem("0hNZeY/gjEeJihP9bIPGHw==");
  let content = "";



  if (data && data !== '') {
    const b = getDate();

    const json = {
      ultimaConexion: b
    }
    updateData('users', data, json)
    // console.log(b);
    content = <div>{children}</div>;
  } else {
    window.location.replace("/");
  }
  return content;
}
