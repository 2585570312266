import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCollectionsComplete, top, updateAll, updateData } from '../../resources/js/functions';
import { Box, Grid, Container, Button } from '@material-ui/core';
import ValidateUser from '../../components/ValidateUser';
import Modal from '../../components/Modal';
import Header from '../../components/HeaderSeleccionarContenido';
import Footer from '../../components/FooterSeleccionarContenido';
import ScrollToTopButton from '../../components/ScrollToTopButton';
import caring from '../../resources/images/seleccionarFase/caring.svg';
import daring from '../../resources/images/seleccionarFase/daring.svg';
import balanzaDer from '../../resources/images/seleccionarFase/balanza-der.png';
import balanza from '../../resources/images/seleccionarFase/balanza.svg';
import bannerFooter from '../../resources/images/seleccionarFase/Frame6.png';
import balanzaIzq from '../../resources/images/seleccionarFase/balanza-izq.png';
import corazon from '../../resources/images/seleccionarFase/corazon.svg';
import btnDescarga from '../../resources/images/seleccionarFase/btnDescarga.png';
import video from '../../resources/videos/videoTutorial.mp4';
import PDFBroshure from '../../resources/descargables/Brochure_HNK_V3.pdf';
import './styles.scss';

export default function SeleccionarFase() {
	const [cualBalanza, setCualBalanza] = useState('normal');
	const [caringPorcentaje, setCaringPorcentaje] = useState(0);
	const [daringPorcentaje, setDaringPorcentaje] = useState(0);
	const [modal, setModal] = useState(false);
	const userID = localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==');
	const navigate = useNavigate();

	useEffect(() => {
		top();
		getPorcentajeCaring();
		getPorcentajeDaring();
		getDataVideoComoNavegar();
		// validar();
	}, []);

	const getPorcentajeCaring = async () => {
		let sumBienestar = 0;
		let sumInclusion = 0;
		let sumGestion = 0;
		let sumComportamiento = 0;
		const bienestar = await getCollectionsComplete(`recursos/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
		const inclusion = await getCollectionsComplete(`pilarInclusion/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
		const gestion = await getCollectionsComplete(`pilarGestion/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
		const comportamiento = await getCollectionsComplete(`pilarComportamiento/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
		// bienestar
		if (bienestar.masterClass === true) {
			sumBienestar++;
		}
		if (bienestar.playbook === true) {
			sumBienestar++;
		}
		if (bienestar.podcast === true) {
			sumBienestar++;
		}
		if (bienestar.tutorial === true) {
			sumBienestar++;
		}
		if (bienestar.evaluacion === true) {
			sumBienestar++;
		}
		// inclusion
		if (inclusion.masterClass === true) {
			sumInclusion++;
		}
		if (inclusion.playbook === true) {
			sumInclusion++;
		}
		if (inclusion.podcast === true) {
			sumInclusion++;
		}
		if (inclusion.tutorial === true) {
			sumInclusion++;
		}
		if (inclusion.evaluacion === true) {
			sumInclusion++;
		}
		// gestion
		if (gestion.masterClass === true) {
			sumGestion++;
		}
		if (gestion.playbook === true) {
			sumGestion++;
		}
		if (gestion.podcast === true) {
			sumGestion++;
		}
		if (gestion.tutorial === true) {
			sumGestion++;
		}
		if (gestion.evaluacion === true) {
			sumGestion++;
		}
		// comportamiento
		if (comportamiento.masterClass === true) {
			sumComportamiento++;
		}
		if (comportamiento.playbook === true) {
			sumComportamiento++;
		}
		if (comportamiento.podcast === true) {
			sumComportamiento++;
		}
		if (comportamiento.tutorial === true) {
			sumComportamiento++;
		}
		if (comportamiento.evaluacion === true) {
			sumComportamiento++;
		}
		const total = sumBienestar + sumInclusion + sumGestion + sumComportamiento;
		const porcentaje = (total * 100) / 15;
		setCaringPorcentaje(porcentaje.toFixed(0));
	};

	const getPorcentajeDaring = async () => {
		let sumOyente = 0;
		let sumNarrador = 0;
		let sumTransformador = 0;
		const oyente = await getCollectionsComplete(`daring/pilarOyente/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
		const narrador = await getCollectionsComplete(`daring/pilarNarrador/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
		const transformador = await getCollectionsComplete(`daring/pilarTransformador/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
		// bienestar
		if (oyente.tutorial1 === true) {
			sumOyente++;
		}
		if (oyente.podcast1 === true) {
			sumOyente++;
		}
		if (oyente.evaluacion === true) {
			sumOyente++;
		}

		// inclusion
		if (narrador.tutorial1 === true) {
			sumNarrador++;
		}
		if (narrador.podcast1 === true) {
			sumNarrador++;
		}
		if (narrador.evaluacion === true) {
			sumNarrador++;
		}

		// gestion
		if (transformador.tutorial1 === true) {
			sumTransformador++;
		}
		if (transformador.podcast1 === true) {
			sumTransformador++;
		}
		if (transformador.evaluacion === true) {
			sumTransformador++;
		}

		const total = sumOyente + sumNarrador + sumTransformador;
		const porcentaje = (total * 100) / 9;
		setDaringPorcentaje(porcentaje.toFixed(0));
	};

	const cambiarBalanza = (cual) => {
		setCualBalanza(cual);
	};

	const getDataVideoComoNavegar = async () => {
		const data = await getCollectionsComplete(`users/${userID}/videoComoNavegar`);

		if (data !== true || data === undefined || data === null || data === '') {
			setModal(true);
			updateData('users', userID, { videoComoNavegar: true });
		}
	};

	// const validar = async () => {
	//     const masterclass = await getCollectionsComplete(`daring/masterclass/`);
	//     const reconocimiento = await getCollectionsComplete(`daring/reconocimiento/`);
	//     let json = {};
	//     Object.keys(reconocimiento).map(key => {
	//         const datamasterclass = masterclass[key];
	//         const datareconocimiento = reconocimiento[key];
	//         if (datareconocimiento) {
	//             if (!datamasterclass) {
	//                 json[key] = {
	//                     masterclass: true,
	//                     fechaDeMasterClass: datareconocimiento.fechaDescargaReconocimiento
	//                 }
	//             }
	//         }
	//     })
	//     updateAll(`daring/masterclass`, json)
	// }

	return (
		<ValidateUser>
			<ScrollToTopButton />
			<Header />
			<div id='seleccionarFase'>
				<Container maxWidth='lg'>
					<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item xs={12} sm={12} md={10} lg={10}>
							<h1>Te damos la bienvenida a THE PEOPLE LEADER</h1>
							<p>¿Quieres saber qué es lo que buscamos lograr? Mira el video.</p>
						</Grid>
						<Grid item xs={12} sm={12} md={10} lg={10}>
							<div className='contenedor-streaming'>
								<iframe title='streaming' src='https://player.vimeo.com/video/864864162?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' allow='autoplay; fullscreen' allowFullScreen frameBorder='0' className='estilo-streaming'></iframe>
							</div>
						</Grid>
					</Grid>
				</Container>
				{cualBalanza === 'izq' && (
					<>
						<Container maxWidth='lg' style={{ marginTop: '40px' }}>
							<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<div className='move-down' onMouseLeave={() => cambiarBalanza('normal')}>
										<div className='flip-box caring'>
											<div className='flip-box-inner'>
												<div className='flip-box-front'>
													<img src={caring} alt='' />
													<p>{`${caringPorcentaje}% avance`}</p>
												</div>
												<div className='flip-box-back'>
													<h1 style={{ color: '#fff' }}>Corazón</h1>
													<p>Asegura tu bienestar y el de tu equipo para seguir dando resultados y crecer como personas y como Compañía.</p>
												</div>
											</div>
										</div>
										{/* <div className='entrar' style={{ borderRadius: '0 0 0 80px' }} onClick={() => navigate('/home')}>
                      Entrar
                    </div> */}
										<div className='entrar' style={{ borderRadius: '0 0 0 80px' }} onClick={() => navigate('/pilares')}>
											Entrar
										</div>
									</div>
								</Grid>
								<Grid item lg={2} className='offset' />
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<div className='flip-box daring'>
										<div className='flip-box-inner'>
											<div className='flip-box-front'>
												<img src={daring} alt='' />
												<p>{`${daringPorcentaje}% avance`}</p>
											</div>
											<div className='flip-box-back'>
												<h1 style={{ color: '#005f21' }}>Cabeza</h1>
												<p>Inspira a tu equipo de trabajo y rétalos a trabajar por objetivos comunes, consolidando el crecimiento de la organización.</p>
											</div>
										</div>
									</div>
									<div className='entrar' style={{ borderRadius: '0 0 80px 0' }} onClick={() => navigate('/daring/home')}>
										Entrar
									</div>
								</Grid>
								<Grid item xs={10} sm={10} md={10} lg={10}>
									<div>
										<img src={balanzaIzq} className='full balanza' alt='balanza' />
									</div>
								</Grid>
							</Grid>
						</Container>
					</>
				)}

				{cualBalanza === 'normal' && (
					<>
						<Container maxWidth='lg' style={{ marginTop: '40px' }}>
							<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<div className='flip-box caring' style={{ width: '100%' }} onMouseEnter={() => cambiarBalanza('izq')}>
										<div className='flip-box-inner'>
											<div className='flip-box-front'>
												<img src={caring} alt='' />
												<p>{`${caringPorcentaje}% avance`}</p>
											</div>
											<div className='flip-box-back'>
												<h1 style={{ color: '#fff' }}>Corazón</h1>
												<p>Asegura tu bienestar y el de tu equipo para seguir dando resultados y crecer como personas y como Compañía.</p>
											</div>
										</div>
									</div>
									<div className='entrar' style={{ borderRadius: '0 0 0 80px' }} onClick={() => navigate('/home')}>
										Entrar
									</div>
								</Grid>
								<Grid item lg={2} className='offset' />
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<div className='flip-box daring' style={{ width: '100%' }} onMouseEnter={() => cambiarBalanza('der')}>
										<div className='flip-box-inner'>
											<div className='flip-box-front'>
												<img src={daring} alt='' />
												<p>{`${daringPorcentaje}% avance`}</p>
											</div>
											<div className='flip-box-back'>
												<h1 style={{ color: '#005f21' }}>Cabeza</h1>
												<p>Inspira a tu equipo de trabajo y rétalos a trabajar por objetivos comunes, consolidando el crecimiento de la organización.</p>
											</div>
										</div>
									</div>
									<div className='entrar' style={{ borderRadius: '0 0 80px 0' }} onClick={() => navigate('/daring/home')}>
										Entrar
									</div>
								</Grid>
								<Grid item xs={10} sm={10} md={10} lg={10}>
									<div>
										<img src={balanza} className='full balanza' alt='balanza' />
									</div>
								</Grid>
							</Grid>
						</Container>
					</>
				)}

				{cualBalanza === 'der' && (
					<>
						<Container maxWidth='lg' style={{ marginTop: '40px' }}>
							<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<div className='flip-box caring'>
										<div className='flip-box-inner'>
											<div className='flip-box-front'>
												<img src={caring} alt='' />
												<p>{`${caringPorcentaje}% avance`}</p>
											</div>
											<div className='flip-box-back'>
												<h1 style={{ color: '#fff' }}>Corazón</h1>
												<p>Asegura tu bienestar y el de tu equipo para seguir dando resultados y crecer como personas y como Compañía.</p>
											</div>
										</div>
									</div>
									<div className='entrar' style={{ borderRadius: '0 0 0 80px' }} onClick={() => navigate('/home')}>
										Entrar
									</div>
								</Grid>
								<Grid item lg={2} className='offset' />
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<div className='move-down' onMouseLeave={() => cambiarBalanza('normal')}>
										<div className='flip-box daring'>
											<div className='flip-box-inner'>
												<div className='flip-box-front'>
													<img src={daring} alt='' />
													<p>{`${daringPorcentaje}% avance`}</p>
												</div>
												<div className='flip-box-back'>
													<h1 style={{ color: '#005f21' }}>Cabeza</h1>
													<p>Inspira a tu equipo de trabajo y rétalos a trabajar por objetivos comunes, consolidando el crecimiento de la organización.</p>
												</div>
											</div>
										</div>
										<div className='entrar' style={{ borderRadius: '0 0 80px 0' }} onClick={() => navigate('/daring/home')}>
											Entrar
										</div>
									</div>
								</Grid>
								<Grid item xs={10} sm={10} md={10} lg={10}>
									<div>
										<img src={balanzaDer} className='full balanza' alt='balanza' />
									</div>
								</Grid>
							</Grid>
						</Container>
					</>
				)}
				<div className='center seccion'>
					<div className='verde'>
						<div>
							<div>
								<img src={corazon} alt='corazon' className='corazon' />
							</div>
							<p>
								“En HEINEKEN lideramos con Corazón y Cabeza para
								<br /> entregar los resultados mientras inspiramos y destapamos
								<br /> todo el potencial de nuestra gente y organización”
							</p>
						</div>
					</div>
				</div>
				<div className='center'>
					<img src={bannerFooter} alt='Banner' style={{ width: '1100px', marginTop: '60px' }} />
				</div>
				<div className='center'>
					<img src={btnDescarga} alt='btnDescarga' className='btnDescarga' onClick={() => window.open(PDFBroshure)} />
				</div>
				<Modal open={modal}>
					<Container maxWidth='lg'>
						<Grid container direction='row' alignItems='stretch' justifyContent='center'>
							<Grid item lg={10}>
								<div className='cursor btn-cerrar-popup' onClick={() => setModal(false)}>
									Cerrar
								</div>
							</Grid>
							<Grid item lg={10}>
								<video id='video1' className='element' src={video} style={{ width: '100%' }} controls autoPlay controlsList='nodownload noplaybackrate noremoteplayback'></video>
							</Grid>
						</Grid>
					</Container>
				</Modal>
			</div>
			<Footer />
		</ValidateUser>
	);
}
