import React from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import tituloMobile from '../../../../../resources/images/broshure/seccion10/img1-mobile.svg';
import titulo from '../../../../../resources/images/broshure/seccion10/titulo.svg';
import textoMobile from '../../../../../resources/images/broshure/seccion10/img2-mobile.svg';
import texto from '../../../../../resources/images/broshure/seccion10/texto.svg';

export default function Seccion10() {
    return <section id="seccion10">
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} className='wow rollIn' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                    <div className='center'>
                        <img src={`${isMobileOnly ? tituloMobile : titulo}`} alt="img" className='mb30 size' />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className='wow zoomInRight' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                    <div className='center'>
                        <img src={`${isMobileOnly ? textoMobile : texto}`} alt="img" className='size' />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}