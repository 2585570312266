import React, { useState, useEffect } from "react";
import SectionCertificate from "./SectionCertificate";
import SectionQuestions from "./SectionQuestions";
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import ValidateUser from "../../../../../components/ValidateUser";
import { searchDataByValue, top } from '../../../../../resources/js/functions';

export default function Certificate() {
  const [vista, setVista] = useState(0);

  const activeView = (id) => {
    setVista(id);
  };

  useEffect(() => {
    getValidaUser();
    top();
  }, [])

  const getValidaUser = async () => {
    try {
      const result = await searchDataByValue('encuestaCertificadoInclusion', 'user', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
      if (Object.values(result).length > 0) {
        setVista(1);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="certificate">
          {vista === 0 && <SectionQuestions activeView={activeView} />}
          {vista === 1 && <SectionCertificate activeView={activeView} />}
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
