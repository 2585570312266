import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setData, top } from '../../../resources/js/functions';
import { Grid, Container, Button } from '@material-ui/core';
import ValidateUser from '../../../components/ValidateUser';
import Header from '../../../components/HeaderDaring';
import Footer from '../../../components/FooterDaring';
import SpeakersConfertencias from '../SpeakersConferencias';
import Pilares from '../PilaresDaring';
import { ref, onChildChanged } from 'firebase/database';
import { db } from '../../../config/firebase';
import { download_file, getCollectionsComplete, getDate, searchDataByValue, updateData } from '../../../resources/js/functions';
import Swal from 'sweetalert2';
import ReactPlayer from 'react-player'
import likeOn from '../../../resources/images/pilares/like-on.svg';
import likeOff from '../../../resources/images/pilares/like-off.svg';
import dislikeOn from '../../../resources/images/pilares/dislike-on.svg';
import dislikeOff from '../../../resources/images/pilares/dislike-off.svg';
import visto from '../../../resources/images/pilares/bienestar/visto.svg';
import noVisto from '../../../resources/images/pilares/bienestar/no-visto.svg';
import './styles.scss';

export default function SeleccionarFase() {
  const navigate = useNavigate();
  const perfil = localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw');
  const [audiencia, setAudiencia] = useState('Ejecutivos');
  const [recursosActivos, setRecursosActivos] = useState({
    masterClass: true,
  });
  const urlKickOff = 'https://player.vimeo.com/video/873139166?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
  const urlMasterclass =
    perfil === 'Ejecutivos'
      ? 'https://player.vimeo.com/video/874951236?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
      : 'https://player.vimeo.com/video/875895749?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479';
  const [likes, setLikes] = useState({
    masterClass: {
      like: false,
      dislike: false,
    },
  });

  useEffect(() => {
    getInfoUSer();
    getlikes();
    const dbRefLikes = ref(db, `daring/likes/home/`);
    onChildChanged(dbRefLikes, (data) => {
      getlikes();
    });
  }, []);

  const getInfoUSer = async () => {
    const data = await searchDataByValue(`users`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
    if (data) {
      setAudiencia(data.audiencia);
    }
  };

  const getlikes = async () => {
    let json = {
      masterClass: {
        like: false,
        dislike: false,
      },
    };
    const data = await searchDataByValue(`daring/likes/home/`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
    if (Object.keys(data).length > 0) {
      if (data.masterClassfechaRegistro) {
        data.masterClass ? (json['masterClass'].like = true) : (json['masterClass'].dislike = true);
      }
    }

    setLikes(json);
  };

  const setLikesBd = (recurso, boolean) => {
    switch (recurso) {
      case 'masterClass':
        updateData('daring/likes/home/', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), masterClassfechaRegistro: getDate() });
        break;
    }
  };

  const setKickOffHome = () => {
    setData(`daring/kickoff/`, localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), {kickoff: true, fechaDeKickOff: getDate()})
  }
  const setMasterclassHome = () => {
    setData(`daring/masterclass/`, localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), {masterclass: true, fechaDeMasterClass: getDate()})
  }

  return (
    <ValidateUser>
      <Header />
      <div id='homeDaring'>
        <Container maxWidth='lg'>
          <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <h1>Kick Off</h1>
              <p>Mira el lanzamiento de nuestro segundo pilar Daring y atrévete a destapar nuestro máximo potencial.</p>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div className='center'>
                <ReactPlayer style={{ margin: 'auto' }} controls url={urlKickOff} onStart={() => setKickOffHome()} />
              </div>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth='lg'>
          <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <h1>Master Class: presentación del pilar daring</h1>
              <p>Conoce las competencias incluidas en el pilar Daring y cómo éstas pueden impulsar tu liderazgo en el día a día.</p>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <div className='contenedor-streaming'>
                <ReactPlayer style={{ margin: 'auto' }} controls url={urlMasterclass} onStart={() => setMasterclassHome()} />
              </div>
              <div className='full center'>
                <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <img src={recursosActivos.masterClass ? visto : noVisto} alt='img' />
                  </Grid>
                  {recursosActivos.masterClass && (
                    <>
                      <Grid item xs={8} sm={8} md={7} lg={7}>
                        <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                      </Grid>
                      <Grid item xs={4} sm={4} md={3} lg={3}>
                        <div className='contenedor-likes'>
                          <img className='btn-likes' src={likes.masterClass.like ? likeOn : likeOff} alt='img' onClick={() => setLikesBd('masterClass', true)} />
                          <img className='btn-likes' src={likes.masterClass.dislike ? dislikeOn : dislikeOff} alt='img' onClick={() => setLikesBd('masterClass', false)} />
                        </div>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </Grid>
            <SpeakersConfertencias />
          </Grid>
        </Container>
      </div>
      <Footer />
    </ValidateUser>
  );
}
