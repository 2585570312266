import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { Grid, Container, Button } from "@material-ui/core";
import textos from './textos';
import { top } from "../../../../../../resources/js/functions";
import img from '../../../../../../resources/images/pilares/inclucion/certificate/aprobado.svg'
import pleca from '../../../../../../resources/images/pilares/inclucion/certificate/pleca.svg';
import entrega from '../../../../../../resources/images/pilares/inclucion/certificate/entrega.svg';
import define from '../../../../../../resources/images/pilares/inclucion/certificate/define.svg';
import conecta from '../../../../../../resources/images/pilares/inclucion/certificate/conecta.svg';
import desarrolla from '../../../../../../resources/images/pilares/inclucion/certificate/desarrolla.svg';

export default function Aprobado(props) {
    const { perfil, seccion1, seccion2, seccion3, seccion4, activeView, calif } = props;
    const [suma1, setSuma1] = useState(0)
    const [suma2, setSuma2] = useState(0)
    const [suma3, setSuma3] = useState(0)
    const [suma4, setSuma4] = useState(0)
    const params = useParams();

    useEffect(() => {
        const sumaTotal1 = seccion1[0] + seccion1[1] + seccion1[2];
        const sumaTotal2 = seccion2[0] + seccion2[1] + seccion2[2];
        const sumaTotal3 = seccion3[0] + seccion3[1] + seccion3[2];
        const sumaTotal4 = seccion4[0] + seccion4[1] + seccion4[2];
        setSuma1(sumaTotal1)
        setSuma2(sumaTotal2)
        setSuma3(sumaTotal3)
        setSuma4(sumaTotal4)
        console.log(seccion1);
        console.log(seccion2);
        console.log(seccion3);
        console.log(seccion4);
        top();
    }, [])

    return (
        <>
            <div id="aprobado">
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <img src={img} alt="img" className="img-top" />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <div className="contenedor-calif">
                                Obtuviste<br />
                                {calif}<br />
                                aciertos de 12
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <div className="retro mt30">
                    <Container maxWidth="lg">
                        <Grid container direction="row">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <img src={pleca} alt="img" />
                            </Grid>
                        </Grid>
                    </Container>
                    {params.id === 'ejecutivos' && <>
                        <Container maxWidth="lg">
                            <Grid item lg={12} md={10} sm={12} xs={12} justifyContent='center' alignItems="center" >
                                <div className="contenedor-retro">
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="hijos center">
                                            <img src={entrega} alt="img" className="mt30 full" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="padre">
                                            <div className="contenedor-retro-opcion" style={{ borderLeft: '15px solid #FDC901' }}>
                                                <p>{suma1 > 1 ? textos[0].retroUno.bien : textos[0].retroUno.mal}</p>
                                            </div>

                                        </Grid>
                                        <div className="border" />
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="hijos center">
                                            <img src={define} alt="img" className="mt30 full" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="padre">
                                            <div className="contenedor-retro-opcion" style={{ borderLeft: '15px solid #EE771B' }}>
                                                <p>{suma2 > 1 ? textos[0].retroDos.bien : textos[0].retroDos.mal}</p>
                                            </div>

                                        </Grid>
                                        <div className="border" />
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="hijos center">
                                            <img src={conecta} alt="img" className="mt30 full" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="padre">
                                            <div className="contenedor-retro-opcion" style={{ borderLeft: '15px solid #2D6DB4' }}>
                                                <p>{suma3 > 1 ? textos[0].retroTres.bien : textos[0].retroTres.mal}</p>
                                            </div>

                                        </Grid>
                                        <div className="border" />
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="hijos center">
                                            <img src={desarrolla} alt="img" className="mt30 full" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="padre">
                                            <div className="contenedor-retro-opcion" style={{ borderLeft: '15px solid #892C85' }}>
                                                <p>{suma4 > 1 ? textos[0].retroCuatro.bien : textos[0].retroCuatro.mal}</p>
                                            </div>

                                        </Grid>
                                        <div className="border mb30" />
                                    </Grid>
                                </div>
                            </Grid>
                        </Container>
                    </>}
                    {params.id === 'jefaturas-y-coordinaciones' && <>
                        <Container maxWidth="lg">
                            <Grid item lg={12} md={10} sm={12} xs={12} justifyContent='center' alignItems="center" >
                                <div className="contenedor-retro">
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="hijos center">
                                            <img src={entrega} alt="img" className="mt30 full" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="padre">
                                            <div className="contenedor-retro-opcion" style={{ borderLeft: '15px solid #FDC901' }}>
                                                <p>{suma1 > 1 ? textos[0].retroUno.bien : textos[0].retroUno.mal}</p>
                                            </div>

                                        </Grid>
                                        <div className="border" />
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="hijos center">
                                            <img src={define} alt="img" className="mt30 full" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="padre">
                                            <div className="contenedor-retro-opcion" style={{ borderLeft: '15px solid #EE771B' }}>
                                                <p>{suma2 > 1 ? textos[0].retroDos.bien : textos[0].retroDos.mal}</p>
                                            </div>

                                        </Grid>
                                        <div className="border" />
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="hijos center">
                                            <img src={conecta} alt="img" className="mt30 full" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="padre">
                                            <div className="contenedor-retro-opcion" style={{ borderLeft: '15px solid #2D6DB4' }}>
                                                <p>{suma3 > 1 ? textos[0].retroTres.bien : textos[0].retroTres.mal}</p>
                                            </div>

                                        </Grid>
                                        <div className="border" />
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="hijos center">
                                            <img src={desarrolla} alt="img" className="mt30 full" />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12} className="padre">
                                            <div className="contenedor-retro-opcion" style={{ borderLeft: '15px solid #892C85' }}>
                                                <p>{suma4 > 1 ? textos[0].retroCuatro.bien : textos[0].retroCuatro.mal}</p>
                                            </div>

                                        </Grid>
                                        <div className="border mb30" />
                                    </Grid>
                                </div>
                            </Grid>
                        </Container>
                    </>}
                </div>
                <Container maxWidth="lg" className="mt30">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Grid container direction="row" alignItems="center" spacing={3}>
                                <Grid item lg={9} md={6} sm={12} xs={12}>
                                    <p>Aprovecha lo que ahora sabes y ponlo en práctica diariamente. Y si necesitas reforzar algún concepto, siempre puedes volver a consultar los recursos digitales.</p>
                                    <p>Ahora da clic en el botón para generar tu constancia.</p>
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12} className='mt30'>
                                    <Button className="btn" onClick={() => activeView(1)} style={{ width: '100%' }}>Generar constancia</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}

