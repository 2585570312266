const texto = [
	{
		inclusion: {
			bien: 'Tienes claridad sobre lo que creemos en HEINEKEN sobre el talento y reconoces su importancia para el crecimiento de nuestra gente mediante el empoderamiento, el diálogo y la retroalimentación.',
			mal: 'Necesitas reforzar tu conocimiento sobre nuestras creencias sobre el talento, ya que son la base para el crecimiento de nuestra gente mediante el empoderamiento, el diálogo y la retroalimentación.',
		},
		equidad: {
			bien: 'Sabes que el talento se comprende como una conjunción entre el desempeño y el potencial. Además, tienes claridad de los factores que componen nuestro modelo.',
			mal: 'Te invitamos a reforzar la comprensión de nuestro modelo para que puedas identificar el potencial de tu gente en forma coherente y unificada.',
		},
		transparencia: {
			bien: 'Comprendes la importancia es estas conversaciones y tienes los fundamentos para llevarlas a cabo de manera clara y eficaz con las personas a tu cargo.',
			mal: 'Dedica tiempo a repasar los pasos y estrategias que te ayudarán a tener conversaciones de carrera de efectivas con las personas a tu cargo.',
		},
	},
	{
		inclusion: {
			bien: 'Tienes claridad sobre lo que creemos en HEINEKEN sobre el talento y reconoces su importancia para el crecimiento de nuestra gente mediante el empoderamiento, el diálogo y la retroalimentación.',
			mal: 'Necesitas reforzar tu conocimiento sobre nuestras creencias sobre el talento, ya que son la base para el crecimiento de nuestra gente mediante el empoderamiento, el diálogo y la retroalimentación.',
		},
		equidad: {
			bien: 'Sabes que el talento se comprende como una conjunción entre el desempeño y el potencial. Además, tienes claridad de los factores que componen nuestro modelo.',
			mal: 'Te invitamos a reforzar la comprensión de nuestro modelo para que puedas identificar el potencial de tu gente en forma coherente y unificada.',
		},
		transparencia: {
			bien: 'Comprendes la importancia es estas conversaciones y tienes los fundamentos para llevarlas a cabo de manera clara y eficaz con las personas a tu cargo.',
			mal: 'Dedica tiempo a repasar los pasos y estrategias que te ayudarán a tener conversaciones de carrera de efectivas con las personas a tu cargo.',
		},
	},
];
export default texto;
