import React from 'react';
import { Grid, Container } from "@material-ui/core";
import img1 from '../../../../../resources/images/broshure/seccion16/img1.svg';
import img2 from '../../../../../resources/images/broshure/seccion16/img2.svg';


export default function Seccion16() {

    return <section id="seccion16">
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={6} className='center wow fadeIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={img1} alt="img" className='mb30 size' />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className='center wow rotateInDownRight' data-wow-delay='.4s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={img2} alt="img" className='mb30 size' />
                    </div>
                </Grid>
               
            </Grid>
        </Container>
    </section>
}