import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { download_file, getDate, searchDataByValue, top, updateData } from '../../../resources/js/functions';
import { Grid, Container } from '@material-ui/core';
import Oyente from './Oyente';
import Narrador from './Narrador';
import Transformador from './Transformador';
import Certificado from './Certificado';
import btn1 from '../../../resources/images/daring/pilares/oyente.svg';
import btn2 from '../../../resources/images/daring/pilares/narrador.svg';
import btn3 from '../../../resources/images/daring/pilares/transformador.svg';
import btn4 from '../../../resources/images/daring/pilares/certificado.svg';
import btn5 from '../../../resources/images/daring/pilares/portada-playbook.svg';
import './styles.scss';

export default function PilaresDaring() {
	const [pilar, setPilar] = useState(1);
	const [activar, setActivar] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		top();
		setPilar(parseInt(localStorage.getItem('pilarDaring')) || 1);
		validar();
	}, []);

	const validar = async () => {
		const oyente = await searchDataByValue(`daring/encuesta/oyente`, 'user', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
		const narrador = await searchDataByValue(`daring/encuesta/narrador`, 'user', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
		const transformador = await searchDataByValue(`daring/encuesta/transformador`, 'user', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
		console.log(oyente);
		console.log(narrador);
		console.log(transformador);
		if (Object.keys(oyente).length > 0 && Object.keys(narrador).length > 0 && Object.keys(transformador).length > 0) {
			setActivar(true);
		}
	};

	const cualBoton = (id) => {
		setPilar(id);
		localStorage.setItem('pilarDaring', id);
		if (id === 5) {
			updateData('daring/playbook', `${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`, { playbook: true, fechaPlaybook: getDate() }).then(() => {
				download_file('https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2FDG_PlaybookDaring.pdf?alt=media&token=9cbee75c-bb9a-4f06-9af1-12179cbef53c', 'playbook');
			});
		}
	};

	return (
		<div id='pilaresDaring'>
			<Container maxWidth='xl'>
				<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
					<Grid item xs={12} sm={6} md={2} lg={2} className='center wow zoomIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
						<div className='container-btns' onClick={() => cualBoton(1)}>
							<img src={btn1} alt='btn' className={`btn-pilar cursor ${pilar === 1 && 'border'}`} />
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={2} lg={2} className='center wow zoomIn' data-wow-delay='.4s' data-wow-offset='100' data-wow-duration='.5s'>
						<div className='container-btns' onClick={() => cualBoton(2)}>
							<img src={btn2} alt='btn' className={`btn-pilar cursor ${pilar === 2 && 'border'}`} />
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={2} lg={2} className='center wow zoomIn' data-wow-delay='.6s' data-wow-offset='100' data-wow-duration='.5s'>
						<div className='container-btns' onClick={() => cualBoton(3)}>
							<img src={btn3} alt='btn' className={`btn-pilar cursor ${pilar === 3 && 'border'}`} />
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={2} lg={2} className='center wow zoomIn' data-wow-delay='.6s' data-wow-offset='100' data-wow-duration='.5s'>
						<div className='container-btns' onClick={() => cualBoton(5)}>
							<img src={btn5} alt='btn' className={`btn-pilar cursor ${pilar === 5 && 'border'}`} />
						</div>
					</Grid>
					{activar && (
						<Grid item xs={12} sm={6} md={2} lg={2} className='center wow zoomIn' data-wow-delay='.6s' data-wow-offset='100' data-wow-duration='.5s'>
							<div className='container-btns' onClick={() => cualBoton(4)}>
								<img src={btn4} alt='btn' className={`btn-pilar cursor ${pilar === 4 && 'border'}`} />
							</div>
						</Grid>
					)}
				</Grid>
			</Container>

			{pilar === 1 && <Oyente />}
			{pilar === 2 && <Narrador />}
			{pilar === 3 && <Transformador />}
			{pilar === 4 && <Certificado />}
			<Container maxWidth='lg' className='mbt60'>
				<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
					<Grid item lg={12} md={12} xs={12}>
						<div className='cursor center' onClick={() => navigate(-1)}>
							{'< Regresar'}
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}
