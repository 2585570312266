import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Container, Grid, TextField, Button } from "@material-ui/core";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { validateEmail, updateData, searchDataByValue, getDate } from "../../../../resources/js/functions";
import Logo from '../../../../resources/images/header/logo.svg';

export default function FormLogin(props) {
  const { activeView } = props;
  const [campos, setCampos] = useState({});
  const navigate = useNavigate();
  const auth = getAuth();

  const rol = string => {
    let compact = '';
    switch (string) {
      case "Super Administrador":
        compact = '0242ac120002';
        break;
      case "Administrador":
        compact = '76c66ae87579';
        break;
      case "Consultor":
        compact = '051c0d485398';
        break;

      default:
        break;
    }
    return compact;
  }

  const validar = async (e) => {
    e.preventDefault();
    if (Object.keys(campos).length === 0) {
      Swal.fire({
        title: "¡Oooopss!",
        text: 'El campo correo electrónico esta vacio',
        icon: "warning",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#F39304",
      });
      return null;
    }

    // const minusculas = campos.correo.toLowerCase();
    // const result = await searchDataByValue('usuarios', 'correo', minusculas);
    signInWithEmailAndPassword(auth, campos.correo, '1vKqm5k8eHwp')
      .then((userCredential) => {
        
        localStorage.setItem('531ac50224f238df5d6efdaf36507cf2', userCredential.user.email)
        setTimeout(() => {
          navigate("/admin/dashboard");
        }, 800);
      })
      .catch((error) => {
        const errorCode = error.code;
        let msj = 'Contacta un Administrador';
        if (errorCode === 'auth/user-not-found') {
          msj = 'Correo no registrado'
        }
        if (errorCode === 'auth/wrong-password') {
          msj = 'Correo y/o Contraseña incorrecta'
        }
        Swal.fire({
          title: "¡Oooopss!",
          text: msj,
          icon: "error",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#F39304",
        });
      });

  };

  return (
    <section id='loginAdmin'>
    {/* <Container maxWidth='md'> */}
        <div className="container-form-login">
          <div id="AAAAAAAA">
          <form onSubmit={validar}>
            <Grid container direction='row' justify='center' alignItems='center' spacing={2} className='Abner'>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className="full center">
                  <img src={Logo} alt="logo" style={{ width: '300px' }} />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={12} xs={12}>
                <div className='full center welcome'>¡Bienvenido!</div>
                &nbsp;
                <div className='full center mb-60 subtitle'>Ingresa tus correo electrónico para acceder a la plataforma</div>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <div className="">
                  <TextField autoComplete="true" type="email" fullWidth size="small" label="Correo electrónico" variant="filled" onChange={(e) => setCampos({ ...campos, correo: e.target.value })} />
                  &nbsp;
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <div className="full center space-footer-form">
                  <Button className="btn btn-yellow" type="submit">
                    Iniciar sesión
                  </Button>
                </div>
              </Grid>
             
            </Grid>
          </form>
        </div>
        </div>
      {/* </Container> */}
       </section>
  );
}
