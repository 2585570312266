import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Grid, Container } from "@material-ui/core";
import Swal from "sweetalert2";
import { ref, onChildChanged } from "firebase/database";
import { db } from "../../../../config/firebase";
import { download_file, getCollections, updateData, searchDataByValue, getDate, setData } from '../../../../resources/js/functions'
import informacion from './info';

import PortadaMasterClass from '../../../../resources/images/pilares/bienestar/portada-master-class.svg';
import PortadaMasterClassJefatura from '../../../../resources/images/pilares/bienestar/portada-master-class-jefatura.svg';
import PortadaVideoClass from '../../../../resources/images/pilares/bienestar/portada-video-tutorial.svg';
import PortadaVideoClassJefatura from '../../../../resources/images/pilares/bienestar/portada-video-tutorial-jefatura.svg';
import podcast from '../../../../resources/images/pilares/bienestar/podcast.svg';
import podcastJefatura from '../../../../resources/images/pilares/bienestar/podcast-jefatura.svg';
import playbook from '../../../../resources/images/pilares/bienestar/playbook.svg';
import playbookJefatura from '../../../../resources/images/pilares/bienestar/playbook-jefatura.svg';
import bienestar from '../../../../resources/images/pilares/bienestar/bienestar.svg';
import evaluacion from '../../../../resources/images/pilares/bienestar/evaluacion.svg';
import evaluacionJefatura from '../../../../resources/images/pilares/bienestar/evaluacion-jefatura.svg';
import visto from '../../../../resources/images/pilares/bienestar/visto.svg';
import noVisto from '../../../../resources/images/pilares/bienestar/no-visto.svg';
import scroll from '../../../../resources/images/scroll.gif';
import likeOn from '../../../../resources/images/pilares/like-on.svg';
import likeOff from '../../../../resources/images/pilares/like-off.svg';
import dislikeOn from '../../../../resources/images/pilares/dislike-on.svg';
import dislikeOff from '../../../../resources/images/pilares/dislike-off.svg';
import heiLife from '../../../../resources/images/home/hei-life.svg';

export default function Bienestar() {
    const navigate = useNavigate();
    const [audiencia, setAudiencia] = useState('Ejecutivos');
    const [recursosActivos, setRecursosActivos] = useState({
        masterClass: false,
        tutorial: false,
        podcast: false,
        playbook: false,
        evaluacion: false,
    });
    const [likes, setLikes] = useState({
        masterClass: {
            like: false,
            dislike: false,
        },
        tutorial: {
            like: false,
            dislike: false,
        },
        podcast: {
            like: false,
            dislike: false,
        },
        playbook: {
            like: false,
            dislike: false,
        },
        evaluacion: {
            like: false,
            dislike: false,
        }
    })

    useEffect(() => {
        getRecursos();
        getInfoUSer();
        getlikes();
        const dbRef = ref(db, `recursos/`);
        onChildChanged(dbRef, (data) => {
            getRecursos();
        });
        const dbRefLikes = ref(db, `likes/bienestar/`);
        onChildChanged(dbRefLikes, (data) => {
            getlikes();
        });
    }, [])

    const getInfoUSer = async () => {
        const data = await searchDataByValue(`users`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
        if (data) {
            setAudiencia(data.audiencia);
        }
    }

    const getRecursos = async () => {
        const data = await searchDataByValue(`recursos`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
        let json = {
            masterClass: data.masterClass || false,
            tutorial: data.tutorial || false,
            podcast: data.podcast || false,
            playbook: data.playbook || false,
            evaluacion: data.evaluacion || false,
        }
        // console.log(json);
        setRecursosActivos(json);
    }

    const irRecurso = id => {
        switch (id) {
            case 1:
                updateData('recursos', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'masterClass': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoMasterClass: getDate() });
                navigate(audiencia === 'Ejecutivos' ? informacion[0].masterClass.url : informacion[1].masterClass.url)
                break;
            case 2:
                navigate(audiencia === 'Ejecutivos' ? informacion[0].videoTutorial.url : informacion[1].videoTutorial.url)
                updateData('recursos', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'tutorial': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoTutorial: getDate() });
                // if (localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') === ''){
                //     console.log('if 1');
                // }

                break;
            case 3:
                navigate(audiencia === 'Ejecutivos' ? informacion[0].podcast.url : informacion[1].podcast.url)
                updateData('recursos', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'podcast': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoPodcast: getDate() });
                break;
            case 4:
                updateData('recursos', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'playbook': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoPlaybook: getDate() });
                const url = audiencia === 'Ejecutivos' ? informacion[0].playbook.url : informacion[1].playbook.url;
                download_file(url, 'playbook');
                break;
            case 5:
                if (recursosActivos.masterClass && recursosActivos.tutorial && recursosActivos.podcast && recursosActivos.playbook) {
                    updateData('recursos', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { 'evaluacion': true, 'id': localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoEvaluacion: getDate() });
                    navigate(audiencia === 'Ejecutivos' ? informacion[0].evaluacion.url : informacion[1].evaluacion.url)
                } else {
                    Swal.fire({
                        title: "¡Espera!",
                        text: "Termina de consultar todos los recursos para acceder a la evaluación.",
                        icon: "warning",
                        confirmButtonText: "Cerrar",
                        confirmButtonColor: "#2D6DB4",
                        // cancelButtonColor: "#2D6DB4",

                    })
                }
                break;

            default:
                break;
        }
    }

    const modalMasterClass = () => {
        const texto = audiencia === 'Ejecutivos' ? `Conéctate al evento en vivo el 9 de agosto a las 11:30 am (hora Centro).` : `Conéctate al evento en vivo el 10 de agosto a las 11:30 am (hora Centro).`;
        Swal.fire({
            title: "¡Espera!",
            text: texto,
            icon: "warning",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#2D6DB4",
            // cancelButtonColor: "#2D6DB4",

        })
    }

    const getlikes = async () => {
        const data = await searchDataByValue(`likes/bienestar`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
        let json = {
            masterClass: {
                like: false,
                dislike: false,
            },
            tutorial: {
                like: false,
                dislike: false,
            },
            podcast: {
                like: false,
                dislike: false,
            },
            playbook: {
                like: false,
                dislike: false,
            },
            evaluacion: {
                like: false,
                dislike: false,
            }
        };
        if (Object.keys(data).length > 0) {

            if (data.masterClassLikefechaRegistro) {
                data.masterClass ? json['masterClass'].like = true : json['masterClass'].dislike = true
            }

            if (data.tutorialLikefechaRegistro) {
                data.tutorial ? json['tutorial'].like = true : json['tutorial'].dislike = true
            }

            if (data.podcastLikefechaRegistro) {
                if (data.podcast || !data.podcast) {
                    data.podcast ? json['podcast'].like = true : json['podcast'].dislike = true
                }
            }

            if (data.playbookLikefechaRegistro) {
                data.playbook ? json['playbook'].like = true : json['playbook'].dislike = true
            }
            setLikes(json);
        }

    }

    const setLikesBd = (recurso, boolean) => {
        switch (recurso) {
            case 'masterClass':
                updateData('likes/bienestar', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), masterClassLikefechaRegistro: getDate() })
                break;
            case 'tutorial':
                updateData('likes/bienestar', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), tutorialLikefechaRegistro: getDate() })
                break;
            case 'podcast':
                updateData('likes/bienestar', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), podcastLikefechaRegistro: getDate() })
                break;
            case 'playbook':
                updateData('likes/bienestar', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), playbookLikefechaRegistro: getDate() })
                break;
            case 'evaluacion':

                break;

            default:
                break;
        }
    }

    return (
        <>
            <div id="bienestar">
                <div id="video-introductorio">
                    <Container maxWidth="lg">
                        <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                            <Grid item xs={12} sm={12} md={1} lg={1} className=' center'>
                                <img src={scroll} alt="scroll" className='scroll-gif' />
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg">
                        <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                            <Grid item xs={12} sm={6} md={4} lg={4} className='wow zoomInLeft center' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <p>¿De qué trata este pilar? <br />Mira el video para descubrirlo.</p>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="contenedor-streaming">
                                    <iframe title="streaming" src="https://player.vimeo.com/video/864857293?badge=0&autopause=0&player_id=0&app_id=58479" allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div id="recursos-digitales">
                    <Container maxWidth="lg">
                        <Grid container direction="row" alignItems="stretch" spacing={2}>
                            <Grid item xs={12} sm={12} md={10} lg={10} className='wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <h1>Recursos digitales.</h1>
                                <p className='descripcion'>Date el tiempo necesario para revisarlos en su totalidad.</p>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg">
                        <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => irRecurso(1)}>
                                    {/* <div className="fullwrap" onClick={() => modalMasterClass()}> */}
                                    <img src={audiencia === 'Ejecutivos' ? PortadaMasterClass : PortadaMasterClassJefatura} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? informacion[0].masterClass.titulo : informacion[1].masterClass.titulo}
                                        </div>
                                        <div className="btn-caption btn-one">
                                            {audiencia === 'Ejecutivos' ? informacion[0].masterClass.experto : informacion[1].masterClass.experto}
                                        </div>
                                    </div>
                                </div>
                                <div className='full center'>
                                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <img src={recursosActivos.masterClass ? visto : noVisto} alt="img" />
                                        </Grid>
                                        {recursosActivos.masterClass && <><Grid item xs={8} sm={8} md={7} lg={7}>
                                            <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                                        </Grid>
                                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likes.masterClass.like ? likeOn : likeOff} alt="img" onClick={() => setLikesBd('masterClass', true)} />
                                                    <img className='btn-likes' src={likes.masterClass.dislike ? dislikeOn : dislikeOff} alt="img" onClick={() => setLikesBd('masterClass', false)} />
                                                </div>
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => irRecurso(2)}>
                                    <img src={audiencia === 'Ejecutivos' ? PortadaVideoClass : PortadaVideoClassJefatura} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? informacion[0].videoTutorial.titulo : informacion[1].videoTutorial.titulo}
                                        </div>
                                        <div className="btn-caption btn-one">
                                            {audiencia === 'Ejecutivos' ? informacion[0].videoTutorial.experto : informacion[1].videoTutorial.experto}
                                        </div>
                                    </div>
                                </div>
                                <div className='full center'>
                                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <img src={recursosActivos.tutorial ? visto : noVisto} alt="img" />
                                        </Grid>
                                        {recursosActivos.tutorial && <><Grid item xs={8} sm={8} md={7} lg={7}>
                                            <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                                        </Grid>
                                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likes.tutorial.like ? likeOn : likeOff} alt="img" onClick={() => setLikesBd('tutorial', true)} />
                                                    <img className='btn-likes' src={likes.tutorial.dislike ? dislikeOn : dislikeOff} alt="img" onClick={() => setLikesBd('tutorial', false)} />
                                                </div>
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => irRecurso(3)}>
                                    <img src={audiencia === 'Ejecutivos' ? podcast : podcastJefatura} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? informacion[0].podcast.titulo : informacion[1].podcast.titulo}
                                        </div>
                                        <div className="btn-caption btn-one">
                                            {audiencia === 'Ejecutivos' ? informacion[0].podcast.experto : informacion[1].podcast.experto}
                                        </div>
                                    </div>
                                </div>
                                <div className='full center'>
                                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <img src={recursosActivos.podcast ? visto : noVisto} alt="img" />
                                        </Grid>
                                        {recursosActivos.podcast && <><Grid item xs={8} sm={8} md={7} lg={7}>
                                            <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                                        </Grid>
                                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likes.podcast.like ? likeOn : likeOff} alt="img" onClick={() => setLikesBd('podcast', true)} />
                                                    <img className='btn-likes' src={likes.podcast.dislike ? dislikeOn : dislikeOff} alt="img" onClick={() => setLikesBd('podcast', false)} />
                                                </div>
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                {/* <a href={PDF} download="playbook" > */}
                                <div className="fullwrap" onClick={() => irRecurso(4)}>
                                    <img src={audiencia === 'Ejecutivos' ? playbook : playbookJefatura} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? informacion[0].playbook.titulo : informacion[1].playbook.titulo}
                                        </div>
                                        {informacion[0].playbook.experto !== '' || informacion[1].playbook.experto !== '' && <div className="btn-caption btn-one">
                                            {audiencia === 'Ejecutivos' ? informacion[0].playbook.experto : informacion[1].playbook.experto}
                                        </div>}
                                    </div>
                                </div>
                                {/* </a> */}
                                <div className='full center'>
                                    <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                                        <Grid item xs={12} sm={12} md={2} lg={2}>
                                            <img src={recursosActivos.playbook ? visto : noVisto} alt="img" />
                                        </Grid>
                                        {recursosActivos.playbook && <><Grid item xs={8} sm={8} md={7} lg={7}>
                                            <b>¿TE GUSTÓ ESTE CONTENIDO?</b>
                                        </Grid>
                                            <Grid item xs={4} sm={4} md={3} lg={3}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likes.playbook.like ? likeOn : likeOff} alt="img" onClick={() => setLikesBd('playbook', true)} />
                                                    <img className='btn-likes' src={likes.playbook.dislike ? dislikeOn : dislikeOff} alt="img" onClick={() => setLikesBd('playbook', false)} />
                                                </div>
                                            </Grid></>}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container maxWidth="lg" style={{ marginTop: '30px' }}>
                        <Grid container direction="row" alignItems="center" justifyContent='center' spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className="fullwrap" onClick={() => irRecurso(5)}>
                                    <img src={audiencia === 'Ejecutivos' ? evaluacion : evaluacionJefatura} alt="img" className='full cursor' />
                                    <div className="fullcap">
                                        <div className="titulo">
                                            {audiencia === 'Ejecutivos' ? informacion[0].evaluacion.titulo : informacion[1].evaluacion.titulo}
                                        </div>
                                    </div>
                                </div>
                                <div className='full center'>
                                    <img src={recursosActivos.evaluacion ? visto : noVisto} alt="img" />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                    <div className='retro-likes'>
                        <Container maxWidth="lg" >
                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                <Grid item xs={12} sm={12} md={7} lg={7} className='center wow zoomIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                    <div className='blanco'>
                                        <Grid container direction="row" alignItems="center" spacing={2}>
                                            <Grid item xs={8} sm={8} md={10} lg={10} className='texto-feedback'>
                                                <b>No olvides decirnos si te gustaron los contenidos</b>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={2} lg={2}>
                                                <div className='contenedor-likes'>
                                                    <img className='btn-likes' src={likeOn} alt="img" />
                                                    <img className='btn-likes' src={dislikeOn} alt="img" />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                    <Container maxWidth="lg" >
                        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={7} lg={7} className='center wow zoomIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                                <div className='hei-life' onClick={() => window.open('https://heiway.sharepoint.com/sites/HEI-LIFE/SitePages/Professional-Wellbeing.aspx')}>
                                    <Grid container direction="row" alignItems="center" spacing={2}>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <img src={heiLife} alt="hei-life" className='full' />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={9} lg={9}>
                                            <b>Consulta aquí los recursos adicionales</b> que tenemos de HEI-LIFE (Bienestar Profesional, Bienestar Emocional, Bienestar Social, Bienestar Físico)
                                        </Grid>
                                    </Grid>

                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div >
        </>
    )
}