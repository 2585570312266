import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import { ejectutivos, jefaturasyCoordinadores } from '../../informacion';
import gabrielFernandez from '../../../../../../resources/images/pilares/inclucion/gabriel-fernandez.svg';
import dianaMoreno from '../../../../../../resources/images/pilares/inclucion/diana-moreno.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30">
        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
          <Grid item lg={5} md={12} xs={12} >
            <div className="full">
              <div className="name-conferencista full ">
                <div >
                  <img className="img-conferencista" src={gabrielFernandez} alt="img" />
                </div>
                <span className="tendencias bold" style={{ display: "inline-block" }}>
                  <span className="job-conferencista">
                    <span className="name">Gabriel Fernández</span>
                    <span className="description-conferencista">
                      <ul>
                        <li>Líder de Diversidad, Equidad e Inclusión en MERCER México </li>
                        <li>Coach y Consultor Senior</li>
                        <li>Posgrado en Diversidad e Inclusión</li>
                      </ul>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </Grid>
          <Grid item lg={5} md={12} xs={12} >
            <div className="full">
              <div className="name-conferencista full ">
                <div >
                  <img className="img-conferencista" src={dianaMoreno} alt="img" />
                </div>
                <span className="tendencias bold" style={{ display: "inline-block" }}>
                  <span className="job-conferencista">
                    <span className="name">Diana Moreno</span>
                    <span className="description-conferencista">
                      <ul>
                        <li>Consultora Senior en MERCER México</li>
                        <li>Pedagoga, Conferencista y Coach Ejecutivo</li>
                        <li>Líder de proyectos de Diversidad, Equidad e Inclusión</li>
                      </ul>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
