import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'
import { Grid, Container, Button } from "@material-ui/core";
import { top } from "../../../../resources/js/functions";
import texto from "./textos";
import img from '../../../../resources/images/certificate/img.svg';
import bienestar from '../../../../resources/images/certificate/bienestar.svg';
import autoconocimientoyEmociones from '../../../../resources/images/certificate/autoconocimiento-y-emociones-mal.svg';
import autoconocimientoyEmocionesBien from '../../../../resources/images/certificate/autoconocimiento-y-emociones-bien.svg';
import planeacionyGestionDelTiempo from '../../../../resources/images/certificate/planeacion-y-gestion-del-tiempo-mal.svg';
import planeacionyGestionDelTiempoBien from '../../../../resources/images/certificate/planeacion-y-gestion-del-tiempo-bien.svg';
import bienestarMal from '../../../../resources/images/certificate/bienestar-mal.svg';
import bienestarBien from '../../../../resources/images/certificate/bienestar-bien.svg';

export default function Reprobado(props) {
    const { perfil, seccion1, seccion2, seccion3, calif } = props;
    const [suma1, setSuma1] = useState(0)
    const [suma2, setSuma2] = useState(0)
    const [suma3, setSuma3] = useState(0)
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const sumaTotal1 = seccion1[0] + seccion1[1] + seccion1[2] + seccion1[3];
        const sumaTotal2 = seccion2[0] + seccion2[1] + seccion2[2];
        const sumaTotal3 = seccion3[0] + seccion3[1] + seccion3[2];
        setSuma1(sumaTotal1)
        setSuma2(sumaTotal2)
        setSuma3(sumaTotal3)
        top();
    }, [])

    const logo = (perfil) => {
        let logo = ''
        switch (perfil) {
            case "bienestar":
                logo = bienestar;
                break;

            default:
                break;
        }

        return logo;
    }

    return (
        <>
            <div id="reprobado">
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={9} md={9} sm={12} xs={12}>
                            <img src={img} alt="img" className="img-top" />
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <div className="contenedor-calif">
                                Obtuviste<br />
                                {calif}<br />
                                aciertos de 10
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <div className="retro mt30">
                    <Container maxWidth="lg">
                        <Grid container direction="row">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <img src={logo(perfil)} alt="img" />
                            </Grid>
                        </Grid>
                    </Container>
                    {params.id === 'ejecutivos' && <>
                        <Container maxWidth="lg">
                            <Grid item lg={12} md={10} sm={12} xs={12} justifyContent='center' alignItems="center" >
                                <div className="contenedor-retro">
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma1 > 2 ? autoconocimientoyEmocionesBien : autoconocimientoyEmociones} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="padre">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre" >
                                                    <div className="full padre">
                                                        <div className='titulo-retro morado hijos'>
                                                            Manejo de las emociones
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma1 > 2 ? texto[0].autococimiento.bien : texto[0].autococimiento.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma2 > 1 ? planeacionyGestionDelTiempoBien : planeacionyGestionDelTiempo} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro azul hijos'>
                                                            Priorización y enfoque
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma2 > 1 ? texto[0].planeacion.bien : texto[0].planeacion.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma3 > 1 ? bienestarBien : bienestarMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro verde hijos'>

                                                            Bienestar (mío y de mi equipo)
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma3 > 1 ? texto[0].bienestar.bien : texto[0].bienestar.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Container>
                    </>}
                    {params.id === 'jefaturas-y-coordinaciones' && <>
                        <Container maxWidth="lg">
                            <Grid item lg={12} md={10} sm={12} xs={12} justifyContent='center' alignItems="center" >
                                <div className="contenedor-retro">
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma1 > 2 ? autoconocimientoyEmocionesBien : autoconocimientoyEmociones} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="padre">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre" >
                                                    <div className="full padre">
                                                        <div className='titulo-retro morado hijos'>
                                                            Autoconocimiento
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma1 > 2 ? texto[1].autococimiento.bien : texto[1].autococimiento.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma2 > 1 ? planeacionyGestionDelTiempoBien : planeacionyGestionDelTiempo} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2} >
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro azul hijos'>
                                                            Planeación
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma2 > 1 ? texto[1].planeacion.bien : texto[1].planeacion.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" spacing={3} alignItems='center' justifyContent="center" className='padre'>
                                        <Grid item lg={2} md={2} sm={12} xs={12} className="hijos center">
                                            <img src={suma3 > 1 ? bienestarBien : bienestarMal} alt="img" />
                                        </Grid>
                                        <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                                <Grid item lg={4} md={4} sm={12} xs={12} className="hijos padre">
                                                    <div className="full padre">
                                                        <div className='titulo-retro verde hijos'>

                                                            Bienestar (mío y de mi equipo)
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item lg={8} md={8} sm={12} xs={12} className="hijos">
                                                    <p>{suma3 > 1 ? texto[1].bienestar.bien : texto[1].bienestar.mal}</p>
                                                </Grid>
                                            </Grid>
                                            <div className="border" />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Container>
                    </>}
                </div>
                <Container maxWidth="lg" className="mt30">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Grid container direction="row" alignItems="center" spacing={3}>
                                <Grid item lg={9} md={6} sm={12} xs={12}>
                                    <p>Puedes intentarlo nuevamente, aunque te recomendamos volver a revisar todos los recursos digitales para reforzar la información.</p>
                                    <p>Recuerda que aprobar la evaluación es un requisito para obtener tu constancia.</p>
                                </Grid>
                                <Grid item lg={3} md={6} sm={12} xs={12} className='mt30'>
                                    <Button className="btn btn-reprobado" onClick={() => navigate('/home')} style={{ width: '100%' }}>Regresar a los recursos</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
}

