// expertos
import SantiagoKlappenbach from '../../../../resources/images/pilares/inclucion/santiago-klappenbach.svg';
import ShirleySaenz from '../../../../resources/images/pilares/inclucion/shirley-saenz.svg';
import CarlosSuarez from '../../../../resources/images/pilares/inclucion/carlos-suarez.svg';
import AlejandraBarba from '../../../../resources/images/pilares/inclucion/alejandra-barba.svg';
import sandraBravo from '../../../../resources/images/pilares/inclucion/sandra-bravo.svg';
import marcellaPlata from '../../../../resources/images/pilares/inclucion/marcella-plata.svg';

// ejectivos
import PortadaMasterClass from '../../../../resources/images/pilares/inclucion/portada-master-class.svg';
import PortadaVideoClass from '../../../../resources/images/pilares/inclucion/portada-video-tutorial.svg';
import podcast from '../../../../resources/images/pilares/inclucion/podcast.svg';
import playbook from '../../../../resources/images/pilares/inclucion/playbook.svg';
import evaluacion from '../../../../resources/images/pilares/inclucion/evaluacion.svg';

// jefaturas y coordinaciones
import PortadaMasterClassJefatura from '../../../../resources/images/pilares/inclucion/portada-master-class-jefatura.svg';
import PortadaVideoClassJefatura from '../../../../resources/images/pilares/inclucion/portada-video-tutorial-jefatura.svg';
import podcastJefatura from '../../../../resources/images/pilares/inclucion/podcast-jefatura.svg';
import playbookJefatura from '../../../../resources/images/pilares/inclucion/playbook-jefatura.svg';
import evaluacionJefatura from '../../../../resources/images/pilares/inclucion/evaluacion-jefatura.svg';




const ejectutivos = {
	videoIntoduccion: 'https://player.vimeo.com/video/864858697?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
	masterClass: {
		portada: PortadaMasterClass,
		url: '/master-class/ejecutivos/inclusion',
		titulo: 'Construyendo nuevos Liderazgos',
		descripcion: 'Aprende cómo construir nuevos liderazgos que fomenten la Diversidad, la Inclusión y la Equidad en ambientes seguros mediados por la comunicación y el respeto.',
		urlVideoMasterClass: 'https://player.vimeo.com/video/864859875?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: SantiagoKlappenbach,
		experto: 'Santiago Klappenbach',
		descripcionExperto: (
			<ul>
				<li>Psicólogo </li>
				<li>Coach</li>
				<li>Facilitador Máster</li>
				<li>Consultor</li>
				<li>Socio fundador y director general de A.V.A.I.</li>
			</ul>
		),
		activo: true,
		modal: false,
	},
	tutorial: {
		portada: PortadaVideoClass,
		url: '/tutorial/ejecutivos/inclusion',
		titulo: 'Tutorial: Líderes Heineken consolidando una cultura de Diversidad, Equidad e Inclusión',
		descripcion: 'Conoce algunos tips que te ayudarán al desarrollo de las competencias de un líder inclusivo, para que puedas seguir liderando una cultura de pertenencia fundamentada en nuestros valores HEINEKEN.',
		urlVideoTutorial: 'https://player.vimeo.com/video/864857333?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: marcellaPlata,
		experto: '',
		descripcionExperto: '',
		activo: false,
	},
	podcast: {
		portada: podcast,
		url: '/podcast/ejecutivos/inclusion',
		titulo: 'Cómo se vive la Inclusión en Helados Holanda',
		descripcion: 'Escucha a Román Rodríguez, General Manager de Helados Holanda, quien nos platica sobre su experiencia y las iniciativas que tienen para vivir la Inclusión.',
		urlPodcast: 'https://player.vimeo.com/video/864852060?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: sandraBravo,
		experto: 'Román Rodríguez ',
		descripcionExperto: '',
		activo: true,
	},
	playbook: {
		portada: playbook,
		url: '',
		titulo: 'Guía del líder inclusivo',
		descripcion: '',
		urlPlaybook: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2Fplaybook-inclusion-ejecutivos.pdf?alt=media&token=739f6d0f-1211-4126-b8dc-8fcd5f786301',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
		activo: true,
	},
	evaluacion: {
		portada: evaluacion,
		url: '/evaluacion/ejecutivos/inclusion',
		titulo: 'Evaluación',
		descripcion: '',
		urlVideoMasterClass: '',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
	},
};

const jefaturasyCoordinadores = {
	videoIntoduccion: 'https://player.vimeo.com/video/864858697?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
	masterClass: {
		portada: PortadaMasterClassJefatura,
		url: '/master-class/jefaturas-y-coordinaciones/inclusion',
		titulo: 'Claves para un Liderazgo inclusivo',
		descripcion: 'Descubre lo que podemos ganar como organización al incorporar la Diversidad, Equidad e Inclusión en nuestro día a día y encuentra consejos para llevarlo a cabo.',
		urlVideoMasterClass: 'https://player.vimeo.com/video/864858761?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: ShirleySaenz,
		experto: 'Shirley Saenz',
		descripcionExperto: (
			<>
				<ul>
					<li>CEO</li>
					<li>Experta en Interculturalidad, Diversidad, Equidad e Inclusión</li>
					<li>Conferencista internacional</li>
				</ul>
			</>
		),
		activo: true,
		modal: false,
	},
	tutorial: {
		portada: PortadaVideoClassJefatura,
		url: '/tutorial/jefaturas-y-coordinaciones/inclusion',
		titulo: 'Tutorial: Líderes Heineken consolidando una cultura de Diversidad, Equidad e Inclusión',
		descripcion: 'Conoce algunos tips que te ayudarán al desarrollo de las competencias de un líder inclusivo, para que puedas seguir liderando una cultura de pertenencia fundamentada en nuestros valores HEINEKEN.',
		urlVideoTutorial: 'https://player.vimeo.com/video/864856426?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
		activo: true,
	},
	podcast: {
		portada: podcastJefatura,
		url: '/podcast/jefaturas-y-coordinaciones/inclusion',
		titulo: 'Ser inclusivos: una forma de vida',
		descripcion: 'Escucha cómo fomentar un entorno inclusivo y la manera de hacerlo posible en el día a día desde la visión de nuestros líderes HEINEKEN.',
		urlPodcast: 'https://player.vimeo.com/video/864664610?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
		imgExperto: '',
		experto: 'Líderes HEINEKEN',
		descripcionExperto: '',
		activo: true,
	},
	playbook: {
		portada: playbookJefatura,
		url: '',
		titulo: 'Guía del líder inclusivo',
		descripcion: '',
		urlPlaybook: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2Fplaybook-inclusion-jefaturas-y-coordinaciones.pdf?alt=media&token=a14ad7b9-1a41-4391-8fa9-0ced6a85cddc',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
		activo: true,
	},
	evaluacion: {
		portada: evaluacionJefatura,
		url: '/evaluacion/jefaturas-y-coordinaciones/inclusion',
		titulo: 'Evaluación',
		descripcion: '',
		urlVideoMasterClass: '',
		imgExperto: '',
		experto: '',
		descripcionExperto: '',
	},
};

export { ejectutivos, jefaturasyCoordinadores };
