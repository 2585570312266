import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import { ejectutivos, jefaturasyCoordinadores } from '../../informacion'
import romanRodriguez from '../../../../../../resources/images/pilares/inclucion/roman-rodriguez.svg';
import paulinaBerumen from '../../../../../../resources/images/pilares/inclucion/paulina-berumen.svg';
import amariizGarcia from '../../../../../../resources/images/pilares/inclucion/amariiz-garcia.svg';
import gerardoNaranjo from '../../../../../../resources/images/pilares/inclucion/gerardo-naranjo.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30 mt30">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      {params.id === 'ejecutivos' && <>
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={8} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={romanRodriguez} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Román Rodríguez</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>General Manager en Latinoamérica para Helados Holanda (Unilever)</li>
                          <li>Experto en Negociación, Distribución en Ventas, Gestión de Marketing, Planificación Comercial, Finanzas y Equipos de Alto Rendimiento</li>
                          <li>Contador Público por la UNAM, enfocado en Auditoría Operacional</li>
                          <li>Coach</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}
      {params.id === 'jefaturas-y-coordinaciones' && <>
        <Container maxWidth="lg" style={{marginBottom: '60px'}}>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={4} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={paulinaBerumen} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Paulina Berumen</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>HRBP para Finanzas HMEX</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item lg={4} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={amariizGarcia} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Amariliz García</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Directora de Planta Toluca HMEX</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
            <Grid item lg={4} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={gerardoNaranjo} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Gerardo Naranjo</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Vicepresidente de LYSC HMEX</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div style={{ background: '#F8F7F7', padding: '40px 0',}}>
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <div className="titulos">Podcast: {ejectutivos.podcast.titulo}</div>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <p>{ejectutivos.podcast.descripcion}</p>
            </Grid>
              <Grid item lg={8} md={12} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src={ejectutivos.podcast.urlPodcast} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Container maxWidth="lg" className="mt30">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={8} md={12} xs={12}>
              <div className="titulos">Acerca del experto</div>
            </Grid>
            <Grid item lg={8} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={romanRodriguez} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Román Rodríguez</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>General Manager en Latinoamérica para Helados Holanda (Unilever)</li>
                          <li>Experto en Negociación, Distribución en Ventas, Gestión de Marketing, Planificación Comercial, Finanzas y Equipos de Alto Rendimiento</li>
                          <li>Contador Público por la UNAM, enfocado en Auditoría Operacional</li>
                          <li>Coach</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </>}

    </>
  );
}
