import React, { useEffect, useState } from 'react';
import { IconButton, Alert, AlertTitle, Stack, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getCollectionsComplete } from '../../resources/js/functions';
import './styles.scss';

export default function Alertas() {
  const [msgPerfil, setMsgPerfil] = useState(false);

  useEffect(() => {
    getDatosCompletosUser();
  }, []);

  const getDatosCompletosUser = async () => {
    const usuario = localStorage.getItem('6UIF_ZOFXc');
    const data = await getCollectionsComplete(`users/${usuario}/selfie`);
    const valData = Object.keys(data).length;
    if (valData === 0) {
      setMsgPerfil(true);
    }
  };

  const toggleAlert = () => {
    if (msgPerfil) {
      document.querySelector('.alerta').classList.add('salida');
      setTimeout(() => {
        setMsgPerfil(false);
      }, 1000);
    }
  };

  return (
    <Stack className='alerta' sx={{ width: '100%' }} spacing={2}>
      {msgPerfil && (
        <Alert
          severity='warning'
          className='shake-animation'
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                toggleAlert();
              }}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
        >
          <AlertTitle>
            AVISO
          </AlertTitle>
          Recuerda descargar el <strong>Playbook</strong> para que tu experiencia y tu aprendizaje sean completos.
        </Alert>
      )}
    </Stack>
  );
}
