import React from 'react';
import { Grid, Container } from "@material-ui/core";
import Img1 from "../../../../../resources/images/broshure/seccion8/img1.svg";

export default function Seccion8() {

    return <>
        <div id="seccion8">
            <Container maxWidth="lg" >
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={10} lg={10} className='wow slideInRight' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                        <div className="center">
                            <img src={Img1} alt="img" />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </>
}