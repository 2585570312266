
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Container, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@material-ui/core";
import uniqid from "uniqid";
import { setData, getDate, top } from "../../../../../../../resources/js/functions";
import Aprobado from '../../Aprobado'
import Reprobado from '../../Reprobrado'

export default function Ejecutivos(props) {
    const { activeView } = props;
    const params = useParams();
    const [reprobado, setReprobado] = useState(false);
    const [aprobado, setAprobado] = useState(false);
    const [preguntas, setPreguntas] = useState(['', '', '', '', '', '', '', '', '', '', '', '']);
    const [respuestas, setRespuestas] = useState(['b', 'a', 'c', 'b', 'c', 'b', 'c', 'b', 'a', 'c', 'a', 'a']);
    const [seccion1, setSeccion1] = useState([0, 0, 0, 0]);
    const [seccion2, setSeccion2] = useState([0, 0, 0, 0]);
    const [seccion3, setSeccion3] = useState([0, 0, 0, 0]);
    const [calif, setCalif] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        top()
    }, [])

    const registrar = async () => {
        if (Object.values(preguntas).length < 9) {
            Swal.fire({
                title: `¡Error!`,
                text: `Existen preguntas sin responder`,
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#f38f04",
                cancelButtonColor: "#e10613",
                icon: "error",
            });
        } else {
            let id = uniqid();
            let puntos = 0;
            const json = {
                ...preguntas,
                id,
                fechaDeRegistro: getDate(),
                user: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')
            };

            Object.values(preguntas).map((val, index) => {
                if (preguntas[index] === respuestas[index]) {
                    puntos = puntos + 1;
                }
            });
            setCalif(puntos);
            if (puntos > 7) {
                setData("encuestaCertificadoGestion", id, json).then(() => {
                    setAprobado(true);
                });
            } else {
                setReprobado(true);
            }
        }
    };

    const set = (val, id) => {
        switch (id) {
            case 0:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[0] = 1;
                }
                break;
            case 1:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[1] = 1;
                }
                break;
            case 2:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[2] = 1;
                }
                break;
            case 3:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[3] = 1;
                }
                break;
            case 4:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[0] = 1;
                }
                break;
            case 5:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[1] = 1;
                }
                break;
            case 6:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[2] = 1;
                }
                break;
            case 7:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[3] = 1;
                }
                break;
            case 8:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[0] = 1;
                }
                break;
            case 9:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[1] = 1;
                }
                break;
            case 10:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[2] = 1;
                }
                break;
            case 11:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[3] = 1;
                }
                break;

            default:
                break;
        }
        setSeccion1([...seccion1]);
        setSeccion2([...seccion2]);
        setSeccion3([...seccion3]);
    }

    return <>
        {(!reprobado && !aprobado) && <>
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <div className="cursor" onClick={() => navigate('/pilares')} style={{ color: '#2D6DB4' }}>{'< Regresar'}</div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="titulos">Instrucciones: Lee con atención cada pregunta o afirmación y selecciona la respuesta correcta.</div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuál es una de nuestras principales creencias sobre la gestión de talento? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 0)} value="Los perfiles con mayor potencial son los únicos en recibir un pan de carrera." control={<Radio />} label="Los perfiles con mayor potencial son los únicos en recibir un pan de carrera." />
                                <FormControlLabel onChange={() => set('b', 0)} value="La igualdad de oportunidades y valoración del talento por igual." control={<Radio />} label="La igualdad de oportunidades y valoración del talento por igual." />
                                <FormControlLabel onChange={() => set('c', 0)} value="Las oportunidades se brindan por antigüedad en la empresa." control={<Radio />} label="Las oportunidades se brindan por antigüedad en la empresa." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuál es una clave para el crecimiento personal y profesional según nuestras creencias sobre el aprendizaje continuo?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 1)} value="a.	La responsabilidad para mejorar es conjunta." control={<Radio />} label="a.	La responsabilidad para mejorar es conjunta." />
                                <FormControlLabel onChange={() => set('b', 1)} value="b.	El conocimiento especializado en un área particular." control={<Radio />} label="b.	El conocimiento especializado en un área particular." />
                                <FormControlLabel onChange={() => set('c', 1)} value="a.	El trabajo duro y la dedicación incansable" control={<Radio />} label="a.	El trabajo duro y la dedicación incansable" />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Qué enfoque debemos tener al brindar oportunidades para impulsar las carreras de nuestra gente?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 2)} value="Un enfoque impulsivo y arriesgado." control={<Radio />} label="Un enfoque impulsivo y arriesgado." />
                                <FormControlLabel onChange={() => set('b', 2)} value="Uno que tienda al crecimiento por antigüedad." control={<Radio />} label="Uno que tienda al crecimiento por antigüedad." />
                                <FormControlLabel onChange={() => set('c', 2)} value="Uno que fomente el equilibrio entre el corazón y la cabeza." control={<Radio />} label="Uno que fomente el equilibrio entre el corazón y la cabeza." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuál es uno de los principios clave para ganar juntos en HEINEKEN?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 3)} value="Una cultura basada en la competencia personal." control={<Radio />} label="Una cultura basada en la competencia personal." />
                                <FormControlLabel onChange={() => set('b', 3)} value="El liderazgo inspirador." control={<Radio />} label="El liderazgo inspirador." />
                                <FormControlLabel onChange={() => set('c', 3)} value="Un enfoque en la recompensa individual." control={<Radio />} label="Un enfoque en la recompensa individual." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Qué aspectos se tienen en cuenta para el seguimiento del desempeño?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 4)} value="Solo cuenta el resultado final." control={<Radio />} label="Solo cuenta el resultado final." />
                                <FormControlLabel onChange={() => set('b', 4)} value="Lo importante es la forma en que se alcanzan los resultados." control={<Radio />} label="Lo importante es la forma en que se alcanzan los resultados." />
                                <FormControlLabel onChange={() => set('c', 4)} value="Se consideran en igual medida el “qué” y el “cómo” para alcanzar los resultados." control={<Radio />} label="Se consideran en igual medida el “qué” y el “cómo” para alcanzar los resultados." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">Dentro de nuestro Modelo de Potencial ¿Qué implica el “Impulso para ganar”?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 5)} value="Estar satisfecho con la forma en que hacemos las cosas y trabajar de manera constante." control={<Radio />} label="Estar satisfecho con la forma en que hacemos las cosas y trabajar de manera constante." />
                                <FormControlLabel onChange={() => set('b', 5)} value="Tener una visión clara y decidida de lo que queremos alcanzar y estar dispuestos a salir de nuestra zona de confort." control={<Radio />} label="Tener una visión clara y decidida de lo que queremos alcanzar y estar dispuestos a salir de nuestra zona de confort." />
                                <FormControlLabel onChange={() => set('c', 5)} value="Esperar a que otros tomen decisiones y lideren el camino." control={<Radio />} label="Esperar a que otros tomen decisiones y lideren el camino." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">Dentro de nuestro Modelo de Potencial ¿Qué implica el “Inspirar y comprometerse"?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 6)} value="Entenderse solo con personas similares a uno mismo. " control={<Radio />} label="Entenderse solo con personas similares a uno mismo. " />
                                <FormControlLabel onChange={() => set('b', 6)} value="Adaptarse a situaciones que no requieren autenticidad. " control={<Radio />} label="Adaptarse a situaciones que no requieren autenticidad. " />
                                <FormControlLabel onChange={() => set('c', 6)} value="Aprovechar nuestra propia individualidad y autenticidad para conectar e influir." control={<Radio />} label="Aprovechar nuestra propia individualidad y autenticidad para conectar e influir." />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">Dentro de nuestro Modelo de Potencial ¿Qué implica el “Tener curiosidad”?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 7)} value="Limitarse a los marcos de referencia existentes." control={<Radio />} label="Limitarse a los marcos de referencia existentes." />
                                <FormControlLabel onChange={() => set('b', 7)} value="Aprender y adaptarse a nuevos entornos." control={<Radio />} label="Aprender y adaptarse a nuevos entornos." />
                                <FormControlLabel onChange={() => set('c', 7)} value="Mantener una visión tradicional y limitada." control={<Radio />} label="Mantener una visión tradicional y limitada." />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuál es el principal objetivo de tener conversaciones de carrera con los miembros de tu equipo?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 8)} value="Apoyar su crecimiento y desarrollo profesional alineado con los objetivos y necesidades de HEINEKEN." control={<Radio />} label="Apoyar su crecimiento y desarrollo profesional alineado con los objetivos y necesidades de HEINEKEN." />
                                <FormControlLabel onChange={() => set('b', 8)} value="Resolver sus problemas laborales y resolver posibles conflictos." control={<Radio />} label="Resolver sus problemas laborales y resolver posibles conflictos." />
                                <FormControlLabel onChange={() => set('c', 8)} value="Evaluar su rendimiento actual y tomar medidas disciplinarias de ser necesario." control={<Radio />} label="Evaluar su rendimiento actual y tomar medidas disciplinarias de ser necesario." />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿En qué momento de la conversación sobre su carrera es cuando debes verificar si sus expectativas están alineadas con los objetivos y necesidades de HEINEKEN?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 9)} value="En el paso 5 “Acuerden el camino a seguir”" control={<Radio />} label="En el paso 5 “Acuerden el camino a seguir”" />
                                <FormControlLabel onChange={() => set('b', 9)} value="En el paso 2 “Reconoce su individualidad”" control={<Radio />} label="En el paso 2 “Reconoce su individualidad”" />
                                <FormControlLabel onChange={() => set('c', 9)} value="En el paso 3 “Alinea sus aspiraciones” " control={<Radio />} label="En el paso 3 “Alinea sus aspiraciones” " />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">Durante el paso 4 “Evaluar sus capacidades”, ¿En qué te debes enfocar? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 9)} value="En definir objetivos claros sobre sus fortalezas, áreas de mejora y habilidades a desarrollar." control={<Radio />} label="En definir objetivos claros sobre sus fortalezas, áreas de mejora y habilidades a desarrollar." />
                                <FormControlLabel onChange={() => set('b', 9)} value="En generar confianza reconociendo su aportación al equipo." control={<Radio />} label="En generar confianza reconociendo su aportación al equipo." />
                                <FormControlLabel onChange={() => set('c', 9)} value="En generar expectativas sobre futuros roles y enfocarnos en el crecimiento “orgánico”." control={<Radio />} label="En generar expectativas sobre futuros roles y enfocarnos en el crecimiento “orgánico”." />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">Es necesario que la conversación y el plan de carrera sea personalizado y acorde a la perspectiva de carrera que tiene cada individuo.</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 9)} value="Verdadero" control={<Radio />} label="Verdadero" />
                                <FormControlLabel onChange={() => set('b', 9)} value="Falso" control={<Radio />} label="Falso" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Button className="btn blanco" onClick={registrar}>
                            Enviar respuestas
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>}
        {reprobado && <Reprobado seccion1={seccion1} seccion2={seccion2} seccion3={seccion3} perfil={params.perfil} calif={calif} />}
        {aprobado && <Aprobado seccion1={seccion1} seccion2={seccion2} seccion3={seccion3} perfil={params.perfil} activeView={activeView} calif={calif} />}
    </>
}