const questionario = {
	pregunta1: {
		pregunta: 'Si a una persona se le dificulta más una tarea que a otra, acércate y averigua como la puedes ayudar para que no se quede atrás.',
		opcionA: 'Encuentra un terreno común con otros',
		opcionB: 'Comunícate de forma incluyente',
		opcionC: 'Proporciona igualdad de oportunidades ',
	},
	pregunta2: {
		pregunta:
			'Acepta abiertamente cuando no tengas toda la información y busca construir con tu equipo desde las fortalezas, experiencias y conocimientos que tienen en conjunto.',
		opcionA: 'Sé transparente',
		opcionB: 'Hazlo Seguro para los demás',
		opcionC: 'Comunícate de forma incluyente',
	},
	pregunta3: {
		pregunta: 'Permite que tus colaboradores opinen sobre las cargas de trabajo y las tareas que van a realizar',
		opcionA: 'Hazlo Seguro para los demás',
		opcionB: 'Planea, ejecuta y logra los entregables en equipo',
		opcionC: 'Encuentra un terreno común con otros',
	},
	pregunta4: {
		pregunta: 'Conoce a tus colaboradores directos y conversa para identificar sus fortalezas, necesidades y aspiraciones.',
		opcionA: 'Desarrolla autoconciencia',
		opcionB: 'Encuentra un terreno común con otros',
		opcionC: 'Planea, ejecuta y logra los entregables en equipo',
	},
	pregunta5: {
		pregunta: 'Escucha a todas las partes que puedan estar involucradas en un conflicto.',
		opcionA: 'Busca múltiples puntos de vista',
		opcionB: 'Desarrolla autoconciencia',
		opcionC: 'Sé transparente',
	},
	pregunta6: {
		pregunta: 'Promueve que en los equipos haya diversidad de ideas, creencias o formación para generar resultados diferentes.',
		opcionA: 'Planea, ejecuta y logra los entregables en equipo',
		opcionB: 'Desarrolla autoconciencia',
		opcionC: 'Encuentra un terreno común con otros',
	},
	pregunta7: {
		pregunta: 'Frena cualquier comunicación violenta desde el momento en que ocurra.',
		opcionA: 'Sé transparente',
		opcionB: 'Proporciona igualdad de oportunidades',
		opcionC: 'Hazlo Seguro para los demás',
	},
	pregunta8: {
		pregunta: 'Aprende de tus errores, acéptalos y corrígelos para crear nuevas oportunidades de conectar con las personas',
		opcionA: 'Comunícate de forma incluyente',
		opcionB: 'Desarrolla autoconciencia',
		opcionC: 'Sé transparente',
	},
	pregunta9: {
		pregunta:
			'Busca la forma más conveniente y ágil de comunicarte con tus colaboradores, siempre respetando sus horarios, contexto y tecnología disponible.',
		opcionA: 'Busca múltiples puntos de vista',
		opcionB: 'Encuentra un terreno común con otros',
		opcionC: 'Comunícate de forma incluyente',
	},
	pregunta10: {
		pregunta: 'Destina espacios para hablar con tus colaboradores 1 a 1, donde puedas escuchar sus necesidades y opiniones.',
		opcionA: 'Otorga y recibe retroalimentación',
		opcionB: 'Hazlo Seguro para los demás',
		opcionC: 'Sé transparente',
	},
	pregunta11: {
		pregunta: 'Analiza en cuáles situaciones puedes necesitar de alguien más para avanzar y reconoce cuando una situación te rebasa.',
		opcionA: 'Hazlo Seguro para los demás',
		opcionB: 'Busca múltiples puntos de vista',
		opcionC: 'Desarrolla autoconciencia',
	},
	pregunta12: {
		pregunta:
			'Pregunta a tus colaboradores sobre la ejecución de sus tareas para asegurarte de que cuentan con los recursos necesarios para realizarlas.',
		opcionA: 'Encuentra un terreno común con otros',
		opcionB: 'Proporciona igualdad de oportunidades',
		opcionC: 'Comunícate de forma incluyente',
	},
};

export default questionario;
