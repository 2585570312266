import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import carlos from '../../../../../../resources/images/pilares/gestion/carlos-adrian.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Container maxWidth="lg" className="mb30">
        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
          <Grid item lg={5} md={12} xs={12} >
            <div className="full">
              <div className="name-conferencista full ">
                <div >
                  <img className="img-conferencista" src={carlos} alt="img" />
                </div>
                <span className="tendencias bold" style={{ display: "inline-block" }}>
                  <span className="job-conferencista">
                    <span className="name">Carlos Adrián García</span>
                    <span className="description-conferencista">
                      <ul>
                        <li>Functional Learning & Capability Manager</li>
                      </ul>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
