import React, { useEffect, useState } from 'react';
import { updateData, getDate } from '../../../../resources/js/functions';
import ValidateUser from '../../../../components/ValidateUser';
import { Grid, Container } from '@material-ui/core';
import Bienestar from './Bienestar';
import Inclusion from './Inclusion';
import Gestion from './Gestion';
import Comportamientos from './Comportamientos';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import btn1 from '../../../../resources/images/broshure/seccion11/btn1-mobile.svg';
import btn2 from '../../../../resources/images/broshure/seccion11/btn2-mobile.svg';
import btn3 from '../../../../resources/images/broshure/seccion11/btn3-mobile.svg';
import btn4 from '../../../../resources/images/broshure/seccion11/btn4-mobile.svg';
import scroll from '../../../../resources/images/scroll-down.gif';
import likeOn from '../../../../resources/images/pilares/like-on.svg';
import dislikeOn from '../../../../resources/images/pilares/dislike-on.svg';
import heiLife from '../../../../resources/images/home/hei-life.svg';
import descarga from '../../../../resources/images/home/descarga.svg';

import './styles.scss';

export default function Pilares() {
	const [pilar, setPilar] = useState(1);
	const [btnBienestar, setBtnBienestar] = useState(true);
	const [btnInclusion, setBtnInclusion] = useState(false);
	const [btnGestion, setBtnGestion] = useState(false);
	const [btnComportamientos, setBtnComportamientos] = useState(false);

	useEffect(() => {
		// top();
		setPilar(parseInt(localStorage.getItem('pilar')) || 1);
	}, []);

	const cualBoton = (id) => {
		setPilar(id);
		localStorage.setItem('pilar', id);
		switch (id) {
			case 1:
				setBtnBienestar(true);
				setBtnInclusion(false);
				setBtnGestion(false);
				setBtnComportamientos(false);
				break;
			case 2:
				setBtnBienestar(false);
				setBtnInclusion(true);
				setBtnGestion(false);
				setBtnComportamientos(false);
				break;
			case 3:
				setBtnBienestar(false);
				setBtnInclusion(false);
				setBtnGestion(true);
				setBtnComportamientos(false);
				break;
			case 4:
				setBtnBienestar(false);
				setBtnInclusion(false);
				setBtnGestion(false);
				setBtnComportamientos(true);
				break;

			default:
				break;
		}
	};

	const acctualizarBroshure = async () => {
		updateData('users', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { playBookHome: true, fechaPlaybookHome: getDate() });
		window.open('https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/DG_WB_Kickoff_People%20Leader_VF_270722.pdf?alt=media&token=4d5d6a80-342a-44c0-9d90-af89df64cf5b', '_blank');
	};

	return (
		<ValidateUser>
			{/* <Header /> */}
			<div id='pilares'>
				<div className='titulo-pilares'>
					<Container maxWidth='lg'>
						<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
							<Grid item xs={12} sm={12} md={6} lg={6} className='wow zoomInLeft center' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
								Descarga el Playbook del Programa
							</Grid>
							<Grid item xs={12} sm={12} md={2} lg={2} className='wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
								<img src={descarga} alt='scroll' className='scroll-gif btn' onClick={() => acctualizarBroshure()} />
							</Grid>
						</Grid>
						<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
							<Grid item xs={12} sm={12} md={1} lg={1} className='wow zoomInLeft center' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
								<img src={scroll} alt='scroll' className='scroll-gif' />
							</Grid>
							<Grid item xs={12} sm={12} md={9} lg={9} className='wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
								Da clic en cada pilar para ver los contenidos.
								<br />
								Recuerda visualizar todos los recursos digitales para obtener tu constancia.
							</Grid>
						</Grid>
					</Container>
				</div>
				<Container maxWidth='lg'>
					<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item xs={3} sm={3} md={3} lg={3} className='center wow zoomIn' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
							<div className='container-btns' onClick={() => cualBoton(1)}>
								<img src={btn1} alt='btn' className={`btn-pilar cursor ${pilar === 1 && 'border'}`} />
							</div>
						</Grid>
						<Grid item xs={3} sm={3} md={3} lg={3} className='center wow zoomIn' data-wow-delay='.4s' data-wow-offset='100' data-wow-duration='.5s'>
							<div className='container-btns' onClick={() => cualBoton(2)}>
								<img src={btn2} alt='btn' className={`btn-pilar cursor ${pilar === 2 && 'border'}`} />
							</div>
						</Grid>
						<Grid item xs={3} sm={3} md={3} lg={3} className='center wow zoomIn' data-wow-delay='.6s' data-wow-offset='100' data-wow-duration='.5s'>
							<div className='container-btns' onClick={() => cualBoton(3)}>
								<img src={btn3} alt='btn' className={`btn-pilar cursor ${pilar === 3 && 'border'}`} />
							</div>
						</Grid>
						<Grid item xs={3} sm={3} md={3} lg={3} className='center wow zoomIn' data-wow-delay='.8s' data-wow-offset='100' data-wow-duration='.5s'>
							<div className='container-btns' onClick={() => cualBoton(4)}>
								<img src={btn4} alt='btn' className={`btn-pilar cursor ${pilar === 4 && 'border'}`} />
							</div>
						</Grid>
					</Grid>
				</Container>
				{pilar === 1 && <Bienestar />}
				{pilar === 2 && <Inclusion />}
				{pilar === 3 && <Gestion />}
				{pilar === 4 && <Comportamientos />}
			</div>
			{/* <Footer /> */}
		</ValidateUser>
	);
}
