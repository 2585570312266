const questionario = {
	pregunta1: {
		pregunta: '1.	¿Cuál es la razón principal de un líder?',
		opcionA: 'a.	Delega tareas y responsabilidades, verifica que se lleven a cabo',
		opcionB: 'b.	Está al servicio de su equipo, colegas y de sí mismo, para lograr los resultados',
		opcionC: 'c.	Desarrolla equipos de trabajo que cumplen con horarios y responsabilidades',
	},
	pregunta2: {
		pregunta: '2.	¿Qué acciones realiza un líder Oyente?',
		opcionA: 'a.	Escucha solo las buenas prácticas para buscar los mejores resultados',
		opcionB: 'b.	Está presente desde su escucha activa y trabaja en sus juicios y creencias para conectar con las personas',
		opcionC: 'c.	Escuchar al otro, sin olvidar mis juicios y barreras, ya que estas me definen como persona',
	},
	pregunta3: {
		pregunta: '3.	¿Cuáles son las ventajas de los líderes que conectan?',
		opcionA: 'a.	Promueven confianza, comunicación y cercanía con todo el equipo ',
		opcionB: 'b.	Construyen buenas relaciones y demuestra a las demás áreas como mejorar en sus tareas',
		opcionC: 'c.	Conecta con personas afines y demuestra interés en ellas',
	},
	pregunta4: {
		pregunta: '4.	¿Cuáles son las características de un líder cultivador?',
		opcionA: 'a.	Es un líder que no permite errores, ya que impactan negativamente a todo el equipo',
		opcionB: 'b.	Impulsa el desarrollo, crecimiento y desempeño de todo el equipo, incorpora prácticas y estimula el aprendizaje continuo',
		opcionC: 'c.	Se apega a lo que espera la compañía y no da pie a reestructurar las prácticas establecidas',
	},
	pregunta5: {
		pregunta: '5.	¿Qué es un riesgo calculado?',
		opcionA: 'a.	Es una metodología que no tenemos tolera errores',
		opcionB: 'b.	Es una práctica que permite generar espacios de aprendizaje, promueve el diálogo e impulsa los resultados del equipo',
		opcionC: 'c.	Es una práctica para empoderar al equipo, que permite la equivocación continua',
	},
	pregunta6: {
		pregunta: '6.	¿Por qué un líder ejecutivo, debe pensar con inteligencia emocional?',
		opcionA: 'a.	Porque puede reconocer el contexto y toma decisiones que permiten ayudar a otros y la comunidad',
		opcionB: 'b.	Porque deben de ser más importantes las emociones que los resultados',
		opcionC: 'c.	Porque generan acuerdos y compromisos que incrementan los resultados de la compañía ',
	},
	pregunta7: {
		pregunta: '7.	¿Cómo puede un líder incorporar las competencias de adaptabilidad a su gestión?',
		opcionA: 'a.	Creciendo en indicadores año con año',
		opcionB: 'b.	Incorpora nuevos talentos que ayudan a lograr más resultados con menos recursos',
		opcionC: 'c.	Redefine acciones y estrategias alineadas al contexto del mercado, considera todos los eventos históricos que vivimos',
	},
	pregunta8: {
		pregunta: '8.	¿Cómo impacta un líder a su equipo?',
		opcionA: 'a.	Impulsa el desarrollo dentro de la compañía ',
		opcionB: 'b.	Reconoce las habilidades profesionales de su equipo',
		opcionC: 'c.	Reconoce las habilidades como personas y promueve un equilibrio constante entre trabajo y vida ',
	},
	pregunta9: {
		pregunta: '9.	 ¿Cómo conecta un líder con personas que tienen diferente opinión?',
		opcionA: 'a.	Limita su interacción con las personas que no es afín',
		opcionB: 'b.	Crean un lugar para trabajar y promueve el sentido de propósito común',
		opcionC: 'c.	Acepta las diferencias y debate constantemente su postura, sobre las demás ',
	},
	pregunta10: {
		pregunta: '10.	¿Cuál es el propósito de un líder?',
		opcionA: 'a.	Brinda calidad y calma ante los desafíos, estimula a los colaboradores y alinea el propósito individual con el de la organización',
		opcionB: 'b.	Dirige a un equipo, indica como espera que se hagan las cosas ',
		opcionC: 'c.	Vigila que los elementos del equipo cumplan las responsabilidades asignadas',
	},
};

export default questionario;
