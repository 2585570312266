import React, { useState } from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import Modal from '../../../../../../components/Modal';
import bgExpertos from '../../../../../../resources/images/broshure/seccion11/inclusion/bg-expertos-inclusion.svg';
import cuadro1 from '../../../../../../resources/images/broshure/seccion11/inclusion/cuadro1.svg'
import cuadro2 from '../../../../../../resources/images/broshure/seccion11/inclusion/cuadro2.svg'
import cuadro1Texto from '../../../../../../resources/images/broshure/seccion11/inclusion/cuadro1Texto.svg'
import cuadro2Texto from '../../../../../../resources/images/broshure/seccion11/inclusion/cuadro2Texto.svg'
import popupContenido from '../../../../../../resources/images/broshure/seccion11/inclusion/popup-inclusion.svg'
import imgContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/img-contenido.svg'
import textoContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/texto-contenido.svg'
import btnContenido from '../../../../../../resources/images/broshure/seccion11/bienestar/btn-contenido.svg'
import imgExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/img-expertos.svg'
import textoExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/texto-expertos.svg'
import btnExpertos from '../../../../../../resources/images/broshure/seccion11/bienestar/btn-expertos.svg'
import masterClass from '../../../../../../resources/images/broshure/seccion11/inclusion/masterclass-inclusion.svg'
import videoTutorial from '../../../../../../resources/images/broshure/seccion11/inclusion/video-tutorial-inclusion.svg'
import Tutorial from '../../../../../../resources/images/broshure/seccion11/inclusion/tutorial-inclusion.svg'
// expertos
import Linkeding from '../../../../../../resources/images/broshure/seccion11/bienestar/linkeding.svg';
// shirley saenz
import portadaShirleySaenz from '../../../../../../resources/images/broshure/seccion11/inclusion/shirley-saenz.svg';
import nombreShirleySaenz from '../../../../../../resources/images/broshure/seccion11/inclusion/nombre-shirley-saenz.svg';
import descripcionShirleySaenz from '../../../../../../resources/images/broshure/seccion11/inclusion/descripcion-shirley-saenz.svg';
// Santiago Klappenbach
import portadaSantiagoKlappenbach from '../../../../../../resources/images/broshure/seccion11/inclusion/santiago-klappenbach.svg';
import nombreSantiagoKlappenbach from '../../../../../../resources/images/broshure/seccion11/inclusion/nombre-santiago-klappenbach.svg';
import descripcionSantiagoKlappenbach from '../../../../../../resources/images/broshure/seccion11/inclusion/descripcion-santiago-klappenbach.svg';
// gabriel fernandez
import portadaGabrielFernandez from '../../../../../../resources/images/broshure/seccion11/inclusion/gabriel-fernandez.svg';
import descripcionGabrielFernandez from '../../../../../../resources/images/broshure/seccion11/inclusion/descripcion-gabriel-fernandez.svg';
// diana moreno
import portadaDianaMoreno from '../../../../../../resources/images/broshure/seccion11/inclusion/diana-morenos.svg';
import descripcionDianaMoreno from '../../../../../../resources/images/broshure/seccion11/inclusion/descripcion-diana-morenos.svg';


export default function Inclusion() {
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);

    return (
        <div id="inclusion">
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="stretch" spacing={0}>
                    <Grid item xs={12} sm={8} md={8} lg={8} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='border-c1'>
                            <img src={cuadro1} alt="img" className='full' />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} className='padre wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s' style={{ background: '#fff' }}>
                        <div className='center hijos border-c2' style={{ background: '#fff' }}>
                            <div className='cuadro-blanco'>
                                {!isMobileOnly && <img src={cuadro2} alt="img" className='cuadro2TextoInclusion' />}
                                {isMobileOnly && <p style={{ textAlign: 'left' }}>El respeto y la diversidad son fundamentales para lograr la inclusión en tu equipo. ¿Quieres saber cómo respetar, integrar y capitalizar la diversidad? En este pilar te lo diremos.</p>}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="stretch" spacing={0}>
                    <Grid item xs={12} sm={6} md={5} lg={5} className='padre border-c3 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='cuadro-blanco hijos'>
                            {!isMobileOnly && <img src={cuadro1Texto} alt="img" className='full' />}
                            {isMobileOnly && <>
                                <p>Retoma iniciativas que ya conoces y adquiere nuevos conocimientos para aprovechar la diversidad de tu equipo en todas sus expresiones, ya sea de género, pensamiento o cultura, y lograr un entorno donde la gente:</p>
                                <ul style={{ float: 'left', color: '#2D6DB4' }}>
                                    <li>Se sienta segura y respetada.</li>
                                    <li>Sea escuchada y valorada por quien es.</li>
                                    <li>Pertenezca y sus contribuciones sean consideradas.</li>
                                </ul>
                            </>}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} lg={7} className='center wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <div className='cuadro-blanco border-c4'>
                            <img src={cuadro2Texto} alt="img" className='' style={{ width: '95%' }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='center border-c5 wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgContenido} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoContenido} alt="img" /><br /><br />
                                <img className='cursor' src={btnContenido} alt="img" onClick={() => setModal(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} className='border-c6 wow zoomInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={imgExpertos} alt="img" style={{ marginRight: '20px', marginBottom: '20px' }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} className='center'>
                                <img src={textoExpertos} alt="img" /><br /><br />
                                <img className='cursor' src={btnExpertos} alt="img" onClick={() => setModal2(true)} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Modal open={modal}>
                <Container maxWidth="lg">
                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                        <div className='full' >
                            <div className='cursor btn-cerrar-popup' onClick={() => setModal(false)}>
                                Cerrar
                            </div>
                        </div>
                        <img src={popupContenido} alt="img" className="contenido-popup1 " />
                    </Grid>
                </Container>
            </Modal>
            <Modal open={modal2}>
                <div className='full' >
                    <div className='cursor btn-cerrar-popup' onClick={() => setModal2(false)}>
                        Cerrar
                    </div>
                </div>
                <div className='bg-expertos' style={{ backgroundImage: `url(${bgExpertos})`, backgroundSize: 'contain' }}>
                    <Container maxWidth="lg" >
                        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={0} style={{ padding: '30px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className='titulo-expertos-popup' style={{ color: '#FDC901' }}>
                                    Expertos
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="cuadro-blanco">
                                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3} style={{ padding: '30px' }}>
                                        <Grid item xs={12} sm={6} md={5} lg={5}>
                                            <div className='contenedor-experto'>
                                                <div className='relative'>
                                                    <img src={portadaSantiagoKlappenbach} alt="alt" />
                                                    <img src={masterClass} alt="alt" className='titulo' />
                                                    <img src={Linkeding} alt="alt" className='linkeding' onClick={() => window.open('http://linkedin.com/in/santiago-klappenbach-0774b81a', '_blank')} />
                                                </div>
                                                <div className='padding'>
                                                    <img src={descripcionSantiagoKlappenbach} alt="alt" className='full' />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5} lg={5}>
                                            <div className='contenedor-experto'>
                                                <div className='relative'>
                                                    <img src={portadaShirleySaenz} alt="alt" />
                                                    <img src={masterClass} alt="alt" className='titulo' />
                                                    <img src={Linkeding} alt="alt" className='linkeding' onClick={() => window.open('http://linkedin.com/in/shirleyjsaenz', '_blank')} />
                                                </div>
                                                <div className='padding'>
                                                    <img src={descripcionShirleySaenz} alt="alt" className='full' />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3} style={{ padding: '30px' }}>
                                        <Grid item xs={12} sm={6} md={5} lg={5}>
                                            <div className='contenedor-experto'>
                                                <div className='relative'>
                                                    <img src={portadaGabrielFernandez} alt="alt" />
                                                    <img src={Tutorial} alt="alt" className='titulo' />
                                                    <img src={Linkeding} alt="alt" className='linkeding' onClick={() => window.open('https://www.linkedin.com/in/gabrielfern%C3%A1ndez', '_blank')} />
                                                </div>
                                                <div className='padding'>
                                                    <img src={descripcionGabrielFernandez} alt="alt" className='full' />
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5} lg={5}>
                                            <div className='contenedor-experto'>
                                                <div className='relative'>
                                                    <img src={portadaDianaMoreno} alt="alt" />
                                                    <img src={Tutorial} alt="alt" className='titulo' />
                                                    <img src={Linkeding} alt="alt" className='linkeding' onClick={() => window.open('https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGZ3tsas2Zs0wAAAYOzl-H4A0N8zXMj5p5TtyuBgHYS2_bOvR9Y2eCc_ZNNufVsez2KM8BDs7TU4dedAdlkE6ttrbbRUeb0lKFezA9OPJXdawTkwSeGBXElZ-bmwLvhg_m_FQk=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fdianamorenorodriguez', '_blank')} />
                                                </div>
                                                <div className='padding'>
                                                    <img src={descripcionDianaMoreno} alt="alt" className='full' />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Modal>
        </div >
    )
}