import img1 from '../../../../../../resources/images/broshure/seccion7/img-carousel.svg';
import img2 from '../../../../../../resources/images/broshure/seccion7/img-carousel-2.svg';
import img3 from '../../../../../../resources/images/broshure/seccion7/img-carousel-3.svg';
import img4 from '../../../../../../resources/images/broshure/seccion7/img-carousel-4.svg';

const imagenes = [
    img1,
    img2,
    img3,
    img4,
]

export default imagenes;