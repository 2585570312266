import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ValidateUser } from "../../../components/ValidateSesion";
// import SectionWelcome from "./SectionsWelcome";
import FormLogin from "./FormLogin";
// import FormRegister from "./FormRegister";
// import FormReset from "./FormReset";
import "./styles.scss";

export default function Login() {
  const [vista, setVista] = useState(1);

  // useEffect(() => {
  //   ValidateUser();
  // }, []);

  const activeView = (id) => {
    setVista(id);
  };

  return (
    <div id="loginAdmin">
      <Grid container direction="row" alignItems="stretch" spacing={2}>
        {/* <SectionWelcome /> */}
        {vista === 1 && <FormLogin activeView={activeView} />}
        {/* {vista === 2 && <FormRegister activeView={activeView} />} */}
        {/* {vista === 3 && <FormReset activeView={activeView} />} */}
      </Grid>
    </div>
  );
}
