import React from 'react';
import { PropTypes } from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '../MenuAdmin';
import './styles.scss';

const styles = () => ({
	grow: {
		flexGrow: 1,
	},
});

const Header = (props) => {
	const { children} = props;
	return (
		<>
			{/* <Toolbar className='header'> */}
				<Menu hijos={children} />
				{/* <div className={styles.grow} /> */}
				{/* {children} */}
			{/* </Toolbar> */}
		</>
	);
};
Header.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
export default Header;
