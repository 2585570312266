import React, { useEffect, useState } from "react";
import { top } from '../../../resources/js/functions';
import { Container, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Header from "../../../components/Header";
import ValidateUser from "../../../components/ValidateUser";
import Footer from "../../../components/Footer";
import SectionWelcome from "./SectionWelcome";
// import Modal from "../../../components/Modal";
import SectionConferencesToday from "./SectionConferencesToday";
import Pilares from "./Pilares";
// import BG from "../../../resources/images/general_bg.png";
import "react-alice-carousel/lib/alice-carousel.css";
import "./styles.scss";

export default function Home() {
  // const [modal, setModal] = useState(true);
  useEffect(() => {
    top();
    // localStorage.removeItem('pilar');
  }, [])

  // let backgroundStyles = `#root::after{background-image: url(${BG})};`;
  return (
    <>
      <ValidateUser>
        <Header />
        {/* <style>{backgroundStyles}</style> */}
        <div id="home">
          <SectionWelcome />
        </div>
          <SectionConferencesToday />
          <Pilares />
        <Footer />
        {/* <Modal open={modal} >
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div onClick={() => setModal(false)} className="cursor" style={{ float: 'right', color: '#fff', fontSize: '24px', fontWeight: 'bold' }}><CloseIcon /></div>
                <img src="https://firebasestorage.googleapis.com/v0/b/heineken-data-academy-connect.appspot.com/o/Banner_spiraconnect.jpg?alt=media&token=92b64ac5-f91d-44e1-8042-5025f4824a9b" alt="banner" className="full" />
              </Grid>
            </Grid>
          </Container>
        </Modal> */}
      </ValidateUser>
    </>
  );
}
