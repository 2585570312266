import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { ValidateUser } from "../../components/ValidateSesion";
import SectionWelcome from "./SectionsWelcome";
import FormLogin from "./FormLogin";
import "./styles.scss";

export default function Login() {
  const [vista, setVista] = useState(1);

  useEffect(() => {
    // ValidateUser();
  }, []);

  const activeView = (id) => {
    setVista(id);
  };

  return (
    <div id="login">
      <Grid container direction="row" alignItems="stretch" spacing={2}>
        <SectionWelcome />
        {vista === 1 && <FormLogin activeView={activeView} />}
      </Grid>
    </div>
  );
}
