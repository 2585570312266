import React from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import img1 from '../../../../../resources/images/broshure/seccion13/img1.svg';
import img1Mobile from '../../../../../resources/images/broshure/seccion13/img1-mobile.svg';

export default function Seccion13() {

    return <section id="seccion13">
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} className='center wow flash' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">
                        <img src={`${isMobileOnly ? img1Mobile : img1}`} alt="img" className='mb30 size' />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}