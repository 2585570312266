import React, { useEffect } from "react";
import { Grid, Container } from "@material-ui/core";
import ValidateUser from "../../../components/ValidateUser";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { top, quitarAcentos } from '../../../resources/js/functions';
import './styles.scss';

export default function SectionConferencetoday() {
  useEffect(() => {
    top();
    localStorage.removeItem('pilar');
  }, []);

  // contabilizar palabras
  // const text = 'hola que tal, tal que mi nombre es Jhon Doe, estoy aqui, aqui estoy en una charla informativa';
  // const text2 = 'Por supuesto existen muchas maneras de escribir tu código, unas mejores que otras, pero me limito a responder tu pregunta y decirte por qué no funciona: porque está incompleto y no gatillas/devuelves/gestionas ningún resultado/función.';

  // la clave esta en unir todas las cadenas en una sola como acontinuacion
  // let json = [
  //   text + text2,
  // ]
  // let repetidos = []

  // Object.values(json).map(val => {
  //   // const words = val.replace(/[^a-zA-Z0-9 ]/g, "").split(" ").map(w => w.toLowerCase())

  //   const sinacento = quitarAcentos(val);
  //   const words = sinacento.split(" ").map(w => w.toLowerCase());
  //   words.forEach(word => {
  //     const arrayFilter = words.filter(t => t === word)
  //     if (arrayFilter.length > 1) {
  //       if (repetidos.filter(r => r.word === word).length === 0) {
  //         repetidos.push({
  //           word,
  //           total: arrayFilter.length
  //         })
  //       }
  //     }
  //   })

  // })

  // console.log(repetidos)

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="kickoff">
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <h1>Kickoff</h1>
                <p>Mira el lanzamiento del programa que tuvimos en el auditorio Monterrey.</p>
              </Grid>
              <Grid item xs={12} sm={12} md={10} lg={10}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src="https://player.vimeo.com/video/864860702?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming"></iframe>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Footer />
      </ValidateUser>
    </>
  );
}
