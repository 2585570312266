const questionario = {
	pregunta1: {
		pregunta: '1.	¿Qué hace un líder al tener conversaciones con su equipo?',
		opcionA: 'a.	Escucha y considera su intuición, confía en su experiencia sin considerar a su equipo ',
		opcionB: 'b.	Conoce el panorama completo y se apoya en la experiencia de su equipo para tomar decisiones',
		opcionC: 'c.	Comunica a su equipo lo que espera que hagan sin que cuestionen sus indicaciones',
	},
	pregunta2: {
		pregunta: '2.	¿Qué acciones realiza un líder Oyente?',
		opcionA: 'a.	Escucha solo las buenas prácticas para buscar los mejores resultados',
		opcionB: 'b.	Está presente desde su escucha activa y trabaja en sus juicios y creencias para conectar con las personas',
		opcionC: 'c.	Escucha al otro, sin olvidar sus juicios y barreras, ya que lo definen como persona',
	},
	pregunta3: {
		pregunta: '3.	¿Cómo construye confianza un líder con su equipo?',
		opcionA: 'a.	Impulsa e involucra a su equipo y promueve la comunicación bidireccional',
		opcionB: 'b.	Delega las responsabilidades y verifica que se hagan conforme sus expectativas',
		opcionC: 'c.	Involucra a las personas con mayor experiencia y delega completamente sus responsabilidades',
	},
	pregunta4: {
		pregunta: '4.	¿Qué genera un líder comunicador con su equipo?',
		opcionA: 'a.	Brinda reglas claras y construye un plan de acción que les permita consolidar sus resultados',
		opcionB: 'b.	Solicita espacios recurrentes para retroalimentación, incluso en horarios extendidos',
		opcionC: 'c.	Se comunica constantemente por correo electrónico para formalizar los acuerdos',
	},
	pregunta5: {
		pregunta: '5.	¿Cómo conecta genuinamente un líder?',
		opcionA: 'a.	De manera presencial ya que por Teams no es posible',
		opcionB: 'b.	Está presente, hace preguntas, demuestra interés en todo momento, en la presencialidad y virtualidad ',
		opcionC: 'c.	Prioriza sus tareas y responsabilidades, busca espacios para conectar con su equipo una vez al mes ',
	},
	pregunta6: {
		pregunta: '6.	¿Para qué preparan los líderes sus conversaciones?',
		opcionA: 'a.	Para conocer el panorama completo de la situación y aportar desde su experiencia al equipo ',
		opcionB: 'b.	Para hablar de sus logros y experiencia en distintos roles de la organización',
		opcionC: 'c.	Para dialogar e influir con sus puntos de vista',
	},
	pregunta7: {
		pregunta: '7.	¿Cómo impacta profundamente un líder en su equipo?',
		opcionA: 'a.	Impulsa el desarrollo del personal dentro de la compañía ',
		opcionB: 'b.	Reconoce las habilidades profesionales de su equipo',
		opcionC: 'c.	Reconoce las habilidades personales y promueve el equilibrio constante entre trabajo y vida ',
	},
	pregunta8: {
		pregunta: '8.	¿Cómo conecta un líder con personas que tienen diferente opinión?',
		opcionA: 'a.	Limita su interacción con personas que no son afín',
		opcionB: 'b.	Crean un lugar para trabajar con y promueve el sentido de propósito común',
		opcionC: 'c.	Acepta las diferencias y debate constantemente su postura, sobre las demás ',
	},
	pregunta9: {
		pregunta: '9.	¿Cómo demuestra un líder su compromiso con el equipo? ',
		opcionA: 'a.	Realiza comentarios constructivos, admite cuando no sabe, busca respuestas con su equipo y está comprometido con su desarrollo constante',
		opcionB: 'b.	Promueve los programas de capacitación y solicita a su equipo que incorporen lo aprendido a su rutina',
		opcionC: 'c.	Envía mensajes constantes a grupos de whatsapp para solicitar estatus y demuestra interés en el equipo',
	},
	pregunta10: {
		pregunta: '10.	¿Cómo contribuye liderar con empatía?',
		opcionA: 'a.	Comprende lo que piensa el equipo y considera su sentir, y ofrece su apoyo para desarrollar constantemente sus áreas de mejora ',
		opcionB: 'b.	Aprovecha las sesiones semanales para preguntar cómo se encuentran ',
		opcionC: 'c.	Verifica los indicadores de su equipo y brinda feedback constantemente',
	},
};

export default questionario;
