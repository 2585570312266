import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Grid, Container } from "@material-ui/core";
import img1 from '../../../../../resources/images/broshure/seccion19/img1.svg';
import img1Mobile from '../../../../../resources/images/broshure/seccion19/img1-mobile.svg';


export default function Seccion19() {
    const email = 'melissa.valdez@heineken.com';
    const subject = 'The PEOPLE Leader'

    return <section id="seccion19" className='center wow fadeInUp' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
        {!isMobileOnly && <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <div className="center contenedor-correo">
                        <img src={img1} alt="img" className='full size' />
                        <div className='link' onClick={() => window.open(`mailto:${email}?subject=${subject}`, '_self')}>

                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>}
        {isMobileOnly && <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                    <div className="center contenedor-correo">
                        <img src={img1Mobile} alt="img" className='full size' />
                        <div className='link' onClick={() => window.open(`mailto:${email}?subject=${subject}`, '_self')}>

                        </div>
                    </div>
                </Grid>
            </Grid>
        </Container>}
    </section>
}

