import React, { useState, useEffect, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Container, Button, Hidden } from "@material-ui/core";
import Pdf from "react-to-pdf";
import { useScreenshot, createFileName } from 'use-react-screenshot';
import Header from '../../../../../components/HeaderDaring';
import Footer from '../../../../../components/FooterDaring';
import { getDate, top, updateData } from "../../../../../resources/js/functions";
import insignia from "../../../../../resources/images/daring/pilares/insignia-oyente.png"
import "./styles.scss";

export default function Certificate() {
  const reference = createRef(null);
  const navigate = useNavigate()
  useEffect(() => {
    top();
  }, []);

  const [image, takeScreenshot] = useScreenshot({
    type: "image/png",
    quality: 1.0
  });

  const download = (image, { name = "insignia-narrador-activador", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const getImage = async () => {
    let a = reference.current;
    setTimeout(async () => {
      await takeScreenshot(a).then(download);
      // setShow(false)
    }, 1000);
  }

  const updateDescarga = () => {
    updateData(`daring/pilarOyente`, localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw='), { descargaInsignia: true, fechaDescargaInsignia: getDate() }).then(() => {
      getImage();
    }).catch(e => console.log(e))
  }

  return (
    <div id="insignia">
      <Header />
      <Container maxWidth="lg">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>

          <Grid item lg={11} md={11} sm={12} xs={12}>
            <p className="text">
              Descarga tu insignia y consérvala. Para obtenerla en la mejor calidad posible, te recomendamos hacerlo desde un equipo de escritorio.
              Si no tienes cómo hacerlo ahora mismo, no te preocupes, siempre puedes volver a ingresar para descargar la insignia dando clic en esta sección.
            </p>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12}>
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
              <Grid item lg={10} md={6} sm={12} xs={12}>
                <div className="titulos">¡Felicidades!</div>
              </Grid>
              <Grid item lg={2} md={6} sm={12} xs={12} className='mt30'>
                <Button className="btn-azul" onClick={() => { updateDescarga() }}>
                  Descargar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={11} md={11} sm={12} xs={12} style={{ background: "#F8F7F7" }} ref={reference}>
            <div className="center full">
              <div id="imgCertificado" className="certificate">
                <img src={insignia} alt="as" className="full" />
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg" className="mt30">
        <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={3}>
          <Grid item lg={12} md={12} xs={12} className='center'>
            <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}
