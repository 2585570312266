import playbookEjecutivos from '../../../resources/descargables/agenda.pdf';
import playbookJefaturas from '../../../resources/descargables/Politicas-de-privacidad.pdf';
import CarlosSuarez from '../../../resources/images/pilares/bienestar/carlos-suarez.svg';
import AlejandraBarba from '../../../resources/images/pilares/bienestar/alejandra-barba.svg';
import sandraBravo from '../../../resources/images/pilares/bienestar/sandra-bravo.svg';
import marcellaPlata from '../../../resources/images/pilares/bienestar/marcella-plata.svg';

// info[0] = generico/ejecutivos
// info[1] = jefatura y coordinadores

const info = [
	{
		masterClass: {
			titulo: 'Reformulando mi estrés',
			experto: 'Carlos Suárez',
			url: '/master-class/ejecutivos',
			img: CarlosSuarez,
		},
		videoTutorial: {
			titulo: 'Tips para priorizar',
			experto: 'Marcella Plata',
			url: '/tutorial/ejecutivos',
			img: marcellaPlata,
		},
		podcast: {
			titulo: 'Viaje del bienestar',
			experto: 'Sandra Bravo',
			url: '/podcast/ejecutivos',
			img: sandraBravo,
		},
		playbook: {
			titulo: 'El bienestar del People Leader',
			experto: '',
			url: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2Fplaybook-ejecutivos.pdf?alt=media&token=9f40d808-c40b-4468-bd3b-5f2b4be2de14',
			img: '',
		},
		evaluacion: {
			titulo: 'Evaluación',
			experto: '',
			url: '/evaluacion/ejecutivos/bienestar',
			img: '',
		},
	},
	{
		masterClass: {
			titulo: 'Sé tu propio líder',
			experto: 'Alejandra Barba',
			url: '/master-class/jefaturas-y-coordinaciones',
			img: AlejandraBarba,
		},
		videoTutorial: {
			titulo: 'Tips para priorizar',
			experto: 'Marcella Plata',
			url: '/tutorial/jefaturas-y-coordinaciones',
			img: marcellaPlata,
		},
		podcast: {
			titulo: 'Bienestar para llevar',
			experto: 'Sandra Bravo',
			url: '/podcast/jefaturas-y-coordinaciones',
			img: sandraBravo,
		},
		playbook: {
			titulo: 'El bienestar del People Leader',
			experto: '',
			url: 'https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/descargables%2Fplaybook-jefaturas-y-coordinciones.pdf?alt=media&token=71758d78-a3d3-49c9-959b-958267d066c5',
			img: '',
		},
		evaluacion: {
			titulo: 'Evaluación',
			experto: '',
			url: '/evaluacion/jefaturas-y-coordinaciones/bienestar',
			img: '',
		},
	},
];

export default info;
