import React, { useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { Grid, Container, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal';
import video from '../../resources/videos/videoTutorial.mp4';
import Connect from '../../resources/images/footer/spira-connect.png';
import linkedin from '../../resources/images/footer/linkedin.png';
import instagram from '../../resources/images/footer/instagram.png';
import workplace from '../../resources/images/footer/workplace.svg';
import './styles.scss';

const date = new Date().getFullYear();

export default function Footer() {
	const navigate = useNavigate();
	const [modal, setModal] = useState(false);

	const logout = () => {
		const auth = getAuth();
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				window.localStorage.removeItem('0hNZeY/gjEeJihP9bIPGHw==');
				navigate('/');
			})
			.catch((error) => {
				console.log('error: ' + error);
				// An error happened.
			});
	};
	return (
		<>
			<div id='footer'>
				{localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==') !== null && (
					<Container maxWidth='lg'>
						<Grid container direction='row' alignItems='center' justifyContent='center'>
							<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '30px' }}>
								<div className='mb30'>Importante: si nos visitas desde un dispositivo Apple, recuerda que debes ir a la opción “Guardar en Archivos” para conservar los recursos descargables.</div>
								<div>
									Para Soporte Técnico, escríbenos un correo a <a href='mailto:soporte@thepeopleleadersite.com'>soporte@thepeopleleadersite.com</a> Recibirás respuesta en nuestro horario de atención de lunes a viernes de 9:00 a 18:00 h (Centro de México).
								</div>
							</Grid>
							<Grid item xs={12} sm={2} md={6} lg={4}>
								<div className='logo-connect'>
									<img src={Connect} alt='img' className='img-connect' />
								</div>
							</Grid>
							<Grid item xs={12} sm={8} md={3} lg={4} className='center'>
								<Grid container direction='row' alignItems='center' justifyContent='center'>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<div className='titulo-menu bold'>Spira Connect</div>
									</Grid>
									<ul>
										<li>
											<div className='option' onClick={() => navigate('/seleccionar-contenido')}>
												Home
											</div>
										</li>
										<li>
											<div className='option' onClick={() => navigate('/pilares')}>
												Pilares
											</div>
										</li>
										<li>
											<div className='option' onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/the-people-leader-connect.appspot.com/o/DG_WB_Kickoff_People%20Leader_VF_270722.pdf?alt=media&token=4d5d6a80-342a-44c0-9d90-af89df64cf5b', '_blank')}>
												Playbook
											</div>
										</li>
										<li>
											<div className='option' onClick={() => navigate('/kickoff')}>
												Kickoff
											</div>
										</li>
									</ul>

									<ul>
										<li>
											<div className='option' onClick={() => navigate('/testimonios')}>
												Testimonios
											</div>
										</li>
										<li>
											<div className='option' onClick={() => navigate('/conoce-mas')}>
												Conoce más
											</div>
										</li>
										<li>
											<div className='option' onClick={() => setModal(true)}>
												Cómo navegar
											</div>
										</li>
									</ul>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={2} md={3} lg={4}>
								<div className='out-sesion'>
									<Button type='submit' className='btn btn-one' onClick={logout}>
										Cerrar sesión
									</Button>
								</div>
							</Grid>
						</Grid>
					</Container>
				)}
				<div className='divider' />
				<Container maxWidth='lg'>
					<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item xs={12} sm={8} md={9} lg={9}>
							<div className='all-rights-reserved'>&copy; {`Derechos reservados, Spira ${date}`}</div>
						</Grid>
						<Grid item xs={12} sm={4} md={3} lg={3}>
							<div className='social-media'>
								<div className='follow-us padre'>
									<span className='hijos'>Síguenos en</span>
									<img src={workplace} alt='img' className='hijos cursor' onClick={() => window.open('https://work.me/g/3BHX44dXY/DAqCZJZ8', '_blank')} />
									{/* <img src={facebook} alt="img" className="hijos cursor" onClick={() => window.open("", "_blank")} /> */}
									{/* <img src={instagram} alt="img" className="hijos cursor" onClick={() => window.open("https://instagram.com/mindfulcare.mx?igshid=YmMyMTA2M2Y=", "_blank")} /> */}
								</div>
							</div>
						</Grid>
					</Grid>
				</Container>
				<Modal open={modal}>
					{/* <Container maxWidth="lg"> */}
					<Grid container direction='row' alignItems='stretch' justifyContent='center' spacing={0}>
						<div className='full'>
							<div className='cursor btn-cerrar-popup' onClick={() => setModal(false)}>
								Cerrar
							</div>
						</div>
						<video id='video1' className='element' _origeny='127' _origenx='196' src={video} controls style={{ width: '100%' }} controlsList='nodownload noplaybackrate noremoteplayback '></video>
					</Grid>
					{/* </Container> */}
				</Modal>
			</div>
		</>
	);
}
