import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Container, Grid, TextField, MenuItem, Button, Paper } from '@material-ui/core';
import uniqid from 'uniqid';
import Swal from "sweetalert2";
import Header from '../../../../components/HeaderAdmin';
import ValidateAdmin from '../../../../components/ValidateAdmin';
import { getDate, setData, searchDataByValue } from '../../../../resources/js/functions'
import './styles.scss';

export default function CrearTestimonios() {
    const [campos, setCampos] = useState({});

    const validar = async e => {
        e.preventDefault();
        let json = {}
        const id = uniqid();
        json = {
            ...campos,
            id,
            // numEmpleado: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='),
            fechaRegistro: getDate()
        };

        setData("testimonios", id, json).then(() => {
            Swal.fire({
                title: "¡Registro exitoso!",
                html: `El testimonio se registro correctamente`,
                icon: "success",
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#2D6DB4",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
        })

    }

    return <>
        <ValidateAdmin>
            <Header>
                <div id='crearTestimonios'>
                    <form onSubmit={validar}>
                        <Container maxWidth="lg">
                            <Paper elevation={3} style={{ padding: '15px' }}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <h1>Registro de testimonios</h1>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="nombre" label="Nombre" variant="outlined" onChange={(e) => setCampos({ ...campos, nombre: e.target.value })} />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <TextField required fullWidth id="puesto" label="Puesto" variant="outlined" onChange={(e) => setCampos({ ...campos, puesto: e.target.value })} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <TextField
                                            id="standard-textarea"
                                            label="Escribe el testimonio"
                                            placeholder=""
                                            multiline
                                            required
                                            fullWidth
                                            minRows={4}
                                            maxRows={4}
                                            variant="outlined"
                                            onChange={e => setCampos({ ...campos, testimonio: e.target.value })}
                                        />
                                    </Grid>
                                    {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField fullWidth id="password" InputLabelProps={{ shrink: true }} label="Contraseña" value={password} disabled variant="outlined" />
                            </Grid> */}
                                    <Grid item lg={12} md={6} sm={12} xs={12}>
                                        <div className="full center">
                                            <Button
                                                type='submit'
                                                className="btn"
                                            // style={{ color: "#fff", width: '150px', margin: 'auto' }}
                                            >
                                                Registrar
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Container>
                    </form>
                </div>
            </Header>
        </ValidateAdmin>
    </>
}