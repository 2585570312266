
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Container, Grid, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@material-ui/core";
import uniqid from "uniqid";
import { setData, getDate, top } from "../../../../../resources/js/functions";
import Aprobado from '../../Aprobado'
import Reprobado from '../../Reprobrado'

export default function Ejecutivos(props) {
    const { activeView } = props;
    const params = useParams();
    const [reprobado, setReprobado] = useState(false);
    const [aprobado, setAprobado] = useState(false);
    const [preguntas, setPreguntas] = useState(['', '', '', '', '', '', '', '', '', '']);
    const [respuestas, setRespuestas] = useState(['c', 'b', 'a', 'b', 'c', 'b', 'a', 'a', 'c', 'a']);
    const [seccion1, setSeccion1] = useState([0, 0, 0, 0]);
    const [seccion2, setSeccion2] = useState([0, 0, 0]);
    const [seccion3, setSeccion3] = useState([0, 0, 0]);
    const [calif, setCalif] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        top()
    }, [])

    const registrar = async () => {
        if (Object.values(preguntas).length < 9) {
            Swal.fire({
                title: `¡Error!`,
                text: `Existen preguntas sin responder`,
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#f38f04",
                cancelButtonColor: "#e10613",
                icon: "error",
            });
        } else {
            let id = uniqid();
            let puntos = 0;
            const json = {
                ...preguntas,
                id,
                fechaDeRegistro: getDate(),
                user: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')
            };

            Object.values(preguntas).map((val, index) => {
                if (preguntas[index] === respuestas[index]) {
                    puntos = puntos + 1;
                }
            });
            setCalif(puntos);
            if (puntos > 7) {
                setData("encuestaCertificado", id, json).then(() => {
                    setAprobado(true);
                });
            } else {
                setReprobado(true);
            }
        }
    };

    const set = (val, id) => {
        switch (id) {
            case 0:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[0] = 1;
                }
                break;
            case 1:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[1] = 1;
                }
                break;
            case 2:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[2] = 1;
                }
                break;
            case 3:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[0] = 1;
                }
                break;
            case 4:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[0] = 1;
                }
                break;
            case 5:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion1[3] = 1;
                }
                break;
            case 6:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[1] = 1;
                }
                break;
            case 7:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[1] = 1;
                }
                break;
            case 8:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion3[2] = 1;
                }
                break;
            case 9:
                preguntas[id] = val
                if (respuestas[id] === val) {
                    seccion2[2] = 1;
                }
                break;

            default:
                break;
        }
        setSeccion1([...seccion1]);
        setSeccion2([...seccion2]);
        setSeccion3([...seccion3]);
    }

    return <>
        {(!reprobado && !aprobado) && <>
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                    <Grid item lg={10} md={10} xs={12}>
                        <div className="cursor" onClick={() => navigate('/pilares')} style={{ color: '#2D6DB4' }}>{'< Regresar'}</div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="titulos">Estás a un paso de conseguir tu constancia del pilar Bienestar. <br />¡Te deseamos éxito!<br /><br /></div>
                        <div className="titulos mt30" style={{ fontSize: '22px' }}>Instrucciones: Lee con atención cada enunciado y selecciona la respuesta correcta.</div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Para qué te sirve el autoconocimiento? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 0)} value="Es útil para autoevaluar mi desempeño." control={<Radio />} label="Es útil para autoevaluar mi desempeño." />
                                <FormControlLabel onChange={() => set('b', 0)} value="Obtener una visión más trascendental de mi trabajo." control={<Radio />} label="Obtener una visión más trascendental de mi trabajo." />
                                <FormControlLabel onChange={() => set('c', 0)} value="Gestionar mejor mis emociones y tomar mejores decisiones." control={<Radio />} label="Gestionar mejor mis emociones y tomar mejores decisiones." />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Para qué te sirve encontrar un significado en lo que haces?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 1)} value="Gestionar mejor mis emociones y tomar mejores decisiones." control={<Radio />} label="Gestionar mejor mis emociones y tomar mejores decisiones." />
                                <FormControlLabel onChange={() => set('b', 1)} value="Obtener una visión más trascendental de mi trabajo." control={<Radio />} label="Obtener una visión más trascendental de mi trabajo." />
                                <FormControlLabel onChange={() => set('c', 1)} value="Evitar el burnout." control={<Radio />} label="Evitar el burnout." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Qué características individuales son valiosas para contribuir a entornos de trabajo positivos?</div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 2)} value="Autoconocimiento, empatía y resiliencia." control={<Radio />} label="Autoconocimiento, empatía y resiliencia." />
                                <FormControlLabel onChange={() => set('b', 2)} value="Compromiso y adicción a la acción." control={<Radio />} label="Compromiso y adicción a la acción." />
                                <FormControlLabel onChange={() => set('c', 2)} value="Trabajo en equipo y un estado de burnout." control={<Radio />} label="Trabajo en equipo y un estado de burnout." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cómo puedes evitar la “Adicción a la acción”? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 3)} value="Gestionando mejor mis emociones." control={<Radio />} label="Gestionando mejor mis emociones." />
                                <FormControlLabel onChange={() => set('b', 3)} value="Concentrándome en hacer una sola cosa hasta terminarla." control={<Radio />} label="Concentrándome en hacer una sola cosa hasta terminarla." />
                                <FormControlLabel onChange={() => set('c', 3)} value="Mostrando amabilidad, reconocimiento y gratitud." control={<Radio />} label="Mostrando amabilidad, reconocimiento y gratitud." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Qué acciones te ayudarían a propiciar entornos probienestar, ambientes seguros e incluyentes? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 4)} value="Gestionar mejor mis emociones y tomar mejores decisiones." control={<Radio />} label="Gestionar mejor mis emociones y tomar mejores decisiones." />
                                <FormControlLabel onChange={() => set('b', 4)} value="Establecer límites para que todo el equipo se desconecte fuera del trabajo." control={<Radio />} label="Establecer límites para que todo el equipo se desconecte fuera del trabajo." />
                                <FormControlLabel onChange={() => set('c', 4)} value="Expresar gratitud y reconocimiento de manera oportuna, sincera y auténtica." control={<Radio />} label="Expresar gratitud y reconocimiento de manera oportuna, sincera y auténtica." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">Según Daniel Goleman, la mejor manera de manejar las emociones es _________ antes de responder o actuar. </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 5)} value="ocultarlas" control={<Radio />} label="ocultarlas" />
                                <FormControlLabel onChange={() => set('b', 5)} value="nombrarlas" control={<Radio />} label="nombrarlas" />
                                <FormControlLabel onChange={() => set('c', 5)} value="cambiarlas" control={<Radio />} label="cambiarlas" />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">La falta de sueño, el hambre, la ansiedad y el estrés disminuyen tu tolerancia y te vuelven irritable. </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 6)} value="Verdadero " control={<Radio />} label="Verdadero " />
                                <FormControlLabel onChange={() => set('b', 6)} value="Falso " control={<Radio />} label="Falso " />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">Para ser un People Leader y un <i>role model</i>, ¿en qué te debes enfocar primero? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 7)} value="En mi propio bienestar. " control={<Radio />} label="En mi propio bienestar. " />
                                <FormControlLabel onChange={() => set('b', 7)} value="En el bienestar de mi equipo. " control={<Radio />} label="En el bienestar de mi equipo. " />
                                <FormControlLabel onChange={() => set('c', 7)} value="En cumplir las metas." control={<Radio />} label="En cumplir las metas." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cuál de las siguientes es una práctica de gratitud? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 8)} value="Encontrar lo que me motiva en mi trabajo diario. " control={<Radio />} label="Encontrar lo que me motiva en mi trabajo diario. " />
                                <FormControlLabel onChange={() => set('b', 8)} value="Establecer límites para que respeten mi horario fuera del trabajo" control={<Radio />} label="Establecer límites para que respeten mi horario fuera del trabajo" />
                                <FormControlLabel onChange={() => set('c', 8)} value="Comenzar una conversación agradeciendo algo a la otra persona." control={<Radio />} label="Comenzar una conversación agradeciendo algo a la otra persona." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <div className="pregunta">¿Cómo puedes prevenir el burnout? </div>
                        <FormControl>
                            <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                                <FormControlLabel onChange={() => set('a', 9)} value="Estableciendo límites para desconectarme fuera del trabajo. " control={<Radio />} label="Estableciendo límites para desconectarme fuera del trabajo. " />
                                <FormControlLabel onChange={() => set('b', 9)} value="Encontrando lo que me motiva en mi trabajo diario. " control={<Radio />} label="Encontrando lo que me motiva en mi trabajo diario. " />
                                <FormControlLabel onChange={() => set('c', 9)} value="Atendiendo primero lo urgente y después lo importante." control={<Radio />} label="Atendiendo primero lo urgente y después lo importante." />

                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={12} xs={12}>
                        <Button className="btn blanco" onClick={registrar}>
                            Enviar respuestas
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>}
        {reprobado && <Reprobado seccion1={seccion1} seccion2={seccion2} seccion3={seccion3} perfil={params.perfil} calif={calif} />}
        {aprobado && <Aprobado seccion1={seccion1} seccion2={seccion2} seccion3={seccion3} perfil={params.perfil} activeView={activeView} calif={calif} />}
    </>
}