import React, { useState, useEffect } from "react";
import { db } from "../../../../../config/firebase";
import { ref, onChildChanged } from "firebase/database";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Container } from "@material-ui/core";
import ValidateUser from "../../../../../components/ValidateUser";
import Header from "../../../../../components/Header";
import Footer from "../../../../../components/Footer";
import SpeakersConferencias from "./SpeakersConferencias";
import { getCollectionsComplete, top, } from "../../../../../resources/js/functions";
import { ejectutivos, jefaturasyCoordinadores } from '../informacion';
import "./styles.scss";

export default function MasterClass() {
  const navigate = useNavigate();
  const params = useParams();
  const [informacion, setinformacion] = useState({})
  const dbRef = ref(db, "configuracionPilares/inclusion/");

  useEffect(() => {
    top();
    getInfo();
    onChildChanged(dbRef, (data) => {
      getInfo();
    });
  }, []);

  const getInfo = async () => {
    const data = await getCollectionsComplete('configuracionPilares/inclusion');
    setinformacion(data);
  }

  const url = params.id === 'ejecutivos' ? ejectutivos.masterClass.urlVideoMasterClass : jefaturasyCoordinadores.masterClass.urlVideoMasterClass

  return (
    <>
      <ValidateUser>
        <Header />
        <div id="masterClass">
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
              <Grid item lg={12} md={12} xs={12}>
                <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <div className="titulos">{params.id === 'ejecutivos' ? `Master Class: ${ejectutivos.masterClass.titulo}` : `Master Class: ${jefaturasyCoordinadores.masterClass.titulo}`}</div>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                {params.id === 'ejecutivos' ?
                  <p>{ejectutivos.masterClass.descripcion}</p> :
                  <p>{jefaturasyCoordinadores.masterClass.descripcion}</p>
                }
              </Grid>
              <Grid item lg={8} md={12} xs={12}>
                <div className="contenedor-streaming">
                  <iframe title="streaming" src={url} allow="autoplay; fullscreen" frameBorder="0" allowFullScreen className="estilo-streaming"></iframe>
                </div>
              </Grid>
              {informacion.chatEjecutivos && <Grid item lg={4} md={6} xs={12}>
                <div className="contentenedor-chat">
                  <iframe title="chat" src={informacion.urlChatEjecutivos} width="100%" height="100%" frameBorder="0"></iframe>
                </div>
              </Grid>}
              {informacion.chatJefaturasCoordinaciones && <Grid item lg={4} md={6} xs={12}>
                <div className="contentenedor-chat">
                  <iframe title="chat" src={informacion.urlChatJefaturasCoordinaciones} width="100%" height="100%" frameBorder="0"></iframe>
                </div>
              </Grid>}
            </Grid>
          </Container>
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
              <SpeakersConferencias />
              <Grid item lg={12} md={12} xs={12} className='center'>
                <div className="cursor" onClick={() => navigate(-1)}>{'< Regresar'}</div>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </div>
      </ValidateUser>
    </>
  );
}
