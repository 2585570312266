import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import bannerMobile from '../../../../../resources/images/broshure/seccion1/img-mobile.svg'

export default function Seccion1() {
    return (
        <>
            {!isMobileOnly && <section id="seccion1" className='wow slideInDown' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s' />}

            {isMobileOnly && <section id="seccion1-mobile" className='wow slideInDown' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                <img src={bannerMobile} alt="banner" className="full" />
            </section>
            }
        </>

    )
}