const questionario = {
	pregunta1: {
		pregunta: '1.	¿Cuáles acciones realiza un líder narrador?',
		opcionA: 'a.	Genera impacto, inspira y vincula las tareas del equipo; en la cadena del propósito de la compañía',
		opcionB: 'b.	Comparte discursos convincentes e inspira a su equipo y a líderes',
		opcionC: 'c.	Habla en público y presenta resultados ',
	},
	pregunta2: {
		pregunta: '2.	¿Qué acciones realiza un líder activador?',
		opcionA: 'a.	Estimula constantemente al equipo, brinda palabras de apoyo',
		opcionB: 'b.	Comparte la pasión de lo que hace, promueve espacios para la innovación y crea puentes de comunicación en su equipo',
		opcionC: 'c.	Escucha a su equipo y se cerciora que cumplan sus indicadores',
	},
	pregunta3: {
		pregunta: '3.	¿Cómo desarrolla los resultados un líder a través del impulso?',
		opcionA: 'a.	Reconsidera lo que hace y cómo lo hace, interactúa entre áreas y desafia el estatus quo, es curioso y se adapta a las necesidades del mercado',
		opcionB: 'b.	Genera reuniones semanales y valida los avances en los indicadores',
		opcionC: 'c.	No innova, ya que todos los procesos están establecidos y deben seguirse perfectamente',
	},
	pregunta4: {
		pregunta: '4.	¿Por qué es importante que un líder deje de hacer constantemente micromanagement?',
		opcionA: 'a.	Porque al no hacerlo permite la equivocación, trabaja para corregir y desafía los resultados',
		opcionB: 'b.	Porque evitará revisar constantemente a su equipo para evitar posibles errores ',
		opcionC: 'c.	El micromanagement es una herramienta positiva que fortalece la cultura de la organización',
	},
	pregunta5: {
		pregunta: '5.	¿Cuáles son las cualidades de un líder narrador?',
		opcionA: 'a.	Comparte sólo los resultados positivos, oculta información que pueda dañar los intereses de la compañía ',
		opcionB: 'b.	Platica con todo el equipo y genera conexión con las personas',
		opcionC: 'c.	Promueve constantemente un balance y comparte el diálogo para generar acuerdos',
	},
	pregunta6: {
		pregunta: '6.	¿Cómo influye un líder de manera positiva en su equipo?',
		opcionA: 'a.	Construyendo vínculos que empoderan a las personas a realizar sus actividades',
		opcionB: 'b.	Revisando constantemente sus avances y realiza ajustes cada semana',
		opcionC: 'c.	Conversando con su equipo las áreas de oportunidad y las hace evidentes',
	},
	pregunta7: {
		pregunta: '7.	¿Por qué es importante que un líder confié en su equipo para lograr los resultados?',
		opcionA: 'a.	Porque brinda autonomía y estimula el aprendizaje a través de los errores, establece límites y empodera al personal',
		opcionB: 'b.	Porque asume que todo el equipo esta comprometido y tienen las cualidades para realizar su trabajo',
		opcionC: 'c.	Porque conversa con su equipo y aprovecha los días libres para generar mayor cercanía y desarrolla confianza fuera de horario laboral  ',
	},
	pregunta8: {
		pregunta: '8.	¿Por qué debe un líder adaptar sus mensajes a diferentes audiencias?',
		opcionA: 'a.	Cada audiencia debe estar preparada para lo que va a compartir el líder',
		opcionB: 'b.	Debe adaptar su mensaje a la audiencia, incluso el material de proyección, PPT, gráficas, diagramas, etc.',
		opcionC: 'c.	No es relevante, ya que la información es la misma',
	},
	pregunta9: {
		pregunta: '9.	¿Cómo puede un líder adaptarse al constante cambio? ',
		opcionA: 'a.	Siendo ejemplo al mostrar apertura al cambio y a la adaptación constante de nuestros procesos ',
		opcionB: 'b.	Siendo estáticos, mantenido nuestros procesos sin posibilidad de ajustes',
		opcionC: 'c.	Ajustando lo mínimo posible nuestros procesos en largos periodos',
	},
	pregunta10: {
		pregunta: '10.	¿Cómo desarrolla un líder la cultura organizacional? ',
		opcionA: 'a.	Estando pendiente de lo que ocurre en el mercado, se adapta y toma decisiones agiles, empodera y desarrolla a su equipo',
		opcionB: 'b.	Apegado a los procesos y productos consolidados en el mercado, la cultura ya esta definida por el modelo de negocio',
		opcionC: 'c.	Construyendo iniciativas locales que desafían los intereses de la compañía',
	},
};

export default questionario;
