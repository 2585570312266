import React from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import CarouselSeccion15 from './Carousel';
import img1 from '../../../../../resources/images/broshure/seccion15/Expertos.svg';
import img2 from '../../../../../resources/images/broshure/seccion12/img2.svg';
import img3 from '../../../../../resources/images/broshure/seccion12/img3.svg';
import img4 from '../../../../../resources/images/broshure/seccion12/img4.svg';


export default function Seccion15() {

    return <section id="seccion15">
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={10} sm={12} md={10} lg={10} className='wow fadeInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <img src={img1} alt="img" className={`mb30 ${isMobileOnly && 'full'}`} />
                </Grid>
            </Grid>
        </Container>
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={10} lg={10} className='wow fadeInRight' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
                    <div className="center">

                        <CarouselSeccion15 />
                    </div>
                </Grid>
            </Grid>
        </Container>
    </section>
}