import React from 'react';
import { Grid, Container } from "@material-ui/core";
import { isMobileOnly } from 'react-device-detect';
import Carousel from './carousel';
import img from '../../../../../resources/images/broshure/seccion7/texto.svg'
import img1 from '../../../../../resources/images/broshure/seccion7/img1-mobile.svg'


export default function Seccion7() {
    return <section id="seccion7">
        <Container maxWidth="lg" >
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} className='center wow slideInLeft' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
                    <img src={`${isMobileOnly ? img1 : img}`} className='size' alt="texto" />
                </Grid>
                <Carousel />
            </Grid>
        </Container>
    </section>
}