import tutorial from '../../../../resources/images/daring/pilares/tutorial-transformador.png';
import podcast from '../../../../resources/images/daring/pilares/podcast-transformador.png';
import evaluacion from '../../../../resources/images/daring/pilares/evaluacion-transformador.png';
import insignia from '../../../../resources/images/daring/pilares/portada-insignia-transformador.png';
const ejectutivos = {
	tutorial1: {
		portada: tutorial,
		titulo: 'Clase tutorial de la competencia Transformador',
		descripcion: 'Conoce cómo incluir y fortalecer la competencia  Trasformador  en tu liderazgo',
		urlInsumo: '/daring/ejecutivos/transformador/tutorial',
		url: 'https://player.vimeo.com/video/883309449?h=7b5ddc0ae1&color=ef00af&title=0&byline=0&portrait=0',
	},
	podcast1: {
		titulo: 'Podcast de la competencia Transformador',
		descripcion: 'Conoce cómo un experto aplica la competencia Trasformador  en su cotidianidad como líder y su punto de vista al ponerlo en práctica',
		urlInsumo: '/daring/ejecutivos/transformador/podcast',
		portada: podcast,
		url: 'https://player.vimeo.com/video/882408071?h=6b4ce24d6f&title=0&byline=0&portrait=0',
	},
	evaluacion: {
		portada: evaluacion,
		url: '',
		titulo: '',
		descripcion: '',
		urlInsumo: '/daring/ejecutivos/transformador/evaluacion',
	},
	insignia: {
		portada: insignia,
		titulo: 'Insignia',
		descripcion: '',
		urlInsumo: '/daring/ejecutivos/transformador/insignia',
		url: '',
	},
};
const jefaturasyCoordinadores = {
	tutorial1: {
		titulo: 'Clase tutorial de la competencia Transformador',
		descripcion: 'Conoce cómo incluir y fortalecer la competencia  Trasformador  en tu liderazgo',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/transformador/tutorial',
		portada: tutorial,
		url: 'https://player.vimeo.com/video/882723142?h=16d93e96d7&title=0&byline=0&portrait=0',
	},
	podcast1: {
		titulo: 'Podcast de la competencia Transformador',
		descripcion: 'Conoce cómo un experto aplica la competencia Trasformador  en su cotidianidad como líder y su punto de vista al ponerlo en práctica',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/transformador/podcast',
		portada: podcast,
		url: 'https://player.vimeo.com/video/882408071?h=6b4ce24d6f&title=0&byline=0&portrait=0',
	},
	evaluacion: {
		portada: evaluacion,
		url: '',
		titulo: 'Evaluación Narrador Activador',
		descripcion: '',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/transformador/evaluacion',
	},
	insignia: {
		portada: insignia,
		titulo: 'Insignia',
		descripcion: '',
		urlInsumo: '/daring/jefaturas-y-coordinaciones/transformador/insignia',
		url: '',
	},
};

export { ejectutivos, jefaturasyCoordinadores };
