import React, { useEffect } from 'react';
import ScrollToTop from "react-scroll-to-top";
import Seccion1 from './Seccion1';
import Seccion2 from './Seccion2';
import Seccion3 from './Seccion3';
import Seccion4 from './Seccion4';
import Seccion5 from './Seccion5';
import Seccion6 from './Seccion6';
import Seccion7 from './Seccion7';
import Seccion8 from './Seccion8';
import Seccion9 from './Seccion9';
import Seccion10 from './Seccion10';
import Seccion11 from './Seccion11';
import Seccion12 from './Seccion12';
import Seccion13 from './Seccion13';
import Seccion14 from './Seccion14';
import Seccion15 from './Seccion15';
import Seccion16 from './Seccion16';
import Seccion17 from './Seccion17';
import Seccion18 from './Seccion18';
import Seccion19 from './Seccion19';
import Seccion20 from './Seccion20';
import { top } from '../../../../resources/js/functions'

export default function BroshureRolUno() {
    useEffect(() => {
        top();
    }, []);

    return <>
        <ScrollToTop
            smooth
            color="#fff"
            style={{ backgroundColor: `#2c6db4`, padding: "7px 0" }}
        />
        <Seccion1 />
        <Seccion2 />
        <Seccion3 />
        <Seccion4 />
        <Seccion5 />
        {/* <Seccion6 /> */}
        <Seccion7 />
        <Seccion8 />
        <Seccion9 />
        <Seccion10 />
        <Seccion11 />
        <Seccion12 />
        <Seccion13 />
        <Seccion14 />
        <Seccion15 />
        <Seccion16 />
        <Seccion2 />
        <Seccion18 />
        <Seccion19 />
        <Seccion20 />
    </>
}
