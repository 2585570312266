import React from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import carlosSuarez from '../../../../resources/images/pilares/bienestar/carlos-suarez.svg';
import alejandraBarba from '../../../../resources/images/pilares/bienestar/alejandra-barba.svg';

export default function SpeakersConfertencias() {
  const params = useParams();

  return (
    <>
      <Grid item lg={8} md={12} xs={12}>
        <div className="titulos">Acerca del experto</div>
      </Grid>
      <Grid item lg={8} md={12} xs={12} >
        <div className="full">
          <div className="name-conferencista full ">
            <div >
              <img className="img-conferencista" src={params.id === 'ejecutivos' ? carlosSuarez : alejandraBarba} alt="img" />
            </div>
            <span className="tendencias bold" style={{ display: "inline-block" }}>
              <span className="job-conferencista">
                <span className="name">{params.id === 'ejecutivos' ? 'Carlos Suárez' : 'Alejandra Barba'}</span>
                <span className="description-conferencista">
                  {
                    params.id === 'ejecutivos' ?
                      <ul>
                        <li>Consultor en liderazgo y desarrollo Gerencial</li>
                        <li>Psicólogo por la Universidad Iberoamericana</li>
                        <li>Maestría en Administración de Negocios por Barrington University</li>
                        <li>Maestría en Educación por University of Southern Queensland</li>
                      </ul> :
                      <ul>
                        <li>TEDx Speaker</li>
                        <li>Consultor en desarrollo organizacional</li>
                        <li>Profesora de Cátedra en la Escuela de Negocios, Gestión y Liderazgo del Tecnológico de Monterrey</li>
                        <li>Instructora en materia de Psicología Positiva para la Universidad Tecmilenio</li>
                        <li>Experta en programas de capacitación</li>
                      </ul>
                  }
                </span>
              </span>
            </span>
          </div>
        </div>
      </Grid>
    </>
  );
}



 

