import portadaImg from '../../../../../resources/images/home/portada.png'

const data = [
	{
		portada: portadaImg,
		titulo: 'Titulo 1',
	},
	{
		portada: portadaImg,
		titulo: 'Titulo 2',
	},
	{
		portada: portadaImg,
		titulo: 'Titulo 3',
	},
	{
		portada: portadaImg,
		titulo: 'Titulo 4',
	},
];

export default data;