import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Paper, Button } from '@mui/material';
import Swal from 'sweetalert2';
import readXlsxFile from 'read-excel-file';
import { TextField } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '../../../../components/Modal';
import Header from '../../../../components/HeaderAdmin';
import ValidateAdmin from '../../../../components/ValidateAdmin';
import { getCollectionsComplete, getDate, updateAll, updateData } from '../../../../resources/js/functions';
import { formato } from '../reportes';
import './styles.scss';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';

const steps = [
	{
		label: 'Descarga el formato para modificarlo',
	},
	{
		label: 'Carga el formato que modificaste',
	},
	{
		label: 'Revisa los cambios efectuados',
	},
	{
		label: 'Confirma los cambios',
	},
];

export default function Actualizacion() {
	const [usuarios, setUsuarios] = useState({});
	const [usuariosRespaldo, setUsuariosRespaldo] = useState({});
	const [usuariosExcel, setUsuariosExcel] = useState({});
	const [nuevos, setNuevos] = useState({});
	const [modificados, setModificados] = useState({});
	const [eliminados, setEliminados] = useState({});
	const [modal, setModal] = useState(false);
	const [comparar, setComparar] = useState(null);
	const [inputFile, setInputFile] = useState(null);
	const [activeStep, setActiveStep] = React.useState(0);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	useEffect(() => {
		getUsuarios();
	}, []);

	const getUsuarios = async () => {
		let json = {};
		let jsonRespaldo = {};
		const users = await getCollectionsComplete('users');
		Object.values(users).map((val) => {
			json[val.id] = {
				id: val.id,
				idGlobal: val.idGlobal,
				nombre: val.nombre ? val.nombre : null,
				puesto: val.puesto ? val.puesto : null,
				nombreLineManager: val.nombreLineManager ? val.nombreLineManager : null,
				audiencia: val.audiencia ? val.audiencia : null,
				statusLineManager: val.statusLineManager ? val.statusLineManager : null,
				correo: val.correo ? val.correo : null,
				j1: val.j1 ? val.j1 : null,
				j2: val.j2 ? val.j2 : null,
				j3: val.j3 ? val.j3 : null,
				j4: val.j4 ? val.j4 : null,
				j5: val.j5 ? val.j5 : null,
				activo: val.activo ? 'Activo' : 'Inactivo',
			};
			jsonRespaldo[val.id] = {
				...val,
				activo: val.activo ? 'Activo' : 'Inactivo',
			};
		});
		setUsuarios({ ...usuarios, ...json });
		setUsuariosRespaldo(jsonRespaldo);
	};

	const readUploadFile = (e) => {
		e.preventDefault();
		let json = {};
		if (e.target.files) {
			readXlsxFile(e.target.files[0]).then((rows) => {
				Object.keys(rows).map((key, index) => {
					const data = rows[key];
					if (index === 0) {
						return;
					}
					json[data[0]] = {
						id: data[0].toString(),
						idGlobal: data[1] ? data[1].toString() : '',
						nombre: data[2] ? data[2] : null,
						puesto: data[3] ? data[3] : null,
						nombreLineManager: data[4] ? data[4] : null,
						audiencia: data[5] ? data[5] : null,
						statusLineManager: data[6] ? data[6] : null,
						correo: data[7] ? data[7] : null,
						j1: data[8] ? data[8] : null,
						j2: data[9] ? data[9] : null,
						j3: data[10] ? data[10] : null,
						j4: data[11] ? data[11] : null,
						j5: data[12] ? data[12] : null,
						activo: data[13],
					};
				});
				setUsuariosExcel(json);
				compareJsons(json);
				buscarEliminados(json);
			});
		}
	};

	const compareJsons = (jsonUsuarios) => {
		let json = {};
		let jsonNuevos = {};
		Object.keys(jsonUsuarios).map((key) => {
			const usuarioBD = usuarios[key];
			const usuarioEXCEL = jsonUsuarios[key];
			if (JSON.stringify(usuarioBD) !== JSON.stringify(usuarioEXCEL)) {
				if (usuarioBD) {
					if (usuarioEXCEL.activo === 'Activo') {
						json[key] = {
							activo: usuarioEXCEL.activo === 'Activo' ? true : false,
							...usuarioEXCEL,
						};
					}
				}
			}

			if (!usuarioBD) {
				jsonNuevos[key] = {
					...usuarioEXCEL,
				};
			}
		});
		setNuevos({ ...nuevos, ...jsonNuevos });
		setModificados({ ...modificados, ...json });
	};

	const buscarEliminados = (json) => {
		let jsonNuevo = {};
		Object.keys(json).map((key) => {
			const usuarioRespaldoBD = usuariosRespaldo[key];
			const usuarioJson = json[key];
			if (usuarioRespaldoBD.activo === 'Activo' && usuarioJson.activo === 'Inactivo') {
				jsonNuevo[usuarioJson.id] = {
					...usuarioJson,
				};
			}
		});

		// console.log(json);
		// Object.values(json).map((val) => {
		// 	if (val.activo === 'Inactivo' || val.activo === 'inactivo' || val.activo === 'INACTIVO' || val.activo === null) {
		// 		jsonNuevo[val.id] = {
		// 			...val,
		// 			activo: false,
		// 		};
		// 	}
		// });
		setEliminados(jsonNuevo);
	};

	if (Object.keys(usuarios).length === 0) {
		return;
	}

	const confirmarUpdate = () => {
		let json = {};
		Object.keys(usuariosExcel).map((id) => {
			const data = usuariosExcel[id];
			const dataUsuarios = usuariosRespaldo[id];
			if (dataUsuarios) {
				json[id] = {
					...data,
					activo: data.activo === 'Activo' ? true : false,
					broshure: dataUsuarios.broshure ? dataUsuarios.broshure : null,
					fechaBroshure: dataUsuarios.fechaBroshure ? dataUsuarios.broshure : null,
					descargaPlaybook: dataUsuarios.descargaPlaybook ? dataUsuarios.descargaPlaybook : null,
					fechaPlaybookHome: dataUsuarios.fechaPlaybookHome ? dataUsuarios.fechaPlaybookHome : null,
					fechaRegistro: dataUsuarios.fechaRegistro ? dataUsuarios.fechaRegistro : null,
					fechakickoff: dataUsuarios.fechakickoff ? dataUsuarios.fechakickoff : null,
					kickoff: dataUsuarios.kickoff ? dataUsuarios.kickoff : null,
					playBookHome: dataUsuarios.playBookHome ? dataUsuarios.playBookHome : null,
					playbook: dataUsuarios.playbook ? dataUsuarios.playbook : null,
					ultimaConexion: dataUsuarios.ultimaConexion ? dataUsuarios.ultimaConexion : null,
				};
			} else {
				json[id] = {
					...data,
					id: data.id.toString(),
					activo: data.activo === 'Activo' ? true : false,
					fechaRegistro: getDate(),
				};
			}
		});
		// console.log(json);
		Swal.fire({
			title: 'Espera',
			html: `¿Estas seguro de hacer esta actualizacion masiva?`,
			icon: 'warning',
			confirmButtonText: 'Confirmar',
			confirmButtonColor: '#2D6DB4',
		}).then((result) => {
			if (result.isConfirmed) {
				formato(`respaldo-bd-${getDate()}`);
				setTimeout(() => {
					updateAll('users', json)
						.then(() => {
							console.log('actualizo');
							Swal.fire({
								title: '¡Bien Hecho!',
								html: `La base de datos se actualizó correctamente`,
								icon: 'success',
								confirmButtonText: 'Confirmar',
								confirmButtonColor: '#2D6DB4',
							}).then((result) => {
								if (result.isConfirmed) {
									window.location.reload();
								}
							});
						})
						.catch((e) => console.log(e));
				}, 500);
			}
		});
	};

	const verCompracion = (id) => {
		setComparar(id);
		setModal(!modal);
	};

	return (
		<>
			<ValidateAdmin>
				<Header>
					<div id='actualizacion'>
						<Container maxWidth='lg'>
							<Paper elevation={3} style={{ padding: '15px' }}>
								<Grid container direction='row' spacing={2}>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<h1>Carga masiva de usuarios</h1>
									</Grid>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<Stepper activeStep={activeStep} orientation='vertical'>
											{steps.map((step, index) => (
												<Step key={step.label}>
													<StepLabel optional={index === 3 ? <Typography variant='caption'>Da clic en confirmar para actualizar</Typography> : null}>{step.label}</StepLabel>
													<StepContent>
														{activeStep === 0 && (
															<Typography>
																<Button className='btn' onClick={() => formato('formato')}>
																	Descargar Formato
																</Button>
															</Typography>
														)}
														{activeStep === 1 && <TextField inputProps={{ accept: '.xlsx' }} fullWidth variant='outlined' type='file' onChange={readUploadFile} />}
														{activeStep === 2 && (
															<>
																<Container maxWidth='lg' className='mt30'>
																	<Grid container direction='row' spacing={2}>
																		<Grid item lg={4} md={4} sm={6} xs={12}>
																			<Paper elevation={3} style={{ padding: '15px' }}>
																				<h1>Nuevos</h1>
																				{Object.values(nuevos).map((val) => (
																					<div className='list-name' key={val.id}>{`${val.nombre}`}</div>
																				))}
																			</Paper>
																		</Grid>
																		<Grid item lg={4} md={4} sm={6} xs={12}>
																			<Paper elevation={3} style={{ padding: '15px' }}>
																				<h1>Modificados</h1>
																				{Object.values(modificados).map((val) => (
																					<div className='list-name cursor' key={val.id} onClick={() => verCompracion(val.id)}>{`${val.nombre}`}</div>
																				))}
																			</Paper>
																		</Grid>
																		<Grid item lg={4} md={4} sm={6} xs={12}>
																			<Paper elevation={3} style={{ padding: '15px' }}>
																				<h1>Eliminados</h1>
																				{Object.values(eliminados).map((val) => (
																					<div className='list-name' key={val.id}>{`${val.nombre}`}</div>
																				))}
																			</Paper>
																		</Grid>
																	</Grid>
																</Container>
															</>
														)}
														<div style={{ display: 'flex', marginTop: '20px', alignItems: 'center', color: '#fff' }}>
															<div className='cursor btn' onClick={index === 3 ? confirmarUpdate : handleNext}>
																{index === steps.length - 1 ? 'Confirmar' : 'Siguiente'}
															</div>
															&nbsp;
															{index === steps.length - 1 && (
																<div className='cursor cancelar' onClick={() => setActiveStep(1)}>
																	Cancelar carga
																</div>
															)}
															&nbsp;
															{index > 0 && (
																<div className='cursor' style={{ color: '#000' }} onClick={index > 0 ? handleBack : null}>
																	Regresar paso anterior
																</div>
															)}
														</div>
													</StepContent>
												</Step>
											))}
										</Stepper>
										{activeStep === steps.length && (
											<Paper square elevation={0} sx={{ p: 3 }}>
												{/* <Typography>All steps completed - you&apos;re finished</Typography>
                                            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                                Reset
                                            </Button> */}
												temrino
											</Paper>
										)}
									</Grid>
									{/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Button className='btn' onClick={() => formato('formato')}>Formato</Button>
                                </Grid> */}
									{/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <form>
                                        <TextField
                                            fullWidth
                                            variant='outlined'
                                            type="file"
                                            name="upload"
                                            id="upload"
                                            onChange={readUploadFile}
                                        />
                                    </form>
                                </Grid> */}
								</Grid>
							</Paper>
						</Container>
						{/* <Container maxWidth="lg" className='mt30'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={3} style={{ padding: '15px' }}>
                                    <h1>Nuevos</h1>
                                    {Object.values(nuevos).map((val) => (
                                        <div className='list-name' key={val.id}>{`${val.nombre}`}</div>
                                    ))}
                                </Paper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={3} style={{ padding: '15px' }}>
                                    <h1>Modificados</h1>
                                    {Object.values(modificados).map((val) => (
                                        <div className='list-name cursor' key={val.id} onClick={() => verCompracion(val.id)}>{`${val.nombre}`}</div>
                                    ))}
                                </Paper>
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Paper elevation={3} style={{ padding: '15px' }}>
                                    <h1>Eliminados</h1>
                                    {Object.values(eliminados).map((val) => (
                                        <div className='list-name' key={val.id}>{`${val.nombre}`}</div>
                                    ))}
                                </Paper>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className="center">
                                    <Button className='btn' onClick={confirmarUpdate}>Confirmar Cargar</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Container> */}
						<Modal open={modal}>
							<Container maxWidth='lg'>
								<Grid container direction='row' alignItems='stretch' justifyContent='center' spacing={2}>
									<Grid item lg={12} md={12}>
										<div style={{ fontSize: '18px', textAlign: 'right' }} className='cursor bold blanco' onClick={() => setModal(!modal)}>
											<CloseIcon />
										</div>
									</Grid>
									<Grid item lg={6} md={6}>
										<div className='contenedor-comparar'>
											<p>
												<b>Antes</b>
											</p>
											{comparar !== null && (
												<p className='igual'>
													<b>ID Global:</b> {`${usuarios[comparar].idGlobal}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>Nombre:</b> {`${usuarios[comparar].nombre}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>Puesto:</b> {`${usuarios[comparar].puesto}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>Nombre Line Manager:</b> {`${usuarios[comparar].nombreLineManager}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>Audiencia:</b> {`${usuarios[comparar].audiencia}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>Status Line Manage:</b> {`${usuarios[comparar].statusLineManager}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>Correo:</b> {`${usuarios[comparar].correo}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>J1:</b> {`${usuarios[comparar].j1}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>J2:</b> {`${usuarios[comparar].j2}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>J3:</b> {`${usuarios[comparar].j3}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>J4:</b> {`${usuarios[comparar].j4}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>J5:</b> {`${usuarios[comparar].j5}`}
												</p>
											)}
											{comparar !== null && (
												<p className='igual'>
													<b>Activo:</b> {`${usuarios[comparar].activo}`}
												</p>
											)}
										</div>
									</Grid>
									<Grid item lg={6} md={6}>
										<div className='contenedor-comparar'>
											<p>
												<b>Despues</b>
											</p>
											{comparar !== null && (
												<p className={`${usuarios[comparar].idGlobal !== modificados[comparar].idGlobal ? 'noigual' : 'igual'}`}>
													<b>ID Global: </b>
													{`${modificados[comparar].idGlobal}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].nombre !== modificados[comparar].nombre ? 'noigual' : 'igual'}`}>
													<b>Nombre: </b>
													{`${modificados[comparar].nombre}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].puesto !== modificados[comparar].puesto ? 'noigual' : 'igual'}`}>
													<b>Puesto: </b>
													{`${modificados[comparar].puesto}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].nombreLineManager !== modificados[comparar].nombreLineManager ? 'noigual' : 'igual'}`}>
													<b>Nombre Line Manager: </b>
													{`${modificados[comparar].nombreLineManager}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].audiencia !== modificados[comparar].audiencia ? 'noigual' : 'igual'}`}>
													<b>Audiencia: </b>
													{`${modificados[comparar].audiencia}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].statusLineManager !== modificados[comparar].statusLineManager ? 'noigual' : 'igual'}`}>
													<b>Status Line Manage: </b>
													{`${modificados[comparar].statusLineManager}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].correo !== modificados[comparar].correo ? 'noigual' : 'igual'}`}>
													<b>Correo: </b>
													{`${modificados[comparar].correo}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].j1 !== modificados[comparar].j1 ? 'noigual' : 'igual'}`}>
													<b>J1: </b>
													{`${modificados[comparar].j1}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].j2 !== modificados[comparar].j2 ? 'noigual' : 'igual'}`}>
													<b>J2: </b>
													{`${modificados[comparar].j2}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].j3 !== modificados[comparar].j3 ? 'noigual' : 'igual'}`}>
													<b>J3: </b>
													{`${modificados[comparar].j3}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].j4 !== modificados[comparar].j4 ? 'noigual' : 'igual'}`}>
													<b>J4: </b>
													{`${modificados[comparar].j4}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].j5 !== modificados[comparar].j5 ? 'noigual' : 'igual'}`}>
													<b>J5: </b>
													{`${modificados[comparar].j5}`}
												</p>
											)}
											{comparar !== null && (
												<p className={`${usuarios[comparar].activo !== modificados[comparar].activo ? 'noigual' : 'igual'}`}>
													<b>Activo: </b>
													{`${modificados[comparar].activo}`}
												</p>
											)}
										</div>
									</Grid>
								</Grid>
							</Container>
						</Modal>
					</div>
				</Header>
			</ValidateAdmin>
		</>
	);
}
