import React from 'react';
import AliceCarousel from "react-alice-carousel";
import Carousel from 'react-material-ui-carousel';
import { deviceType, isMobileOnly } from 'react-device-detect';
import imagenes from './imagenes'
import linkeding from '../../../../../../resources/images/broshure/seccion15/linkeding.svg';
import tecIcono from '../../../../../../resources/images/broshure/seccion15/tec-icono.svg';

import "react-alice-carousel/lib/alice-carousel.css";

export default function CarouselSeccion15() {
    return (
        <>
            {!isMobileOnly && <AliceCarousel
                mouseTrackingEnabled
                items={imagenes}
                autoPlayInterval="4000"
                autoPlay={true}
                infinite={true}
                mouseTracking
                startIndex={0}
                slideToIndex={0}
                // disableButtonsControls
                // disableDotsControls
                responsive={{
                    425: {
                        items: 1,
                    },
                    640: {
                        items: 2,
                    },
                    1024: {
                        items: 3,
                    },
                }}
            >
                {imagenes.map((i, index) => {
                    return (
                        <>
                            <div key={`${JSON.stringify(`val-${index}`)}`} style={{ padding: '15px' }}>
                                <div className="fullwrap">
                                    {/* <iframe width="100%" height="240" title="streaming" src={i.urlVideo} allow="autoplay; fullscreen" allowFullScreen frameBorder="0" className="estilo-streaming" /> */}
                                    <img src={i.portada} alt="portada" className="" />
                                    {i.descripcion !== '' && <div className="fullcap">
                                        <img src={i.descripcion} alt="portada" className="full" />
                                    </div>}
                                </div>
                                {i.nombre !== '' && <div className='mt30'>
                                    <img src={i.nombre} alt="portada" className="" />
                                </div>}
                                <div className='mt30 padres'>
                                    {i.linkeding !== '' && <img src={linkeding} alt="portada" className="cursor hijoss" style={{ marginRight: '10px' }} onClick={() => window.open(i.linkeding, '_blank')} />}
                                    {i.segundoIcon !== '' && <img src={i.segundoIcon} alt="portada" className="hijoss" />}
                                </div>
                            </div>
                        </>
                    );
                })}
            </AliceCarousel>}
            {isMobileOnly && <Carousel>
                {
                    imagenes.map((item, i) => <>
                        <div key={`${JSON.stringify(`val-${i}`)}`} className='center'>
                            <div className="fullwrap">
                                <div className='center'>
                                    <img src={item.portada} alt="portada" className="" />
                                </div>
                                {item.drescripcion !== '' && <div className="fullcap">
                                    <img src={item.descripcion} alt="portada" className="full" />
                                </div>}
                            </div>
                            {item.nombre !== '' && <div className='mt30'>
                                <img src={item.nombre} alt="portada" className="" />
                            </div>}
                            <div className='mt30'>
                                {item.linkeding !== '' && <img src={linkeding} alt="portada" className="cursor" style={{ marginRight: '10px' }} onClick={() => window.open(i.linkeding, '_blank')} />}
                                <img src={item.segundoIcon} alt="portada" className="" />
                            </div>
                        </div>
                    </>)
                }
            </Carousel>}
        </>
    )
}