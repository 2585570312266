import React from "react";
import { Grid, Container } from "@material-ui/core";
import raquelSaenz from '../../../resources/images/daring/pilares/raquel-saenz.svg';

export default function SpeakersConfertencias() {
  return (
    <>
      <Container maxWidth="lg" className="mb30 mt30">
        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
          <Grid item lg={8} md={12} xs={12}>
            <div className="titulos">Acerca del experto</div>
          </Grid>
        </Grid>
      </Container>
      
        <Container maxWidth="lg">
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
            <Grid item lg={12} md={12} xs={12} >
              <div className="full">
                <div className="name-conferencista full ">
                  <div >
                    <img className="img-conferencista" src={raquelSaenz} alt="img" />
                  </div>
                  <span className="tendencias bold" style={{ display: "inline-block" }}>
                    <span className="job-conferencista">
                      <span className="name">Raquel Hernández Sanz</span>
                      <span className="description-conferencista">
                        <ul>
                          <li>Experta en personas y procesos de cambio con amplia experiencia en la gestión del talento como consultora senior.</li>
                          <li>Actualmente es Principal en Mercer, líder de la práctica de Talent Strategy Mercer México.</li>
                          <li>1993- 1999 LICENCIADA EN PSICOLOGÍA por la Universidad de Valencia. España. Formada en el Programa Directivo 21: Directivo-coach. Escuela Internacional de Coaching. IDDI de la Universidad Francisco de Vitoria. Madrid. Coach Ejecutivo certificado por AECOP- EMCC.</li>
                          <li>Certificación en Creatividad e Innovación por State University of New York at Buffalo.</li>
                          <li>Colabora como facilitadora en programas de Educación Ejecutiva de la EGADE Business School en México.</li>
                        </ul>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
    </>
  );
}
