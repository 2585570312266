import React from 'react';
import { Grid } from "@material-ui/core";

import btns from './img';
// import 'react-alice-carousel/lib/alice-carousel.css';
import Carousel from 'react-material-ui-carousel';

export default function CarouselSeccion7() {
    return (
        <Grid item xs={12} sm={6} md={6} lg={6} className='center wow slideInRight' data-wow-delay='.2s' data-wow-offset='0' data-wow-duration='.5s'>
            {/* <AliceCarousel
                mouseTrackingEnabled
                items={imagenes}
                autoPlayInterval="1000"
                autoPlay={true}
                // infinite={true}
                mouseTracking
                startIndex={0}
                slideToIndex={0}
                disableButtonsControls
                // disableDotsControls
                responsive={{
                    425: {
                        items: 1,
                    },
                    640: {
                        items: 1,
                    },
                    1024: {
                        items: 1,
                    },
                }}
            >
                {imagenes.map((i, index) => {
                    return (
                        <img src={i} alt={`img-${index}`} key={i} className='' />
                    );
                })}
            </AliceCarousel> */}
            <Carousel>
                {
                    btns.map((item, i) => <img src={item} alt={`img-${item}`} key={i} className='' />)
                }
            </Carousel>
        </Grid>
    )
}