import React, { useEffect, useState } from 'react';
import { Fab } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './styles.scss'; // Asegúrate de importar tu archivo SCSS

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const currentScroll = window.scrollY;
    setVisible(currentScroll > 200); // Cambia el valor según sea necesario
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='scroll-to-top'>
      <Fab className={` ${visible ? 'visible' : 'hidden'}`} color='primary' onClick={scrollToTop}>
        <ArrowUpwardIcon />
      </Fab>
    </div>
  );
};

export default ScrollToTopButton;
