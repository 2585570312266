import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Toolbar } from "@material-ui/core";
import Swal from "sweetalert2";
import { getCollections, getCollectionsComplete, getDate, searchDataByValue, updateData } from '../../resources/js/functions';
import Menu from "../Menu";
import "./styles.scss";

const styles = () => ({
  grow: {
    flexGrow: 1,
  },
});

const Header = (props) => {
  const { children, bg } = props;
  const [seActiva, setSeActiva] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    mensajeCerrarSesion();
    const header = document.getElementById("myHeader");
    const imgBanner = document.getElementById('imgBanner');

    window.addEventListener("scroll", (event) => {
      let scroll = window.scrollY;
      if (scroll > 0) {
        header.classList.add("bg-azul");
        // navega.classList.add("text-header-sticky");
        imgBanner.classList.add('transform-img');
      } else {
        if (!bg) {
          header.classList.remove("bg-azul");
          // navega.classList.remove("text-header-sticky");
        }
        imgBanner.classList.remove('transform-img');
      }
    });
    // const scrollCallBack = window.addEventListener('scroll', () => {
    // });
    // return () => {
    // 	window.removeEventListener('scroll', scrollCallBack);
    // };
  }, []);

  const mensajeCerrarSesion = async () => {
    const data = await getCollectionsComplete(`actualizacion/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
    if (Object.keys(data).length < 1) {
      try {
        updateData('actualizacion', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { actualizo: true, user: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fecha: getDate() }).then(() => {
          Swal.fire({
            title: `Atención`,
            text: `Queremos mejorar tu experiencia. Da clic en el botón para actualizar la plataforma.`,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#2D6DB4",
            confirmButtonText: "Actualizar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const auth = getAuth();
              signOut(auth)
                .then(() => {
                  // Sign-out successful.
                  window.localStorage.removeItem('0hNZeY/gjEeJihP9bIPGHw==');
                  window.localStorage.removeItem('0hNZeY/gjEeJihP9bIPGHw');
                  window.localStorage.removeItem('pilar');
                  navigate("/");
                })
                .catch((error) => {
                  console.log("error: " + error);
                  // An error happened.
                });
            }
          });
        }).catch(e => {
          console.log("Error de actualizacion usuario");
        })
      } catch (error) {
        console.log(error);
      }
    }
  };



  return (
    <>
      <Toolbar id="myHeader" className={`header mb60 ${bg ? "bg-azul" : ""}`}>
        <Menu bg={bg} />
        <div className={styles.grow} />
      </Toolbar>
    </>
  );
};
Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  bg: PropTypes.bool,
};
export default Header;
