import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ejectutivos, jefaturasyCoordinadores } from './informacion';
import { Grid, Container } from '@material-ui/core';
import { ref, onChildChanged } from 'firebase/database';
import { db } from '../../../../config/firebase';
import { getCollectionsComplete, getDate, searchDataByValue, updateData } from '../../../../resources/js/functions';
import likeOn from '../../../../resources/images/pilares/like-on.svg';
import likeOff from '../../../../resources/images/pilares/like-off.svg';
import dislikeOn from '../../../../resources/images/pilares/dislike-on.svg';
import dislikeOff from '../../../../resources/images/pilares/dislike-off.svg';
import visto from '../../../../resources/images/pilares/bienestar/visto.svg';
import noVisto from '../../../../resources/images/pilares/bienestar/no-visto.svg';
import './styles.scss';

export default function PilaresDaring() {
	const navigate = useNavigate();
	const [audiencia, setAudiencia] = useState('Ejecutivos');
	const [recursosActivos, setRecursosActivos] = useState({});

	const [likes, setLikes] = useState({
		tutorial1: {
			like: false,
			dislike: false,
		},
		podcast1: {
			like: false,
			dislike: false,
		},
		evaluacion: {
			like: false,
			dislike: false,
		},
	});

	useEffect(() => {
		getRecursos();
		getInfoUSer();
		getlikes();
		const dbRef = ref(db, `daring/pilarTransformador/`);
		onChildChanged(dbRef, (data) => {
			getRecursos();
		});
		const dbRefLikes = ref(db, `daring/likes/transformador/`);
		onChildChanged(dbRefLikes, (data) => {
			getlikes();
		});
	}, []);

	const getInfoUSer = async () => {
		const data = await searchDataByValue(`users`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
		if (data) {
			setAudiencia(data.audiencia);
		}
	};

	const getRecursos = async () => {
		const data = await getCollectionsComplete(`daring/pilarTransformador/${localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw==')}`);
		const encuesta = await searchDataByValue(`daring/encuesta/transformador`, 'user', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
		let json = {
			tutorial1: data.tutorial1 || false,
			podcast1: data.podcast1 || false,
			evaluacion: Object.keys(encuesta).length > 0 || false,
			insignia: Object.keys(encuesta).length > 0 || false,
			insigniaVista: data.insignia || false,
		};
		setRecursosActivos(json);
	};

	const irRecurso = (id) => {
		switch (id) {
			case 1:
				updateData('daring/pilarTransformador', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { tutorial1: true, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoTutorial1: getDate() })
					.then(() => {
						navigate(audiencia === 'Ejecutivos' ? ejectutivos.tutorial1.urlInsumo : jefaturasyCoordinadores.tutorial1.urlInsumo);
					})
					.catch((e) => console.log(e));
				break;
			case 2:
				updateData('daring/pilarTransformador', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { podcast1: true, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoPodcast1: getDate() })
					.then(() => {
						navigate(audiencia === 'Ejecutivos' ? ejectutivos.podcast1.urlInsumo : jefaturasyCoordinadores.podcast1.urlInsumo);
					})
					.catch((e) => console.log(e));
				break;
			case 3:
				updateData('daring/pilarTransformador', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { evaluacion: true, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoEvaluacion: getDate() })
					.then(() => {
						navigate(audiencia === 'Ejecutivos' ? ejectutivos.evaluacion.urlInsumo : jefaturasyCoordinadores.evaluacion.urlInsumo);
					})
					.catch((e) => console.log(e));
				break;
			case 4:
				updateData('daring/pilarTransformador', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { insignia: true, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), fechaVistoInsignia: getDate() })
					.then(() => {
						navigate(audiencia === 'Ejecutivos' ? ejectutivos.insignia.urlInsumo : jefaturasyCoordinadores.insignia.urlInsumo);
					})
					.catch((e) => console.log(e));
				break;

			default:
				break;
		}
	};

	const getlikes = async () => {
		let json = {
			tutorial1: {
				like: false,
				dislike: false,
			},
			podcast1: {
				like: false,
				dislike: false,
			},
			evaluacion: {
				like: false,
				dislike: false,
			},
		};
		const data = await searchDataByValue(`daring/likes/transformador/`, 'id', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='));
		if (Object.keys(data).length > 0) {
			if (data.tutorial1fechaRegistro) {
				data.tutorial1 ? (json['tutorial1'].like = true) : (json['tutorial1'].dislike = true);
			}

			if (data.podcast1fechaRegistro) {
				if (data.podcast1 || !data.podcast1) {
					data.podcast1 ? (json['podcast1'].like = true) : (json['podcast1'].dislike = true);
				}
			}
			if (data.fechaVistoEvaluacion) {
				if (data.evaluacion || !data.evaluacion) {
					data.evaluacion ? (json['evaluacion'].like = true) : (json['evaluacion'].dislike = true);
				}
			}
		}

		setLikes(json);
	};

	const setLikesBd = (recurso, boolean) => {
		switch (recurso) {
			case 'tutorial1':
				updateData('daring/likes/transformador/', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), tutorial1fechaRegistro: getDate() });
				break;
			case 'podcast1':
				updateData('daring/likes/transformador/', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), podcast1fechaRegistro: getDate() });
				break;
			case 'evaluacion':
				updateData('daring/likes/transformador/', localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), { [recurso]: boolean, id: localStorage.getItem('0hNZeY/gjEeJihP9bIPGHw=='), evaluacionfechaRegistro: getDate() });
				break;

			default:
				break;
		}
	};

	return (
		<div id='transformador'>
			<div id='recursos-digitales'>
				<Container maxWidth='lg'>
					<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
						<Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
							<div className='fullwrap' onClick={() => irRecurso(1)}>
								<img src={audiencia === 'Ejecutivos' ? ejectutivos.tutorial1.portada : jefaturasyCoordinadores.tutorial1.portada} alt='img' className='full cursor' />
							</div>
							<div className='full center'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item xs={12} sm={12} md={2} lg={2}>
										<img src={recursosActivos.tutorial1 ? visto : noVisto} alt='img' />
									</Grid>
									{recursosActivos.tutorial1 && (
										<>
											<Grid item xs={8} sm={8} md={7} lg={7}>
												<b>¿TE GUSTÓ ESTE CONTENIDO?</b>
											</Grid>
											<Grid item xs={4} sm={4} md={3} lg={3}>
												<div className='contenedor-likes'>
													<img className='btn-likes' src={likes.tutorial1.like ? likeOn : likeOff} alt='img' onClick={() => setLikesBd('tutorial1', true)} />
													<img className='btn-likes' src={likes.tutorial1.dislike ? dislikeOn : dislikeOff} alt='img' onClick={() => setLikesBd('tutorial1', false)} />
												</div>
											</Grid>
										</>
									)}
								</Grid>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
							<div className='fullwrap' onClick={() => irRecurso(2)}>
								<img src={audiencia === 'Ejecutivos' ? ejectutivos.podcast1.portada : jefaturasyCoordinadores.podcast1.portada} alt='img' className='full cursor' />
							</div>
							<div className='full center'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item xs={12} sm={12} md={2} lg={2}>
										<img src={recursosActivos.podcast1 ? visto : noVisto} alt='img' />
									</Grid>
									{recursosActivos.podcast1 && (
										<>
											<Grid item xs={8} sm={8} md={7} lg={7}>
												<b>¿TE GUSTÓ ESTE CONTENIDO?</b>
											</Grid>
											<Grid item xs={4} sm={4} md={3} lg={3}>
												<div className='contenedor-likes'>
													<img className='btn-likes' src={likes.podcast1.like ? likeOn : likeOff} alt='img' onClick={() => setLikesBd('podcast1', true)} />
													<img className='btn-likes' src={likes.podcast1.dislike ? dislikeOn : dislikeOff} alt='img' onClick={() => setLikesBd('podcast1', false)} />
												</div>
											</Grid>
										</>
									)}
								</Grid>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
							<div className='fullwrap' onClick={recursosActivos.tutorial1 && recursosActivos.podcast1 ? () => irRecurso(3) : null}>
								<img src={audiencia === 'Ejecutivos' ? ejectutivos.evaluacion.portada : jefaturasyCoordinadores.evaluacion.portada} alt='img' className={`full ${recursosActivos.tutorial1 && recursosActivos.podcast1 ? 'cursor' : 'disable'}`} />
							</div>
							<div className='full center'>
								<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
									<Grid item xs={12} sm={12} md={2} lg={2}>
										<img src={recursosActivos.evaluacion ? visto : noVisto} alt='img' />
									</Grid>
									{recursosActivos.tutorial1 && recursosActivos.podcast1 && (
										<>
											<Grid item xs={8} sm={8} md={7} lg={7}>
												<b>¿TE GUSTÓ ESTE CONTENIDO?</b>
											</Grid>
											<Grid item xs={4} sm={4} md={3} lg={3}>
												<div className='contenedor-likes'>
													<img className='btn-likes' src={likes.evaluacion.like ? likeOn : likeOff} alt='img' onClick={() => setLikesBd('evaluacion', true)} />
													<img className='btn-likes' src={likes.evaluacion.dislike ? dislikeOn : dislikeOff} alt='img' onClick={() => setLikesBd('evaluacion', false)} />
												</div>
											</Grid>
										</>
									)}
								</Grid>
							</div>
						</Grid>
						{recursosActivos.insignia && (
							<Grid item xs={12} sm={6} md={5} lg={5} className='center wow zoomInLeft' data-wow-delay='.2s' data-wow-offset='100' data-wow-duration='.5s'>
								<div className='fullwrap' onClick={() => irRecurso(4)}>
									<img src={audiencia === 'Ejecutivos' ? ejectutivos.insignia.portada : jefaturasyCoordinadores.insignia.portada} alt='img' className={`full ${recursosActivos.tutorial1 && recursosActivos.podcast1 ? 'cursor' : 'disable'}`} />
								</div>
								<div className='full center'>
									<Grid container direction='row' alignItems='center' justifyContent='center' spacing={2}>
										<Grid item xs={12} sm={12} md={2} lg={2}>
											<img src={recursosActivos.insigniaVista ? visto : noVisto} alt='img' />
										</Grid>
									</Grid>
								</div>
							</Grid>
						)}
					</Grid>
				</Container>
			</div>
		</div>
	);
}
